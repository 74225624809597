import { Link } from "react-router-dom";
import SettingsBack from "./../../img/SettingsBack.png";
import styles from "./css/index.module.css";

const SettingsHeader = ({backLink, backText,heading}) => {

    return(
        <div className={styles.SettingsHeader}>
            <Link to={backLink}>
                <img src={SettingsBack}  alt="Back Button"/>
                <span>{backText}</span>
            </Link>
            <h3>{heading}</h3>
        </div>
    )

}

export default SettingsHeader;