import Features from "../../../Components/Features/Index";
import TradeBlock from "./Components/TradeBlock";
import styles from "./css/index.module.css"

const CExchange = () => {

    return(
        <div className={styles.CExchange}>
            <div>
                <h3>Exchange</h3>
                <p>Buy, sell and swap assets securely at competitive rates.</p>
            </div>
            <div>
                <Features phoneNumbers={<><br/>+234 913 282 7213 <br/>+234 906 805 6728</>}/>
                <TradeBlock/>
            </div>
        </div>
    );

}

export default CExchange;