import styles from "./css/index.module.css";
import Transaction from "./../../../img/ATransactionActive.png";
import Customer from "./../../../img/ACustomerActive.png";
import User from "./../../../img/AUserActive.png";
import GreenArrowUp from "./../../../img/GreenUpArrow.png";
import Loading from "./../../../img/loading.gif";
import Transactions from "../../../Components/Transactions/Index";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../store/AuthContext";
import { BASE_URL, CUSTOMER_COUNT, GET_TRANSACTION_LIST, TRANS_COUNT, USER_COUNT } from "../../../Utils/Endpoint";

const ADashboard = () => {
    const [transactions, setTransactions] = useState(null);
    const [customerCount, setCustomerCount] = useState(null);
    const [userCount, setUserCount] = useState(null);
    const [transactionCount, setTransactionCount] = useState(null);
    const AuthCtx = useContext(AuthContext);


    useEffect(() => {
        let getTransactionsAction = async () => {
        
            var response = await fetch(BASE_URL + GET_TRANSACTION_LIST + "?isAdmin=" + true,
                {
                    method : "GET",
                    headers : {
                        'content-type': 'application/json',
                        "Authorization" : "Bearer " + AuthCtx.token,
                        'Accept': 'application/json'
                    }
                })
    
                if(response.ok){
                    let json = await response.json();
                    setTransactions(json.data);
                }
        }

        let GetCustomerCount = async () => {
        
            var response = await fetch(BASE_URL + CUSTOMER_COUNT,
                {
                    method : "GET",
                    headers : {
                        'content-type': '*/*',
                        "Authorization" : "Bearer " + AuthCtx.token,
                        'Accept': '*/*'
                    }
                })
    
                if(response.ok){
                    let json = await response.json();
                    setCustomerCount(json.data);
                }
        }

        let GetUserCount = async () => {
        
            var response = await fetch(BASE_URL + USER_COUNT,
                {
                    method : "GET",
                    headers : {
                        'content-type': '*/*',
                        "Authorization" : "Bearer " + AuthCtx.token,
                        'Accept': '*/*'
                    }
                })
    
                if(response.ok){
                    let json = await response.json();
                    setUserCount(json.data);
                }
        }

        let GetTransactionCount = async () => {
        
            var response = await fetch(BASE_URL + TRANS_COUNT,
                {
                    method : "GET",
                    headers : {
                        'content-type': '*/*',
                        "Authorization" : "Bearer " + AuthCtx.token,
                        'Accept': '*/*'
                    }
                })
    
                if(response.ok){
                    let json = await response.json();
                    setTransactionCount(json.data);
                }
        }

        getTransactionsAction();
        GetCustomerCount();
        GetUserCount();
        GetTransactionCount();
        
    },[AuthCtx])

    

    return(
        <div className={styles.ADashboard}>
            <div>
                <div>
                    <div>
                        <img src={Transaction} alt="People icon"/>
                        <div>
                            <label>Transactions</label>
                            {transactionCount === null ?
                                <img src={Loading} alt="loading" className={styles.Loading} /> 
                            :
                                <h4>{transactionCount}</h4>
                            }
                        </div>
                    </div>
                    {false && <div>
                        <img src={GreenArrowUp} alt="Up Arrow"/>
                        <span>26</span>
                    </div>}
                </div>
                <div>
                    <div>
                        <img src={Customer} alt="People icon"/>
                        <div>
                            <label>Customers</label>
                            {customerCount === null ?
                                <img src={Loading} alt="loading" className={styles.Loading} /> 
                            :
                                <h4>{customerCount}</h4>
                            }
                        </div>
                    </div>
                    {false && <div>
                        <img src={GreenArrowUp} alt="Up Arrow"/>
                        <span>26</span>
                    </div>}
                </div>
                <div>
                    <div>
                        <img src={User} alt="People icon"/>
                        <div>
                            <label>Users</label>
                            {userCount === null ?
                                <img src={Loading} alt="loading" className={styles.Loading} /> 
                            :
                                <h4>{userCount}</h4>
                            }
                        </div>
                    </div>
                    {false && <div>
                        <img src={GreenArrowUp} alt="Up Arrow"/>
                        <span>26</span>
                    </div>}
                </div>
            </div>
            <div className={styles.TransactionsCtnr}>
                <h3>Transactions</h3>
                <Transactions isAdmin={true} transactions={transactions} setTransactions={setTransactions}/>
            </div>
        </div>
    )

}

export default ADashboard;