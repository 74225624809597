import ModalContainer from "../ModalContainer/Index";
import styles from "./css/index.module.css";

const Swap = ({setSwapModal, asset1, asset2, amount, setWalletAddress, walletAddress, setConfirmTrans, buyNairaRate, address, sellNairaRate, swapFee}) => {
    console.log('buy', buyNairaRate);
    console.log('sell', sellNairaRate);
    return(
        <ModalContainer showModal={setSwapModal}>
            <div className={styles.Sell}>
                <h3>Swap Order</h3>
                <div>
                    <div className={styles.InputGroup}>
                        <label>Send your crypto to Address here</label>
                        <div className={styles.InputDropDown}>
                            <div className={styles.SelectedInput}>
                            <img src={asset1.img} alt="Bank Transfer Icon"  className={styles.coinSize}/>
                            <label>{asset1.name} Address</label>
                            </div>
                        </div>
                    </div>
                    <div className={styles.InputGroup}>
                        <label>Wallet Address</label>
                        <div>
                            <span className={styles.CryptoAddress}>{address}</span>
                        </div>
                    </div>
                    <div className={styles.InputGroup}>
                        <label>Enter your crypto to Address here</label>
                        <div className={styles.InputDropDown}>
                            <div className={styles.SelectedInput}>
                            <img src={asset2.img} alt="Bank Transfer Icon" className={styles.coinSize}/>
                            <label>{asset2.name} Address</label>
                            </div>
                        </div>
                    </div>
                    <div className={styles.InputGroup}>
                        <label>Wallet Address</label>
                        <div className={styles.InputDropDown}>
                            <input type={"text"} className={styles.CryptoAddress} value={walletAddress} onChange={(e) => setWalletAddress(e.target.value)}/>
                        </div>
                    </div>
                    <div className={styles.Summary}>
                        <div>
                            <span>Fee</span>
                            <span>{(swapFee.feeCharge / sellNairaRate.rate).toFixed(2) } USD</span>
                        </div>
                        <div>
                            <span>You will receive</span>
                            <span>{((amount * (sellNairaRate.rate / buyNairaRate.rate)) - (swapFee.feeCharge / sellNairaRate.rate)).toFixed(2)} USD</span>
                        </div>
                        <div>
                            <span>Exchange rate</span>
                            <span> {(sellNairaRate.rate / buyNairaRate.rate).toFixed(2)} / USD</span>
                        </div>
                    </div>
                </div>
                <div>
                    <button onClick={() => setSwapModal(false)}>
                        Cancel
                    </button>
                    <button onClick={() => setConfirmTrans()}>
                        Confirm
                    </button>
                </div>
            </div>
        </ModalContainer>
    )
}

export default Swap;