import RecentTransactions from "./Components/RecentTransactions";
import Wallet from "./Components/Wallet";
import styles from "./css/index.module.css";

const CDashboard = () => {


    return(
        <div className={styles.CDashboard}>
            <Wallet/>
            <RecentTransactions/>
        </div>
    );

}

export default CDashboard;