import styles from "./css/index.module.css";
import { Link } from "react-router-dom";
import whiteLogo from "./../../img/CoinNestLogoWhite.png";
import twitter from "./../../img/twitterIcon.png";
import linkedin from "./../../img/linkedinIcon.png";
import facebook from "./../../img/facebookIcon.png";
import instagram from "./../../img/instagramIcon.png";
import { useState } from "react";

const Footer = () => {
    const [email, setEmail] = useState("");

    let submitEmail = (e) => {
        e.preventDefault()

        setEmail("");
    }

    return(<div className={styles.FooterContainer}>
        <div className={styles.FooterNav}>
            <div className={styles.FormNLogo}>
                <Link to={'/'}>
                <img src={whiteLogo} alt="coin nest footer logo"/>
                </Link>
                <div>
                    <h4>Join our Newsletter</h4>
                    <form 
                        onSubmit={(e) => submitEmail(e)}
                        className={styles.SubForm}
                    >
                        <input type={"email"} placeholder="Enter your email address" value={email} onChange={(e) => setEmail(e.target.value)}/>
                        <button type={'submit'}>
                            Subscribe
                            </button>
                    </form>
                </div>
            </div>
            <div className={styles.FooterLinkCtnr}>
                <div className={styles.FooterLinkNav}>
                    <h4>Company</h4>
                    <ul>
                        <li>
                            <Link to={'/About'}>
                                <span>
                                 About   
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/FAQs'}>
                                <span>
                                 FAQs   
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/'}>
                                <span>
                                 Blog   
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/Contact'}>
                                <span>
                                 Contact   
                                </span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className={styles.FooterLinkNav}>
                    <h4>Legal</h4>
                    <ul>
                        <li>
                            <Link to={'/TermsOfUse'}>
                                <span>
                                Terms of Use   
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/AntiMoneyLaundering'}>
                                <span>
                                Anti-Money Laundering   
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/PrivacyPolicy'}>
                                <span>
                                Privacy Policy   
                                </span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className={styles.FooterNote}>
            <p>© 2023 CoinNest Africa. All rights reserved.</p>
            <ul>
                <li>
                    <a href="/">
                        <img src={twitter} alt="twitter icon"/>
                    </a>
                </li>
                <li>
                    <a href="/">
                        <img src={linkedin} alt="linkedin icon"/>
                    </a>
                </li>
                <li>
                    <a href="/">
                        <img src={instagram} alt="instagram icon"/>
                    </a>
                </li>
                <li>
                    <a href="/">
                        <img src={facebook} alt="facebook icon"/>
                    </a>
                </li>
            </ul>
        </div>
    </div>);

}

export default Footer;