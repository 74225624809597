import styles from "./../css/index.module.css";

const ProgressBar = ({currentValue}) => {
    let states = new Array(5).fill(" status");

    return(
    <div className={styles.ProgressBar}>
        {states.map((value, index) => {
            return <div key={index + value} className={`${styles.ProgressIcon} ${currentValue - 1 === index ? styles.ActiveIcon : ""}`}></div>
        })}
    </div>)

}

export default ProgressBar;