import Title from "../../Components/Title/Index";
import UnAuthContainer from "../../Components/UnAuthContainer/Index";
import styles from "./css/index.module.css";
import Map from "./../../img/Map.png";
import Bolaji from "./../../img/Bolaji.png";
import User from "./../../img/User.png"
import Lucy from "./../../img/Lucy.png";
import Teamwork from "./../../img/Teamwork.png";
import Empathy from "./../../img/Empathy.png";
import Customer from "./../../img/Customer.png";
import Ownership from "./../../img/Ownership.png";
import Attention from "./../../img/Attention.png";
import Flag from "./../../img/Flag.png";
import Clock from "./../../img/clock.png";
import Office from "./../../img/Office.png";
import Person from "./Components/Person";
import Action from "../../Components/Action/Index";
import { JOBS } from "./TestData";
import useGaTracker from "./../../Utils/useGATracker";

const About = () => {

    useGaTracker();

    return(
        <UnAuthContainer>
            <div className={styles.AboutCtnr}>
                <div className={styles.FirstSection}>
                    <Title
                        label={"About Us"}
                        heading={"Join the Revolution of Digital Trading"}
                        text={"We are building a platform and service that will enable our customers explore the possibilities of the cryptocurrency market."}
                        bigTitle={true}
                    />
                    <img src={Map} alt="Map" />
                </div>
                <div className={styles.SecondSection}>
                    <Title
                        label={"Our Team"}
                        heading={"Meet our Team"}
                        text={"A team of diverse, passionate people and that foster a culture that empowers you to do you best work."}
                    />
                    <div className={styles.PersonGroup}>
                        <Person
                            source={Bolaji}
                            name={"Bolaji George"}
                            role={"Founder"}
                        />
                        <Person
                            source={Lucy}
                            name={"Lucy Ezeuka"}
                            role={"Assistant Manager"}
                        />
                        <Person
                            source={User}
                            noPhoto={true}
                            name={"Kehinde P."}
                            role={"Sales/Marketing Manager"}
                        />
                    </div>
                </div>
                <div className={styles.ThirdSection}>
                    <Title
                        label={"Our values"}
                        heading={"What Drives us"}
                        text={"Our shared values keep us connected. It is how we make the most important decisions about our work, relationships and customer service."}
                    />
                    <div className={styles.ThirdBody}>
                        <Action
                            source={Teamwork}
                            label={"Teamwork"}
                            description={"Understand what matters to our employees. Give them what they need to do their best work."}
                        />
                        <Action
                            source={Empathy}
                            label={"Empathy"}
                            description={"Effective and clear communication is crucial for efficient collaboration and creating a positive work environment."}
                        />
                        <Action
                            source={Empathy}
                            label={"Pride in what we do"}
                            description={"Value quality and integrity in everything we do. At all times. No exceptions."}
                        />
                        <Action
                            source={Customer}
                            label={"Customer centric"}
                            description={"Understand customers' stated and unstated needs. Make them wildly successful."}
                        />
                        <Action
                            source={Ownership}
                            label={"Ownership"}
                            description={"Be energized by difficult problems. Revel in unknowns. Ask \"Why?\", but always question, \"Why not?\""}
                        />
                        <Action
                            source={Attention}
                            label={"Attention to detail"}
                            description={"We believe the best products come from the best attention to detail. Sweat the small stuff."}
                        />
                    </div>
                </div>
                <div className={styles.FourthSection}>
                    <div className={styles.JobsTitle}>
                        <label>Open Positions</label>
                        <h2>We’re Looking for Talented People</h2>
                    </div>
                    <div className={styles.JobSection}>
                        {JOBS.map((department, index) => {
                            return (
                            <div key={index} className={styles.Department}>
                               <div>
                                    <h3>{department.Department}</h3>
                                    <span>{department.Description}</span>
                               </div>
                               <div className={styles.JobList}>
                                {department.Offers.map((job, i) => {
                                    return (
                                        <div key={i} className={styles.Job}>
                                            <div>
                                                <h4>{job.Role}</h4>
                                                <div>
                                                    <img src={Flag} alt="Nigerian Flag Icon" />
                                                    <span>Lagos, Nigeria</span>
                                                </div>
                                            </div>
                                            <div>
                                                <p>{job.Text}</p>
                                                <div>
                                                    <img src={Clock} alt="Clock icon" />
                                                    <span>{job.Type}</span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                </div> 
                            </div>);
                        })}
                    </div>
                </div>
                <div className={styles.FifthSection}>
                    <img src={Office} alt="Office" />
                </div>
            </div>
        </UnAuthContainer>
    );

}

export default About;