import styles from "./../css/index.module.css";

const ContactAction = ({source, title, label, text}) => {

    return(
        <div className={styles.Action}>
            <img src={source} alt={title + "img"}/>
            <div>
                <div>
                    <h3>{title}</h3>
                    <label>{label}</label>
                </div>
                <p>{text}</p>
            </div>
        </div>
    );

}

export default ContactAction;