export const TESTIMONIALS = [
    {
        initial : "C",
        name : "Chinny",
        text : "I really love that your Bitcoin rates are always reasonable, never too high."
    },
    {
        initial : "M",
        name : "Mekky",
        text : "I am stuck with CoinNest, they are so far the most reliable trading company I’ve used."
    },
    {
        initial : "J",
        name : "Jay Boy",
        text : "You guys are the bomb, keep it going!"
    },
    {
        initial : "T",
        name : "Tunde",
        text : "My experience with Coinnest has been so stress free, amazing and easy. Their service is top notch, second to none."
    },
    {
        initial : "A",
        name : "Ademola",
        text : "All I can say is you’re doing well. Your 24/7 support is no bluff."
    },
    {
        initial : "C",
        name : "Chiseko",
        text : "Very nice customer service."
    },
]