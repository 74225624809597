// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_InputCtnr__pUgPp{display: flex;flex-direction: column;align-items: flex-start;gap: 10px;}
.css_InputCtnr__pUgPp > label{color: var(--gray-900, #101828);font-size: 14px;font-style: normal;font-weight: 450;line-height: 20px; /* 142.857% */}
.css_bigMargin__qXzIG{margin-bottom: 20px;}
.css_smallMargin__JbiBh{margin-bottom: 10px;}`, "",{"version":3,"sources":["webpack://./src/Components/InputCtnr/css/index.module.css"],"names":[],"mappings":"AAAA,sBAAW,aAAa,CAAC,sBAAsB,CAAC,uBAAuB,CAAC,SAAS,CAAC;AAClF,8BAAmB,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,EAAE,aAAa,CAAC;AACxI,sBAAW,mBAAmB,CAAC;AAC/B,wBAAa,mBAAmB,CAAC","sourcesContent":[".InputCtnr{display: flex;flex-direction: column;align-items: flex-start;gap: 10px;}\n.InputCtnr > label{color: var(--gray-900, #101828);font-size: 14px;font-style: normal;font-weight: 450;line-height: 20px; /* 142.857% */}\n.bigMargin{margin-bottom: 20px;}\n.smallMargin{margin-bottom: 10px;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InputCtnr": `css_InputCtnr__pUgPp`,
	"bigMargin": `css_bigMargin__qXzIG`,
	"smallMargin": `css_smallMargin__JbiBh`
};
export default ___CSS_LOADER_EXPORT___;
