// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_BankAccountsBody__U3EWO {margin-top: 32px;}
.css_BankAccountsBody__U3EWO > div{display: flex; flex-direction: column; gap: 21px;}
.css_BankAccountsBody__U3EWO > div > div{padding: 16px;width: 390px;border-radius: 12px;border: 1px solid var(--gray-300, #D0D5DD);background: var(--white, #FFF);display: flex;align-items: center;gap: 20px;}
.css_BankAccountsBody__U3EWO > div > div > div{display: flex;flex-direction: column;}
.css_BankAccountsBody__U3EWO > div > div > div > h4{margin: 0px;color: var(--gray-500, #667085);font-size: 16px;font-style: normal;font-weight: 500;line-height: 24px; /* 150% */}
.css_BankAccountsBody__U3EWO > div > div > div > span{color: var(--gray-400, #98A2B3);font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px; /* 150% */}

.css_BankAccountsBody__U3EWO > button{border: none;background: rgba(0, 0, 0, 0);padding: 0px;margin: 0px;color: var(--accent, #F60);font-size: 16px;font-style: normal;font-weight: 500;line-height: 24px;margin-top: 32px;}

@media screen and (max-width : 800px) { 
    
    .css_BankAccountsBody__U3EWO > div > div{width: 100%; box-sizing: border-box;}


}`, "",{"version":3,"sources":["webpack://./src/Pages/Admin/Customers/Pages/CustomerBank/css/index.module.css"],"names":[],"mappings":"AAAA,8BAAmB,gBAAgB,CAAC;AACpC,mCAAwB,aAAa,EAAE,sBAAsB,EAAE,SAAS,CAAC;AACzE,yCAA8B,aAAa,CAAC,YAAY,CAAC,mBAAmB,CAAC,0CAA0C,CAAC,8BAA8B,CAAC,aAAa,CAAC,mBAAmB,CAAC,SAAS,CAAC;AACnM,+CAAoC,aAAa,CAAC,sBAAsB,CAAC;AACzE,oDAAyC,WAAW,CAAC,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,EAAE,SAAS,CAAC;AACtK,sDAA2C,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,EAAE,SAAS,CAAC;;AAE5J,sCAA2B,YAAY,CAAC,4BAA4B,CAAC,YAAY,CAAC,WAAW,CAAC,0BAA0B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,gBAAgB,CAAC;;AAEhN;;IAEI,yCAA8B,WAAW,EAAE,sBAAsB,CAAC;;;AAGtE","sourcesContent":[".BankAccountsBody {margin-top: 32px;}\n.BankAccountsBody > div{display: flex; flex-direction: column; gap: 21px;}\n.BankAccountsBody > div > div{padding: 16px;width: 390px;border-radius: 12px;border: 1px solid var(--gray-300, #D0D5DD);background: var(--white, #FFF);display: flex;align-items: center;gap: 20px;}\n.BankAccountsBody > div > div > div{display: flex;flex-direction: column;}\n.BankAccountsBody > div > div > div > h4{margin: 0px;color: var(--gray-500, #667085);font-size: 16px;font-style: normal;font-weight: 500;line-height: 24px; /* 150% */}\n.BankAccountsBody > div > div > div > span{color: var(--gray-400, #98A2B3);font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px; /* 150% */}\n\n.BankAccountsBody > button{border: none;background: rgba(0, 0, 0, 0);padding: 0px;margin: 0px;color: var(--accent, #F60);font-size: 16px;font-style: normal;font-weight: 500;line-height: 24px;margin-top: 32px;}\n\n@media screen and (max-width : 800px) { \n    \n    .BankAccountsBody > div > div{width: 100%; box-sizing: border-box;}\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BankAccountsBody": `css_BankAccountsBody__U3EWO`
};
export default ___CSS_LOADER_EXPORT___;
