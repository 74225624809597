// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_Timers__5fOok{margin: 32px; text-align: left;}
.css_TimersBody__mbCE3{margin-top: 32px;display: flex;flex-direction: column;gap: 21px;}
.css_TimersBody__mbCE3 > a{padding: 16px;text-decoration: none;border-radius: 12px;border: 1px solid var(--gray-300, #D0D5DD);background: var(--White, #FFF);display: flex;justify-content: space-between;align-items: center;width: 390px;}
.css_TimersBody__mbCE3 > a > div{display: flex;align-items: center; gap: 10px;}
.css_TimersBody__mbCE3 > a > div > span{color: var(--gray-900, #101828);font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px; /* 150% */}
.css_TimersBody__mbCE3 > a > span{color: var(--gray-500, #667085);font-size: 18px;font-style: normal;font-weight: 450;line-height: 28px; /* 155.556% */}

@media screen and (max-width : 800px) { 
    .css_TimersBody__mbCE3 > a{width: 100%;box-sizing: border-box;}
}`, "",{"version":3,"sources":["webpack://./src/Pages/Admin/Settings/Pages/Timers/css/index.module.css"],"names":[],"mappings":"AAAA,mBAAQ,YAAY,EAAE,gBAAgB,CAAC;AACvC,uBAAY,gBAAgB,CAAC,aAAa,CAAC,sBAAsB,CAAC,SAAS,CAAC;AAC5E,2BAAgB,aAAa,CAAC,qBAAqB,CAAC,mBAAmB,CAAC,0CAA0C,CAAC,8BAA8B,CAAC,aAAa,CAAC,8BAA8B,CAAC,mBAAmB,CAAC,YAAY,CAAC;AAChO,iCAAsB,aAAa,CAAC,mBAAmB,EAAE,SAAS,CAAC;AACnE,wCAA6B,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,EAAE,SAAS,CAAC;AAC9I,kCAAuB,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,EAAE,aAAa,CAAC;;AAE5I;IACI,2BAAgB,WAAW,CAAC,sBAAsB,CAAC;AACvD","sourcesContent":[".Timers{margin: 32px; text-align: left;}\n.TimersBody{margin-top: 32px;display: flex;flex-direction: column;gap: 21px;}\n.TimersBody > a{padding: 16px;text-decoration: none;border-radius: 12px;border: 1px solid var(--gray-300, #D0D5DD);background: var(--White, #FFF);display: flex;justify-content: space-between;align-items: center;width: 390px;}\n.TimersBody > a > div{display: flex;align-items: center; gap: 10px;}\n.TimersBody > a > div > span{color: var(--gray-900, #101828);font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px; /* 150% */}\n.TimersBody > a > span{color: var(--gray-500, #667085);font-size: 18px;font-style: normal;font-weight: 450;line-height: 28px; /* 155.556% */}\n\n@media screen and (max-width : 800px) { \n    .TimersBody > a{width: 100%;box-sizing: border-box;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Timers": `css_Timers__5fOok`,
	"TimersBody": `css_TimersBody__mbCE3`
};
export default ___CSS_LOADER_EXPORT___;
