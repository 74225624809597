import { Link } from "react-router-dom";
import styles from "./css/index.module.css";

const AuthFooter = () => {

    return(
        <div className={styles.AuthFooter}>
            <p>© CoinNest 2023</p>
            <Link to={'/Contact'}>Contact Us</Link>
        </div>
    )

}

export default AuthFooter;