export const JOBS = [
    {
        "Department" : "Design",
        "Description" : "Open positions in our design team.",
        "Offers" : [
            {
                "Role" : "Product Designer",
                "Text" : "We’re looking for a mid-level product designer to join our team.",
                "Type" : "Contract"
            },
            {
                "Role" : "Graphics Designer",
                "Text" : "We’re looking for a mid-level product designer to join our team.",
                "Type" : "Full-time"
            }
        ]
    },
    {
        "Department" : "Software Development",
        "Description" : "Open positions in our software team.",
        "Offers" : [
            {
                "Role" : "Frontend Developer",
                "Text" : "We’re looking for a senior frontend developer to join our team.",
                "Type" : "Full-time"
            }
        ]
    },
    {
        "Department" : "Customer Success",
        "Description" : "Open positions in our support team.",
        "Offers" : [
            {
                "Role" : "Customer Success Manager",
                "Text" : "We’re looking for a mid-level customer success personnel to join our team.",
                "Type" : "Hybrid"
            }
        ]
    },
]