import ModalContainer from "../../../../Components/ModalContainer/Index";
import styles from "./../css/index.module.css";
import ArrowDown from "./../../../../img/DropdownArrow.png";
import { useState } from "react";

const AddUser = ({showModal, activeRole, setRole, firstName, setFirstName, lastName, setLastName, email, setEmail, password, setPassword, AddUserAction, error}) => {
    const roles = ["Manager", "Support", "Admin"]
    const [showRoles, setShowRoles] = useState(false);

    return(
        <ModalContainer>
            <div className={styles.AddUser}>
                <h3>Add User</h3>
                <div>
                    <div className={styles.Names}>
                        <div className={styles.InputGroup}>
                            <label>First Name</label>
                            <input value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                        <div className={styles.InputGroup}>
                            <label>Last Name</label>
                            <input value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </div>
                    </div>
                    <div className={styles.InputGroup}>
                        <label>Email</label>
                        <input value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className={styles.InputGroup}>
                        <label>Password</label>
                        <input value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className={styles.InputGroup}>
                        <label>Role</label>
                        <div onClick={() => setShowRoles(prev => !prev)}>
                            <div>
                                <span>{activeRole === null ? "Select Role" : activeRole }</span>
                                <img src={ArrowDown} alt="Arrow Down" />
                            </div>
                            {showRoles &&
                            <div>
                                {roles.map((role, index) => (
                                    <span onClick={() => setRole(role)} key={index}>{role}</span>
                                ))}
                            </div>}
                        </div>
                    </div>
                </div>
                <span className={styles.ErrorMessage}>{error}</span>
                <div className={styles.FooterButton}>
                    <button  onClick={() => showModal(false)}>
                        Cancel
                    </button>
                    <button onClick={() => AddUserAction()}>
                        Confirm
                    </button>
                </div>
            </div>
        </ModalContainer>
    )

}

export default AddUser;