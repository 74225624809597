// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_SettingsHeader__GhFk1{border-bottom: 1px solid #EAECF0; padding-bottom: 16px;display: flex;justify-content: flex-start;flex-direction: column;gap: 16px;text-align: left;}
.css_SettingsHeader__GhFk1 > h3{margin: 0px;color: var(--primary, #060661);font-size: 30px;font-style: normal;font-weight: 500;line-height: 38px; /* 126.667% */}
.css_SettingsHeader__GhFk1 > a {display: flex;gap: 4px;align-items: center;text-decoration: none;}
.css_SettingsHeader__GhFk1 > a > span{color: var(--gray-500, #667085);font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px;}`, "",{"version":3,"sources":["webpack://./src/Components/SettingsHeader/css/index.module.css"],"names":[],"mappings":"AAAA,2BAAgB,gCAAgC,EAAE,oBAAoB,CAAC,aAAa,CAAC,2BAA2B,CAAC,sBAAsB,CAAC,SAAS,CAAC,gBAAgB,CAAC;AACnK,gCAAqB,WAAW,CAAC,8BAA8B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,EAAE,aAAa,CAAC;AACrJ,gCAAqB,aAAa,CAAC,QAAQ,CAAC,mBAAmB,CAAC,qBAAqB,CAAC;AACtF,sCAA2B,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC","sourcesContent":[".SettingsHeader{border-bottom: 1px solid #EAECF0; padding-bottom: 16px;display: flex;justify-content: flex-start;flex-direction: column;gap: 16px;text-align: left;}\n.SettingsHeader > h3{margin: 0px;color: var(--primary, #060661);font-size: 30px;font-style: normal;font-weight: 500;line-height: 38px; /* 126.667% */}\n.SettingsHeader > a {display: flex;gap: 4px;align-items: center;text-decoration: none;}\n.SettingsHeader > a > span{color: var(--gray-500, #667085);font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SettingsHeader": `css_SettingsHeader__GhFk1`
};
export default ___CSS_LOADER_EXPORT___;
