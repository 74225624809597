// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_CustomerNavigation__psrVc > ul{margin: 0px; padding: 32px 24px 0px 32px;list-style-type: none;text-align: left;display: flex;flex-direction: column;gap: 16px;width: 204px;border-right: 1px solid #EAECF0;height: calc(100% - 32px);}
.css_CustomerNavigation__psrVc > ul > li{display: block;}
.css_CustomerNavigation__psrVc > ul > li > a{text-decoration: none;color: var(--gray-500, #667085);padding: 10px;display: block;}
.css_CustomerNavigation__psrVc > ul > li > a.css_ActiveNav__e32ln{border-radius: 8px;background: var(--gray-100, #F2F4F7);}
.css_CustomerNavigation__psrVc > ul > li > a > div{display: flex;gap: 10px; align-items: center;font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px;}
.css_CustomerNavigation__psrVc > ul > li > a.css_ActiveNav__e32ln > div > span{color: var(--primary-900, #060661);}
@media screen and (max-width : 800px) { 
    .css_CustomerNavigation__psrVc > ul{position: absolute;background-color: white;z-index: 100;}
}`, "",{"version":3,"sources":["webpack://./src/Components/CustomerNavigation/css/index.module.css"],"names":[],"mappings":"AAAA,oCAAyB,WAAW,EAAE,2BAA2B,CAAC,qBAAqB,CAAC,gBAAgB,CAAC,aAAa,CAAC,sBAAsB,CAAC,SAAS,CAAC,YAAY,CAAC,+BAA+B,CAAC,yBAAyB,CAAC;AAC/N,yCAA8B,cAAc,CAAC;AAC7C,6CAAkC,qBAAqB,CAAC,+BAA+B,CAAC,aAAa,CAAC,cAAc,CAAC;AACrH,kEAA4C,kBAAkB,CAAC,oCAAoC,CAAC;AACpG,mDAAwC,aAAa,CAAC,SAAS,EAAE,mBAAmB,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC;AAC3J,+EAAyD,kCAAkC,CAAC;AAC5F;IACI,oCAAyB,kBAAkB,CAAC,uBAAuB,CAAC,YAAY,CAAC;AACrF","sourcesContent":[".CustomerNavigation > ul{margin: 0px; padding: 32px 24px 0px 32px;list-style-type: none;text-align: left;display: flex;flex-direction: column;gap: 16px;width: 204px;border-right: 1px solid #EAECF0;height: calc(100% - 32px);}\n.CustomerNavigation > ul > li{display: block;}\n.CustomerNavigation > ul > li > a{text-decoration: none;color: var(--gray-500, #667085);padding: 10px;display: block;}\n.CustomerNavigation > ul > li > a.ActiveNav{border-radius: 8px;background: var(--gray-100, #F2F4F7);}\n.CustomerNavigation > ul > li > a > div{display: flex;gap: 10px; align-items: center;font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px;}\n.CustomerNavigation > ul > li > a.ActiveNav > div > span{color: var(--primary-900, #060661);}\n@media screen and (max-width : 800px) { \n    .CustomerNavigation > ul{position: absolute;background-color: white;z-index: 100;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CustomerNavigation": `css_CustomerNavigation__psrVc`,
	"ActiveNav": `css_ActiveNav__e32ln`
};
export default ___CSS_LOADER_EXPORT___;
