import styles from "./../css/index.module.css"

const Milestone = ({title, label}) => {

    return(
        <div className={styles.Milestone}>
            <h3>{title}</h3>
            <label>{label}</label>
        </div>
    );

}

export default Milestone;