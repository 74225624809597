import ModalContainer from "../ModalContainer/Index";
import styles from "./css/index.module.css";
import Transfer from "./../../../../img/Transfer.png";
import LoadingCircle from "../../../../Components/Loading/Index";

const BankTransfer = ({depAmount, rate, setBankTransfer, depositAction, isLoading}) => {
    return(
        <ModalContainer showModal={setBankTransfer}>
            <div className={styles.BankTransfer}>
                <h3>Bank Transfer</h3>
                <div>
                    <div>
                        <label>Payment Method</label>
                        <div>
                            <img src={Transfer} alt="Bank Transfer Icon"/>
                            <label>Bank Transfer</label>
                        </div>
                    </div>
                    <p>Kindly make a payment to the details below to complete the transaction.</p>
                    <div>
                        <div>
                            <span>Amount</span>
                            <span>{parseFloat(depAmount).toFixed(2)} NGN</span>
                        </div>
                        <div>
                            <span>Account name</span>
                            <span>YGX Meerchant</span>
                        </div>
                        <div>
                            <span>Account number</span>
                            <span>1304369844</span>
                        </div>
                        <div>
                            <span>Bank name</span>
                            <span>PROVIDUS BANK</span>
                        </div>
                    </div>
                </div>
                <div>
                    {isLoading ? <LoadingCircle/> :
                    <button onClick={() => depositAction()}>
                        Payment Completed
                    </button>}
                </div>
            </div>
        </ModalContainer>
    )
}

export default BankTransfer;