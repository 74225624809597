import ModalContainer from "../ModalContainer/Index";
import styles from "./css/index.module.css";
import CompletedIcon from "./../../../../img/Completed.gif";

const PinSuccess = ({setIsCompleted}) => {

    return(
        <ModalContainer showModal={setIsCompleted}>
            <div className={styles.Completed}>
                <img src={CompletedIcon} alt="Completed Icon" />
                <h3>Pin Successfully Changed</h3>
                <p>Your request has been processed successfully.</p>
                <button onClick={() => setIsCompleted(false)}>Back to Security Page</button>
            </div>
        </ModalContainer>
    );

}

export default PinSuccess;