import { Link, NavLink, Outlet, useParams } from "react-router-dom";
import Back from "./../../../../../img/CustomerBack.png";
import styles from "./css/index.module.css";
import User from "./../../../../../img/User.png";
import ArrowDown from "./../../../../../img/CustomerArrowDown.png";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../store/AuthContext";
import { BASE_URL, GET_USER } from "../../../../../Utils/Endpoint";

const CustomerContainer = () => {
    const [user, setUser] = useState(null);
    const AuthCtx = useContext(AuthContext);
    const { id } = useParams();


    useEffect(() => {
        let getTransactionsAction = async () => {
        
            var response = await fetch(BASE_URL + GET_USER + "?userId=" + id,
                {
                    method : "GET",
                    headers : {
                        'content-type': 'application/json',
                        "Authorization" : "Bearer " + AuthCtx.token,
                        'Accept': 'application/json'
                    }
                })
    
                if(response.ok){
                    let json = await response.json();
                    setUser(json.data);
                }
        }

        getTransactionsAction()
        
    },[AuthCtx, id])

    return(
    <div className={styles.CustomerContainer}>
        <div>
            <Link to={'/Admin/Customers'}>
                <img src={Back} alt="Back Icon"/>
                <span>Customers</span>
            </Link>
            {user !== null &&
            <div>
                <div>
                    <div>
                        <img src={User} alt="User Icon" />
                    </div>
                    <div>
                        <h3>{`${user.firstName} ${user.lastName}`}</h3>
                        <span>{user.email}</span>
                    </div>
                </div>
                <div>
                    <label>Account Limit</label>
                    <div>
                        <h3>Level 1</h3>
                        <img src={ArrowDown} alt="Down Arrow"/>
                    </div>
                </div>
            </div>}
            <div>
                <NavLink end to={'/Admin/Customer/' + id} className={({isActive, isPending}) => isActive ? styles.IsActive : "" }>Transactions</NavLink>
                <NavLink to={'Profile'} className={({isActive, isPending}) => isActive ? styles.IsActive : "" }>Profile</NavLink>
                <NavLink to={'Bank'} className={({isActive, isPending}) => isActive ? styles.IsActive : "" }>Bank Accounts</NavLink>
            </div>
        </div>
        <Outlet/>
    </div>
    );

}

export default CustomerContainer;