import { Link } from "react-router-dom";
import UnAuthContainer from "../../Components/UnAuthContainer/Index";
import styles from "./css/index.module.css";
import HomeImg from "./../../img/HomeImg.png";
import SignUp from "./../../img/SignUp.png";
import Fund from "./../../img/Fund.png";
import Trade from "./../../img/Trade.png";
import Workflow from "./../../img/coinNestFlow.gif";
import MockScreen from "./../../img/MockScreen.png";
import Check from "./../../img/Check.png";
import BitcoinLogo from "./../../img/BitcoinLogo.png";
import PerfectMoneyLogo from "./../../img/PerfectMoneyLogo.png";
import TetherLogo from "./../../img/TetherLogo .png";
import Milestone from "./Components/Milestone";
import Action from "./../../Components/Action/Index";
import CarouselCard from "./Components/CarouselCard";
import { TESTIMONIALS } from "./TestData";
import Title from "../../Components/Title/Index";
import Features from "../../Components/Features/Index";
import useGaTracker from "./../../Utils/useGATracker";


const Home = () => {

    useGaTracker();

    return(
    <UnAuthContainer>
        <div className={styles.HomeCtnr}>
            <div className={styles.FirstSection}>
                <label>WELCOME TO COINNEST</label>
                <h1>Powering Your Digital Asset Journey</h1>
                <p>Buy, sell and manage your cryptocurrency assets. Experience seamless transactions, zero trading fees and 24/7 support, all in one platform.</p>
                <Link to={'/OnBoarding'}>
                    <span>Get started</span>
                </Link>
                <img src={HomeImg} alt="Home Demo" className={styles.HomeImg}/>
            </div>
            <div className={styles.SecondSection}>
                <Title
                    label={"Our Numbers"}
                    heading={"Trade with Confidence"}
                    text={"We understand the importance of speed, reliability and security when it comes to your digital assets. That's why we've built a platform that prioritizes these values."}
                 />
                <div className={styles.SecondBody}>
                    <Milestone title={"4,000+"} label={"Verified Users"}/>
                    <Milestone title={"$982,389+"} label={"Transaction Value"}/>
                    <Milestone title={"$527,284+"} label={"Assets Bought"}/>
                    <Milestone title={"$455,105+"} label={"Assets Sold"}/>
                </div>
            </div>
            <div className={styles.ThirdSection}>
                <Title
                    label={"Our Process"}
                    heading={"How it Works"}
                    text={"The easiest way to trade digital assets with different funding and payment methods."}
                 />
                <div className={styles.ThirdBody}>
                    <Action addBorder={false} source={SignUp} label={"Sign Up"} description={"Create an account and verify your identity in minutes."} />
                    <Action addBorder={true} source={Fund} label={"Fund"} description={"Choose your preferred payment method to deposit money into your wallet."} />
                    <Action addBorder={false} source={Trade} label={"Trade"} description={"Start trading instantly at the best rates with zero trading fees"} />
                </div>
            </div>
            <div className={styles.FourthSection}>
                <div className={styles.WorkflowCtnr}>
                    <div>
                        <h2>Buy and Sell with Ease</h2>
                        <p>Our platform makes it simple and easy for you to buy, sell & swap digital assets, with quick transactions and a user-friendly interface. We are also here to help you every step of the way.</p>
                        <Features/>
                    </div>
                    <img src={Workflow} alt={"coinNest work flow"}/>
                    
                </div>
            </div>
            <div className={styles.FifthSection}>
                <Title
                    label={"Testimonials"}
                    heading={"Hear from our Traders"}
                    text={"See what our satisfied customers have to say about their experience with us. At Coinnest, we are dedicated to providing the best possible service."}
                 />
                <div className={styles.Carousel}>
                    {TESTIMONIALS.map((testimonial,index) => {
                        return <CarouselCard 
                        key={index} 
                        initial={testimonial.initial} 
                        name={testimonial.name} 
                        text={testimonial.text}/>
                        })
                    }
                    
                </div>
            </div>
            <div className={styles.SixthSection}>
                <div>
                    <h2>Join 4,000+ traders <br/>today</h2>
                    <ul>
                        <li>
                            <img src={Check} alt="Check"/>
                            <label>Instant transactions</label>
                        </li>
                        <li>
                            <img src={Check} alt="Check"/>
                            <label>Zero trading fees</label>
                        </li>
                        <li>
                            <img src={Check} alt="Check"/>
                            <label>24/7 support</label>
                        </li>
                    </ul>
                    <div>
                        <div className={styles.Contact}>
                            <Link to={"/Contact"}>
                            <span>Contact Us</span>
                            </Link>
                        </div>
                        <div className={styles.GetStarted}>
                            <Link to={"/OnBoarding"}>
                            <span>Get started</span>
                            </Link>
                        </div>
                    </div>
                </div>
                <img src={MockScreen} alt="Workflow 2" />
            </div>
            <div className={styles.SeventhSection}>
                <label>We currently support these digital assets</label>
                <div>
                    <img src={BitcoinLogo} alt="Bitcoin Logo" />
                    <img src={PerfectMoneyLogo} alt="Perfect Money Logo" />
                    <img src={TetherLogo} alt="Tether Logo" />
                </div>
            </div>
        </div>
    </UnAuthContainer>);
}

export default Home;