import SettingsHeader from "../../../../../Components/SettingsHeader/Index";
import styles from "./css/index.module.css";
import ArrowRight from "./../../../../../img/ArrowRight.png";
import { Link } from "react-router-dom";


const STransactions = () => {
    return(
        <div className={styles.Notifications}>
            <SettingsHeader
                heading={"Transactions"}
                backText={"Settings"}
                backLink={"/Admin/Settings"}
            />
            <div className={styles.NotificationsBody}>
                <Link to={'Rates'}>
                    <div>
                        <h4>Rates</h4>
                        <span>Manage transaction exchange prices</span>
                    </div>
                    <img src={ArrowRight}  alt="Notification Toggle"/>
                </Link>
                <Link to={'CryptoWallet'}>
                    <div>
                        <h4>Crypto Wallet Address</h4>
                        <span>Manage wallet Addresses</span>
                    </div>
                    <img src={ArrowRight}  alt="Notification Toggle"/>
                </Link>
                <Link to={'Timers'}>
                    <div>
                        <h4>Countdown Timers</h4>
                        <span>Set and control time for transactions.</span>
                    </div>
                    <img src={ArrowRight}  alt="Notification Toggle"/>
                </Link>
            </div>
        </div>
    )
}

export default STransactions;