import { useLocation, useNavigate } from "react-router-dom";
import SettingsHeader from "../../../../../Components/SettingsHeader/Index";
import styles from "./css/index.module.css";
import { ADD_TIMER, BASE_URL, GET_TIMERS } from "../../../../../Utils/Endpoint";
import { useEffect, useState } from "react";

const TimerSetting = ({action}) => {
    const [timers, setTimers] = useState(null);
    const location = useLocation();
    const navigate = useNavigate()

    const type = location.pathname.split('/').pop()
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [seconds, setSeconds] = useState('');



    useEffect(() => {
        (async () => {
          const response = await fetch(BASE_URL + GET_TIMERS) 
          
          var result = await response.json()

          if(response.ok){
                setTimers(result.data)
                setHours(result.data.filter(timer => timer.orderType === type)[0].hours)
                setMinutes(result.data.filter(timer => timer.orderType === type)[0].minutes)
                setSeconds(result.data.filter(timer => timer.orderType === type)[0].seconds)
          }
          
        })()
    },[type])

    const Submit = async (e) => {
        e.preventDefault()

        

        var response = await fetch(BASE_URL + ADD_TIMER, {method: "POST", body: JSON.stringify({
            hours: hours,
            minutes: minutes,
            seconds: seconds,
            orderType: type
        }), headers: {
            'Content-Type' : 'application/json'
        }})

        var result = await response.json()

        console.log(result)

        if(response.ok){
            navigate('/Admin/Settings/Transactions/Timers')
        }

    }

    return(
    <>
        {timers !== null &&
        <div className={styles.TimerSetting}>
            <SettingsHeader
                heading={action.Title}
                backText={"Countdown Timers"}
                backLink={"/Admin/Settings/Transactions/Timers"}
            />
            <div className={styles.TimerSettingBody}>
                <div>
                    <label>Duration</label>
                    <div>
                        <div>
                            <input type={"number"} value={hours} onChange={e => setHours(e.target.value)}/>
                            <label>Hours</label>
                        </div>
                        <div>
                            <input type={"number"} value={minutes} onChange={e => setMinutes(e.target.value)}/>
                            <label>Minutes</label>
                        </div>
                        <div>
                            <input type={"number"} value={seconds} onChange={e => setSeconds(e.target.value)}/>
                            <label>Seconds</label>
                        </div>
                    </div>
                </div>
                <button onClick={Submit}>Save</button>
            </div>
        </div>}
        </>
    );

}

export default TimerSetting;