// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_AuthFooter__b5CGn{border-top: 1px solid #EAECF0;display: flex;align-items: center;justify-content: space-between;}
.css_AuthFooter__b5CGn > p {margin: 0px;color: var(--gray-500, #667085);font-size: 14px;font-style: normal;font-weight: 450;line-height: 20px;padding: 10px 0px 10px 32px;text-align: left;}
.css_AuthFooter__b5CGn > a {text-decoration: none;padding: 10px 0px; padding-right: 32px; font-size: 14px;color: var(--gray-500, #667085);line-height: 20px;}`, "",{"version":3,"sources":["webpack://./src/Components/AuthFooter/css/index.module.css"],"names":[],"mappings":"AAAA,uBAAY,6BAA6B,CAAC,aAAa,CAAC,mBAAmB,CAAC,8BAA8B,CAAC;AAC3G,4BAAiB,WAAW,CAAC,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,2BAA2B,CAAC,gBAAgB,CAAC;AAChL,4BAAiB,qBAAqB,CAAC,iBAAiB,EAAE,mBAAmB,EAAE,eAAe,CAAC,+BAA+B,CAAC,iBAAiB,CAAC","sourcesContent":[".AuthFooter{border-top: 1px solid #EAECF0;display: flex;align-items: center;justify-content: space-between;}\n.AuthFooter > p {margin: 0px;color: var(--gray-500, #667085);font-size: 14px;font-style: normal;font-weight: 450;line-height: 20px;padding: 10px 0px 10px 32px;text-align: left;}\n.AuthFooter > a {text-decoration: none;padding: 10px 0px; padding-right: 32px; font-size: 14px;color: var(--gray-500, #667085);line-height: 20px;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AuthFooter": `css_AuthFooter__b5CGn`
};
export default ___CSS_LOADER_EXPORT___;
