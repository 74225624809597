import { Outlet } from "react-router-dom";
import AdminNavigation from "../AdminNavigation/Index";
import AuthHeader from "../AuthHeader/Index";
import styles from "./css/index.module.css";
import AuthFooter from "../AuthFooter/Index";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../store/AuthContext";
import useGaTracker from "./../../Utils/useGATracker";


const AuthAdminContainer = () => {

    useGaTracker();

    const isMob = window.innerWidth > 800;
    const [showNav, setShowNav] = useState(isMob);
    const AuthCtx = useContext(AuthContext);

    useEffect(() => {

        function handleResize() {
            setShowNav(window.innerWidth > 800)
          
        }
      
          window.addEventListener('resize', handleResize)

          const logoutHandler = setTimeout(() => {
            AuthCtx.logout();
          }, 3600000)

        return () => {
            window.removeEventListener('resize', handleResize);
            clearTimeout(logoutHandler);
        }
    },[AuthCtx])

    return(
        <div className={styles.AuthAdminContainer}>
            <AuthHeader isAdmin={true} setShowNav={setShowNav}/>
            <div>
            {(showNav || isMob) && <AdminNavigation setShowNav={setShowNav}/>}
                <div className={styles.Outlet}>
                <Outlet/>
                </div>
            </div>
            <AuthFooter/>
        </div>
    );

}

export default AuthAdminContainer;