import SettingsHeader from "../../../../../Components/SettingsHeader/Index";
import styles from "./css/index.module.css";
import AccountUpgraded from "./../../../../../img/AccountUpgraded.png";
import { useState } from "react";
import UploadId from "../../../Modals/UploadId/Index";

const AccountUpgrade = () => {
    const [showIdModal, setShowIdModal] = useState(false);

    return(
        <>
        {showIdModal && <UploadId showModal={setShowIdModal}/>}
            <div className={styles.AccountUpgrade}>
                <SettingsHeader
                    heading={"Account Upgrade"}
                    backText={"Settings"}
                    backLink={"/Dashboard/Settings"}
                />
                <div className={styles.AccountUpgradeBody}>
                    <div>
                        <div>
                            <div>
                                <span>1</span>
                            </div>
                            <div>
                                <h4>Level 1</h4>
                                <label>Limit per Transaction: $200</label>
                                <span>Verify phone number and email address</span>
                            </div>
                        </div>
                        <div>
                            {true ?
                            <img src={AccountUpgraded} alt="Account Upgraded"/> :
                            <button className={false ? "" : styles.InActive}>
                                Verify Now
                            </button>
                            }
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>
                                <span>2</span>
                            </div>
                            <div>
                                <h4>Level 2</h4>
                                <label>Limit per Transaction: $10,000</label>
                                <span>Add your legal identity</span>
                            </div>
                        </div>
                        <div>
                            {false ?
                            <img src={AccountUpgraded} alt="Account Upgraded"/> :
                            <button className={true ? "" : styles.InActive} onClick={() => setShowIdModal(true)}>
                                Verify Now
                            </button>
                            }
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>
                                <span>3</span>
                            </div>
                            <div>
                                <h4>Level 3</h4>
                                <label>Limit per Transaction: $10,000 and above</label>
                                <span>Confirm your  BVN or NIN</span>
                            </div>
                        </div>
                        <div>
                            {false ?
                            <img src={AccountUpgraded} alt="Account Upgraded"/> :
                            <button className={false ? "" : styles.InActive}>
                                Verify Now
                            </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default AccountUpgrade;