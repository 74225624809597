import { useContext, useState } from "react";
import SettingsHeader from "../../../../../Components/SettingsHeader/Index";
import styles from "./css/index.module.css";
import AuthContext from "../../../../../store/AuthContext";
import { BASE_URL, CHANGE_RATE } from "../../../../../Utils/Endpoint";

const RateSetting = ({rate}) => {
    const [rateVal, setRate] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const AuthCtx = useContext(AuthContext);

    let SetRateVal = async () => {

        if(rateVal === '' || rateVal === 0){
            setErrorMessage("Rate not set.")
            return null;
        }


        var code = rate.isUSDRate ? `${rate.ShortCode}-${rate.Short}` : rate.ShortCode

        var obj = {
            "currencyCode": code,
            "rate": rateVal
          };
        
        console.log(obj);

        var response = await fetch(BASE_URL + CHANGE_RATE,
            {
                method : "POST",
                body : JSON.stringify(obj),
                headers : {
                    'content-type': 'application/json',
                    "Authorization" : "Bearer " + AuthCtx.token,
                    'Accept': 'application/json'
                }
            })

            let json = await response.json();
            console.log(json);

            if(response.ok){
               
                setSuccessMessage("Rate successfully changed.");
                setErrorMessage('');
                setRate('');
                return null;
            }

            setSuccessMessage('');
            setErrorMessage(json.statusText);
            return null;
    }

    return(
        <div className={styles.RateSetting}>
            <SettingsHeader
                heading={rate.Name}
                backText={"Rates"}
                backLink={"/Admin/Settings/Transactions/Rates"}
            />
            <div className={styles.RateSettingBody}>
                <div>
                    <label>Rate</label>
                    <div>
                        <label>{rate?.isUSDRate ? "NGN/USD" : "USD" }</label>
                        <input type={"number"} value={rateVal} onChange={(e) => setRate(e.target.value)}/>
                    </div>
                </div>
                <span className={styles.ErrorMessage}>{errorMessage}</span>
                <span className={styles.SuccessMessage}>{successMessage}</span>
                <button onClick={() => SetRateVal()}>Save</button>
            </div>
        </div>
    );

}

export default RateSetting;