import { useContext, useState } from "react";
import Input from "../../Components/Input/Index";
import InputCtnr from "../../Components/InputCtnr/Index";
import OnBoardingCtnr from "../../Components/OnBoardingCtnr/Index";
import Button from "../../Components/Button/Index";
import styles from "./css/index.module.css";
import { BASE_URL, REQUEST_PWD_RESET } from "../../Utils/Endpoint";
import AuthContext from "../../store/AuthContext";
import useGaTracker from "./../../Utils/useGATracker";


const ForgotPassword = () => {

    useGaTracker();

    const [email, setEmail] = useState('');
    const [isSent ,setIsSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const AuthCtx = useContext(AuthContext);

    let setEmailHandler = (value) => {
        setEmail(value);
    }

    let submitEmail = async () => {
        if(email.length > 0)
        {
            var obj = {
                "email": email
              };
            console.log(obj);
            var response = await fetch(BASE_URL + REQUEST_PWD_RESET,
                {
                    method : "POST",
                    body : JSON.stringify(obj),
                    headers : {
                        'content-type': 'application/json',
                        "Authorization" : "Bearer " + AuthCtx.token
                    }
            })

            let json = await response.json();
            console.log(json);
    
            if(response.ok){
                setIsSent(true);
                return null;
            }   

            setErrorMessage(json.statusText);
            return null;
        }
    }

    return(
        <OnBoardingCtnr>
            <div className={styles.ForgotPassword}>
                <h3>Password Reset</h3>
                {isSent ?
                <h3>Password Request Successfully Sent</h3>
                :
                <>
                <InputCtnr label={"Email Address"}>
                    <Input
                        type={"email"}
                        placeholder={"johndoe@gmail.com"}
                        value={email}
                        setChange={setEmailHandler}
                    />
                </InputCtnr>
                <Button
                    text={"Reset password"}
                    action={submitEmail}
                    isEnabled={email.length > 0}
                />
                </>}
                <span className={styles.ErrorMessage}>{errorMessage}</span>
            </div>
        </OnBoardingCtnr>
    );

}

export default ForgotPassword;