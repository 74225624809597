import Title from "../../Components/Title/Index";
import UnAuthContainer from "../../Components/UnAuthContainer/Index";
import styles from "./css/index.module.css";
import useGaTracker from "./../../Utils/useGATracker";

const AntiMoney = () => {
    useGaTracker();

    return(
        <UnAuthContainer>
            <div>
                <div className={styles.FirstSection}>
                    <Title
                        label={"Current as of 20 Jan 2023"}
                        heading={"Anti-Money Laundering"}
                        text={"At CoinNest, we take our commitment to preventing money laundering and combating the financing of terrorism very seriously."}
                        bigTitle={true}
                    />
                </div>
                <div className={styles.SecondSection}>
                    <div>
                        <h3>Policy</h3>
                        <p>The Anti-Money Laundering and Combating the Financing of Terrorism Policy (“AMF/CFT Policy”) sets out the guidelines for Coinnest Africa’s compliance with AML/CFT obligations under the law, as well as regulatory directives, and actively prevents any transaction that facilitates criminal activities.</p>
                    </div>
                    <div>
                        <h3>Description</h3>
                        <p>Money laundering and financing terrorism are financial crimes with economic effects. 
                            It requires an underlying primary prot-making crime such as corruption, drug trafficking, market manipulation, fraud, tax evasion, etc., with the intent to conceal the proceeds of the crime and to further the criminal enterprise. 
                            AML/CFT compliance programme helps mitigate the adverse effects of criminal economic activity and promote integrity and stability in financial markets. It is also critical to preserving the Company’s corporate integrity, reputation and operational efficiency.</p>
                    </div>
                    <div>
                        <h3>Definitions</h3>
                        <p>Money Laundering: Money laundering (ML) has been deined as the process whereby criminals attempt to conceal the illegal origin and/or illegitimate ownership of property and assets that are the proceeds of their criminal activities. It is, thus, a derivative crime (Central Bank of Nigeria Manual).
                            Terrorism Financing: Includes both legitimate and illegitimate money characterised by concealment of the origin or intended criminal use of the funds.
                            Nigeria Financial Intelligence Unit: The Nigerian arm of the global Financial Intelligence Unit (FIU).
                            Politically Exposed Persons: Individuals who are or have been entrusted with prominent public functions in any country; generally presenting a higher risk for potential involvement in bribery and corruption by virtue of their position and the influence that they may hold.</p>
                    </div>
                    <div>
                        <h3>Purpose</h3>
                        <p>The purpose of this Policy manual is to:</p>
                        <p>i. Provide guidance on the standards of conduct and practice that must be followed in the implementation of the Know Your Customer (KYC) and Customer Due Diligence (CDD) requirements of the capital market.</p>
                        <p>ii. Protect the Company against fraud, reputational and other financial market risks.</p>
                        <p>iii. Minimise the risks faced by the Company from proceeds of crime.</p>
                        <p>iv. Protect the integrity of the securities market against all forms of abuse, fraudulent and unfair trade practices.
                        </p>
                    </div>
                    <div>
                        <h3>Policy Statement</h3>
                        <p>The following statements shall guide the principles and procedures for AML/CFT compliance programme. Coinnest Africa shall:</p>
                        <p>a. Formulate and implement internal controls and other procedures that will deter criminals from using its facilities for money laundering and terrorist financing and to ensure that its obligations under subsisting laws and Regulations are met.</p>
                        <p>b. Designate AML/CFT Chief Compliance Officer at the Management level, with the relevant competence, authority and independence to implement the institution’s AML/CFT compliance programme.</p>
                        <p>c. Comply with the requirements of the Money Laundering (Prohibition) Act, 2011 (as amended), Terrorism (Prevention) Act, 2011 (as and Terrorism Prevention (Freezing of International Terrorists Funds and other Related Measures) Regulations 2013, including related laws and Regulations.</p>
                        <p>d. Comply promptly with all the requests made pursuant to subsisting laws and Regulations and shall provide relevant information to the Nigerian Financial Intelligence Unit (NFIU) and other relevant law enforcement agencies on AML/CFT matters.</p>
                        <p>e. Identify and report to the NFIU, in the course of its business, any suspicious transactions derived from the criminal activities.</p>
                        <p>f. Ensure the implementation of the requirements of the AML/CFT Act are not inhibited through the Company’s Confidentiality Agreement or Policy.</p>
                        <p>g. Exit relationships which pose heightened money laundering risks to Coinnest Africa.</p>
                        <p>h. Effectively communicate this Policy to raise the level of staff awareness on AML/CFT issues.</p>
                    </div>
                    <div>
                        <h3>Awareness and Training</h3>
                        <p>a. Coinnest Africa shall create awareness amongst its employees on AML/CFT through a robust training program that will include formal courses, workshops and newsletters. Such trainings shall incorporate current developments and changes to relevant guidelines as well as internal Policies, procedures, processes and monitoring systems.</p>
                        <p>b. Coinnest Africa shall also utilise other avenues such as e-mails, laptop screensavers, display screens, posters etc. to disseminate compliance issues arising from new rules and regulations to all Staff members.</p>
                    </div>
                    <div>
                        <h3>Politically Exposed Persons (PEPs)</h3>
                        <p>Business relationships with family members or close associates of PEPs involve reputation risks similar to those PEPs themselves. Coinnest Africa shall evaluate the risks to its business operations when dealing with PEPs. The following factors shall guide identication of PEPs’ risk characteristics:</p>
                        <p>a. Nature of the client and the client’s businesses; the purpose and activity; the nature and relationship of the client with Coinnest Africa; the Client’s corporate structure and available public information.</p>
                    </div>
                    <div>
                        <h3>Responsibilities</h3>
                        <p>All Staff Members Whilst the Board of Directors, Internal Audit, Compliance Officer and other key personnel may have their respective roles regarding this AML/CFT Policy, all employees shall:</p>
                        <p>a. Familiarize themselves with guidelines, policies and best practices relating to their respective areas of responsibility.</p>
                        <p>b. Implementing the measures and approaches diligently and to the best of their ability.</p>
                        <p>c. Reporting any legal violations or other forms of misconduct in accordance with Coinnest Africa Policies and Procedures.</p>
                    </div>
                    <div>
                        <h3>Scope</h3>
                        <p>This Policy manual applies to Coinnest Africa and its Board of Directors, Management and Staff with respect to compliance with rules and regulations that govern its operations.</p>
                    </div>
                    <div>
                        <h3>Consequences</h3>
                        <p>A breach of the anti-money laundering and combating the financing of terrorism laws is a serious offence and could result in lengthy investigations, significanes and criminal sanctions (including imprisonment of employees).</p>
                    </div>
                </div>
            </div>
        </UnAuthContainer>
    );

}

export default AntiMoney;