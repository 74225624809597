import SettingsHeader from "../../../../../Components/SettingsHeader/Index";
import styles from "./css/index.module.css";
import Bank from "./../../../../../img/Bank.png";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { GetBankAccounts } from "../../../../../Utils/GetData";
import AuthContext from "../../../../../store/AuthContext";

const BankAccounts = () => {
    const [userAccounts, setAccounts] = useState(null);
    const AuthCtx = useContext(AuthContext);

    useEffect(() => {
        let getAccounts = async () => {
            let accounts = await GetBankAccounts(AuthCtx.token);
            console.log(accounts);
            setAccounts(accounts);
        };
        getAccounts();
    },[AuthCtx.token])

    return(
        <div className={styles.BankAccounts}>
            <SettingsHeader
                    heading={"Bank Accounts"}
                    backText={"Settings"}
                    backLink={"/Dashboard/Settings"}
            /> 
            <div className={styles.BankAccountsBody}>
                <div>
                    {userAccounts !== null && 
                    userAccounts.map((userAccount, index) => (
                        <Link key={index} to={'Details'} state={userAccount}>
                            <img src={Bank} alt="Bank Icon" />
                            <div>
                                <h4>{userAccount.accountName}</h4>
                                <span>{`${userAccount.bankName} - ${userAccount.accountNumber}`}</span>
                            </div>
                        </Link>
                    ))}
                </div>
                <Link to={"Add"}>Add bank account</Link>
            </div>
        </div>
    )

}

export default BankAccounts;