import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { BASE_URL, GET_TIMERS } from '../Utils/Endpoint';


const AuthContext = React.createContext({
  token: '',
  isLoggedIn: false,
  role : '',
  login: (token, route) => {},
  logout: () => {},
  timers: {
    buy: 
    {
      hours: 0,
      minutes: 0,
      seconds: 0
    },
    sell: 
    {
      hours: 0,
      minutes: 0,
      seconds: 0
    },
    swap: 
    {
      hours: 0,
      minutes: 0,
      seconds: 0
    },
    withdraw: 
    {
      hours: 0,
      minutes: 0,
      seconds: 0
    },
    deposit: 
    {
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  },  
});

export const AuthContextProvider = (props) => {
  const storedToken = localStorage.getItem('token');
  const [token, setToken] = useState(storedToken);
  const storedRole = localStorage.getItem('role');
  const [role, setRole] = useState(storedRole);
  const storedTimer = localStorage.getItem('timers');
  const [timers, setTimers] = useState(!!storedTimer ? JSON.parse(storedTimer) : null)
  const navigate = useNavigate();


  const userIsLoggedIn = !!token;

  const loginHandler = (token, role) => {
    (async () => {
      var response = await fetch(BASE_URL + GET_TIMERS)

      var result = await response.json()

      if(response.ok){
        var buy = result.data.filter(time => time.orderType === "Buy")[0]
        var sell = result.data.filter(time => time.orderType === "Sell")[0]
        var swap = result.data.filter(time => time.orderType === "Swap")[0]
        var deposit = result.data.filter(time => time.orderType === "Deposit")[0]
        var withdraw = result.data.filter(time => time.orderType === "Withdraw")[0]

        var obj = {
          buy: 
          {
            hours: buy.hours,
            minutes: buy.minutes,
            seconds: buy.seconds
          },
          sell: 
          {
            hours: sell.hours,
            minutes: sell.minutes,
            seconds: sell.seconds
          },
          swap: 
          {
            hours: swap.hours,
            minutes: swap.minutes,
            seconds: swap.seconds
          },
          withdraw: 
          {
            hours: withdraw.hours,
            minutes: withdraw.minutes,
            seconds: withdraw.seconds
          },
          deposit: 
          {
            hours: deposit.hours,
            minutes: deposit.minutes,
            seconds: deposit.seconds
          }
        }
        setTimers(obj)
        localStorage.setItem('timers', JSON.stringify(obj))
      }
  })()
    setToken(token);
    localStorage.setItem('token', token);
    setRole(role)
    localStorage.setItem('role', role);
    navigate(role === "Customer" ? "/Dashboard" : "/Admin");
  };

  const logoutHandler = () => {
    setToken(null);
    localStorage.removeItem('token');
    setRole(null);
    localStorage.removeItem('role');
    setTimers(null);
    localStorage.removeItem('timers');
    navigate("/Login", { replace: true });
  };

  const contextValue = {
    token: token,
    role: role,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    timers: timers
  };

  
  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;