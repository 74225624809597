import Title from "../../Components/Title/Index";
import UnAuthContainer from "../../Components/UnAuthContainer/Index";
import ContactAction from "./Components/ContactAction";
import styles from "./css/index.module.css";
import Email from "./../../img/Email.png";
import Chat from "./../../img/Chat.png";
import Location from "./../../img/Location.png";
import Call from "./../../img/Call.png";
import ArrowUp from "./../../img/ArrowUp.png";
import GoogleMap from "./../../img/GoogleMap.png";
import useGaTracker from "./../../Utils/useGATracker";

const Contact = () => {

    useGaTracker();

    return(
        <UnAuthContainer>
            <div className={styles.FirstSection}>
                <Title
                    label={"Contact us"}
                    heading={"Get in touch with us"}
                    text={"Our team is always here to answer your questions."}
                    bigTitle={true}
                />
            </div>
            <div className={styles.SecondSection}>
                <ContactAction
                    source={Email}
                    title={"Chat to sales"}
                    label={"Send us an email"}
                    text={<>info@coinnest.africa<br/>hello@coinnest.africa</>}
                />
                <ContactAction
                    source={Chat}
                    title={"Chat to support"}
                    label={"We’re available to help."}
                    text={<><a href="https://wa.me/message/56ONQOWYOQ32H1">Text on Whatsapp<img src={ArrowUp} alt="arrow up" /></a> </>}
                />
                <ContactAction
                    source={Location}
                    title={"Visit us"}
                    label={"Visit our office HQ."}
                    text={<>Intershore Chambers, Road Town,<br/>Tortola, British Virgin Islands</>}
                />
                <ContactAction
                    source={Call}
                    title={"Call us"}
                    label={<>Sun-Sat from 8am to 6pm.<br/>Also available on Whatsapp</>}
                    text={<>+234 813 029 4711 <br/>+234 913 282 7213</>}
                />
            </div>
            <div className={styles.ThirdSection}>
                <img src={GoogleMap} alt="Google Map" />
            </div>
        </UnAuthContainer>
    );

}

export default Contact;