import { Link } from "react-router-dom";
import SettingsHeader from "../../../../../Components/SettingsHeader/Index";
import styles from "./css/index.module.css";
import ArrowRight from "./../../../../../img/ArrowRight.png";
//import USD from "./../../../../../img/DollarSign.png";
//import NGN from "./../../../../../img/NairaSign.png";
import BTC from "./../../../../../img/BitcoinSign.png";
import PM from "./../../../../../img/PerfectMoneySign.png";
import USDT from "./../../../../../img/USDT.png";
import { ACTIONS, CURRENCIES } from "../../../../../Utils/StaticData";

const Rates = () => {
    

    return(
    <div className={styles.Rates}>
        <SettingsHeader
         heading={"Rates"}
         backText={"Settings / Transactions "}
         backLink={"/Admin/Settings/Transactions"}
        />
        <div className={styles.RatesBody}>
            {/* <Link to={CURRENCIES[0].ShortCode}>
                <div>
                    <img src={USD} alt="Dollar Sign" />
                    <span>United States Dollars</span>
                </div>
                <img src={ArrowRight} alt="Forward Arrow" />
            </Link>
            <Link to={CURRENCIES[1].ShortCode}>
                <div>
                    <img src={NGN} alt="Naira Sign" />
                    <span>Nigerian Naira</span>
                </div>
                <img src={ArrowRight} alt="Forward Arrow" />
            </Link> */}
            <Link to={CURRENCIES[2].ShortCode}>
                <div>
                    <img src={BTC} alt="Bitcoin Sign" />
                    <span>Bitcoin</span>
                </div>
                <img src={ArrowRight} alt="Forward Arrow" />
            </Link>
            <Link to={`${CURRENCIES[2].ShortCode}-${ACTIONS[0].Short}`}>
                <div>
                    <img src={BTC} alt="Bitcoin Sign" />
                    <span>Bitcoin Buy Dollar Rate</span>
                </div>
                <img src={ArrowRight} alt="Forward Arrow" />
            </Link>
            <Link to={`${CURRENCIES[2].ShortCode}-${ACTIONS[1].Short}`}>
                <div>
                    <img src={BTC} alt="Bitcoin Sign" />
                    <span>Bitcoin Sell Dollar Rate</span>
                </div>
                <img src={ArrowRight} alt="Forward Arrow" />
            </Link>
            <Link to={CURRENCIES[3].ShortCode}>
                <div>
                    <img src={PM} alt="Perfect Money Sign" />
                    <span>Perfect Money</span>
                </div>
                <img src={ArrowRight} alt="Forward Arrow" />
            </Link>
            <Link to={`${CURRENCIES[3].ShortCode}-${ACTIONS[0].Short}`}>
                <div>
                    <img src={PM} alt="Perfect Money Sign" />
                    <span>Perfect Money Buy Dollar Rate</span>
                </div>
                <img src={ArrowRight} alt="Forward Arrow" />
            </Link>
            <Link to={`${CURRENCIES[3].ShortCode}-${ACTIONS[1].Short}`}>
                <div>
                    <img src={PM} alt="Perfect Money Sign" />
                    <span>Perfect Money Sell Dollar Rate</span>
                </div>
                <img src={ArrowRight} alt="Forward Arrow" />
            </Link>
            <Link to={CURRENCIES[4].ShortCode}>
                <div>
                    <img src={USDT} alt="USDT Sign" style={{width : "40px", height : "40px"}} />
                    <span>USDT</span>
                </div>
                <img src={ArrowRight} alt="Forward Arrow" />
            </Link>
            <Link to={`${CURRENCIES[4].ShortCode}-${ACTIONS[0].Short}`}>
                <div>
                    <img src={USDT} alt="USDT Sign" style={{width : "40px", height : "40px"}} />
                    <span>USDT Buy Dollar Rate</span>
                </div>
                <img src={ArrowRight} alt="Forward Arrow" />
            </Link>
            <Link to={`${CURRENCIES[4].ShortCode}-${ACTIONS[1].Short}`}>
                <div>
                    <img src={USDT} alt="USDT Sign" style={{width : "40px", height : "40px"}} />
                    <span>USDT Sell Dollar Rate</span>
                </div>
                <img src={ArrowRight} alt="Forward Arrow" />
            </Link>
        </div>
    </div>
    );

}

export default Rates;