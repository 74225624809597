import { BASE_URL, GET_BANK_ACCOUNTS, GET_LATEST_FEES, GET_LATEST_RATES, GET_LIMIT } from "./Endpoint";

export let GetRates = async () => {
    var response = await fetch(BASE_URL + GET_LATEST_RATES,
        {
            method : "GET",
            headers : {
                'content-type': 'application/json',
                'Accept': 'application/json'
            }
        })

        if(response.ok){
            let json = await response.json();
            return json.data;
        }

        return null;
}

export let GetLatestFees = async () => {
    var response = await fetch(BASE_URL + GET_LATEST_FEES,
        {
            method : "GET",
            headers : {
                'content-type': 'application/json',
                'Accept': 'application/json'
            }
        })

        if(response.ok){
            let json = await response.json();
            return json.data;
        }

        return null;
}

export let GetBankAccounts = async (token) => {
    var response = await fetch(BASE_URL + GET_BANK_ACCOUNTS,
        {
            method : "GET",
            headers : {
                'content-type': 'application/json',
                "Authorization" : "Bearer " + token,
                'Accept': 'application/json'
            }
        })

        if(response.ok){
            let json = await response.json();
            return json.data;
        }

        return null;
}

export let GetLimitStatus = async (token, amount, currency = "NGN") => {
    var response = await fetch(BASE_URL + GET_LIMIT + "?amount=" + amount + "&currency=" + currency,
        {
            method : "GET",
            headers : {
                'content-type': 'application/json',
                "Authorization" : "Bearer " + token,
                'Accept': 'application/json'
            }
        })

        if(response.ok){
            let json = await response.json();
            return json.data;
        }

        return null;
}