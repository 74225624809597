import React,{ useContext } from 'react';
import AuthContext from '../../store/AuthContext';
import { Navigate } from "react-router-dom";

const ProtectedPages = ({ children }) =>{
    
    const AuthCtx = useContext(AuthContext);


    if(!AuthCtx.isLoggedIn){
        console.log("not protected.")
        return <Navigate to="/Login" />;
    }

    console.log("is protected.")

    return children;

}
export default ProtectedPages