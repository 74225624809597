import { useContext, useEffect, useState } from "react";
import DateOfBirth from "../../../../../Components/DateOfBirth/Index";
import SettingsHeader from "../../../../../Components/SettingsHeader/Index";
import styles from "./css/index.module.css"
import AuthContext from "../../../../../store/AuthContext";
import { BASE_URL, GET_USER, UPDATE_USER } from "../../../../../Utils/Endpoint";
import { useNavigate } from "react-router-dom";

const GeneralEdit = ({isAdmin = false}) => {
    const [user, setUser] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dob, setDob] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [address, setAddress] = useState('');

    const AuthCtx = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        let getTransactionsAction = async () => {
        
            var response = await fetch(BASE_URL + GET_USER,
                {
                    method : "GET",
                    headers : {
                        'content-type': 'application/json',
                        "Authorization" : "Bearer " + AuthCtx.token,
                        'Accept': 'application/json'
                    }
                })
    
                if(response.ok){
                    let json = await response.json();
                    setUser(json.data);
                }
        }

        getTransactionsAction()
        
    },[AuthCtx])

    useEffect(() => {

        if(user !== null){
            console.log(user);
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setPhoneNumber(user.phoneNumber);
            setDob(user.dateOfBirth);
            setCountry(user.country ?? "");
            setState(user.state ?? "");
            setAddress(user.streetAddress ?? "");
        }

    },[user])

    let EditUserAction = async () => {

        var response = await fetch(BASE_URL + UPDATE_USER + "?userId=" + user.id,
            {
                method : "PUT",
                body : JSON.stringify({
                    "firstName": firstName,
                    "lastName": lastName,
                    "dob": dob,
                    "country": country,
                    "phoneNumber": phoneNumber,
                    "address": address,
                    "state": state
                  }),
                headers : { 
                    'content-type': 'application/json',
                    "Authorization" : "Bearer " + AuthCtx.token,
                    'Accept': 'application/json'
                }
            })
            if(response.ok){
                navigate(isAdmin ? "/Admin/Settings/General" : "/Dashboard/Settings/General")
            } 

    }

    return(
        <div className={styles.GeneralEdit}>
            <SettingsHeader
                heading={"Personal Information"}
                backText={"General"}
                backLink={isAdmin ? "/Admin/Settings/General" : "/Dashboard/Settings/General"}
            />
            <div className={styles.GeneralEditBody}>
                <div>
                    <div>
                        <label>First Name</label>
                        <input type="text" value={firstName} onChange={e => setFirstName(e.target.value)} />
                    </div>
                    <div>
                        <label>Last Name</label>
                        <input type="text" value={lastName} onChange={e => setLastName(e.target.value)} />
                    </div>
                    <div>
                        <label>Date of Birth</label>
                        <div>
                            <DateOfBirth dob={dob} setDob={setDob}/>
                        </div>
                    </div>
                    <div>
                        <label>Phone Number</label>
                        <input type="text" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                    </div>
                    <div>
                        <label>Country</label>
                        <input type="text" value={country} onChange={e => setCountry(e.target.value)} />
                    </div>
                    <div>
                        <label>State</label>
                        <input type="text" value={state} onChange={e => setState(e.target.value)} />
                    </div>
                    <div>
                        <label>Address</label>
                        <input type="text" value={address} onChange={e => setAddress(e.target.value)} />
                    </div>
                </div>
                <div>
                    <button onClick={() => EditUserAction()}>Save</button>
                </div>
            </div>
        </div>
    )
}

export default GeneralEdit;