import { useState } from "react";
import Arrow from "./../../../img/Arrow.png";
import styles from "./../css/index.module.css"

const QAs = ({children, header}) => {
    const [isOpen, setIsOpen] = useState(false);

    return(
        <div onClick={() => setIsOpen(prevState => !prevState)} className={`${styles.QA} ${isOpen ? styles.IsOpen : ""}`}>
            <div>
                <h3>{header}</h3>
                <img src={Arrow} alt="Arrow"/>
            </div>
            {isOpen && children}
        </div>
    );

}

export default QAs;