import styles from "./css/index.module.css";
import Bank from "./../../../../../img/Bank.png";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../store/AuthContext";
import { useParams } from "react-router-dom";
import { BASE_URL, GET_BANK_ACCOUNTS } from "../../../../../Utils/Endpoint";


const CustomerBank = () => {

    const [banks, setBanks] = useState(null);
    const AuthCtx = useContext(AuthContext);
    const { id } = useParams();


    useEffect(() => {
        let getTransactionsAction = async () => {
        
            var response = await fetch(BASE_URL + GET_BANK_ACCOUNTS + "?userId=" + id,
                {
                    method : "GET",
                    headers : {
                        'content-type': 'application/json',
                        "Authorization" : "Bearer " + AuthCtx.token,
                        'Accept': 'application/json'
                    }
                })
    
                if(response.ok){
                    let json = await response.json();
                    setBanks(json.data);
                }
        }

        getTransactionsAction()
        
    },[AuthCtx, id])

    return(
        <div className={styles.CustomerBank}>
            <div className={styles.BankAccountsBody}>
                <div>
                    {banks !== null && 
                    banks.map((bank, index) => (
                    <div key={index}>
                        <img src={Bank} alt="Bank Icon" />
                        <div>
                            <h4>{bank.accountName}</h4>
                            <span>{bank.bankName} - {bank.accountNumber}</span>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </div>
    );

}

export default CustomerBank;