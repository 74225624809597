import styles from "./css/index.module.css";

const Title = ({label, heading, text, bigTitle = false, isFAQs = false}) => {

    return(
        <div className={styles.Header}>
            <label>{label}</label>
            <h2 className={`${bigTitle ? styles.IsBigger : ""} ${isFAQs ? styles.IsFAQs : ""}`}>{heading}</h2>
            <p>{text}</p>
        </div>
    );

}

export default Title