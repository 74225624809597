// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_Feature__OkFiO{display: flex; gap: 7px;margin-top: 32px;}
.css_Feature__OkFiO > img{align-self: flex-start;padding-top: 5px;}
.css_Feature__OkFiO > div > label{color: var(--gray-900, #101828);font-size: 18px;font-style: normal;font-weight: 500;line-height: 28px; }
.css_Feature__OkFiO > div > p{color: var(--gray-500, #667085);font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px;margin: 0px;}`, "",{"version":3,"sources":["webpack://./src/Components/Features/css/index.module.css"],"names":[],"mappings":"AAAA,oBAAS,aAAa,EAAE,QAAQ,CAAC,gBAAgB,CAAC;AAClD,0BAAe,sBAAsB,CAAC,gBAAgB,CAAC;AACvD,kCAAuB,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,EAAE;AAC9H,8BAAmB,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,WAAW,CAAC","sourcesContent":[".Feature{display: flex; gap: 7px;margin-top: 32px;}\n.Feature > img{align-self: flex-start;padding-top: 5px;}\n.Feature > div > label{color: var(--gray-900, #101828);font-size: 18px;font-style: normal;font-weight: 500;line-height: 28px; }\n.Feature > div > p{color: var(--gray-500, #667085);font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px;margin: 0px;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Feature": `css_Feature__OkFiO`
};
export default ___CSS_LOADER_EXPORT___;
