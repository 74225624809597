import styles from "./css/index.module.css";

const Button = ({isEnabled = false, text, action}) => {

    return(
    <button className={`${isEnabled ? styles.Active : styles.InActive} ${styles.Button}`} onClick={() => action()}>
        <span>{text}</span>
    </button>
    );

}

export default Button;