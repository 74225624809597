import styles from "./../css/index.module.css";
import BackArrow from "./../../../img/BackArrow.png";
import ProgressBar from "./ProgressBar";

const Title = ({title, currentValue, goBack = null}) => {

    return(
        <div className={styles.TitleFrame}>
            { goBack !== null &&
            <div className={styles.BackArrow} onClick={() => goBack()}>
                <img src={BackArrow} alt="Back Arrow" />
                <span>Back</span>
            </div>
            }
            <div className={styles.TitleCtnr}>
                <h3>{title}</h3>
                <ProgressBar currentValue={currentValue} />
            </div>
        </div>
    )

}

export default Title