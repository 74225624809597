export const CURRENCIES = [
    {
        ShortCode : "USD",
        Name : "United States Dollars",
        IsCrypto : false
    },
    {
        ShortCode : "NGN",
        Name : "Nigerian Naira",
        IsCrypto : false
    },
    {
        ShortCode : "BTC",
        Name : "Bitcoin",
        IsCrypto : true
    },
    {
        ShortCode : "PM",
        Name : "Perfect Money",
        IsCrypto : true
    },
    {
        ShortCode : "USDT",
        Name : "USDT",
        IsCrypto : true
    },
]

export const ACTIONS = [
    {
        Title : "Buy Order",
        Short : "Buy"
    },
    {
        Title : "Sell Order",
        Short : "Sell"
    },
    {
        Title : "Swap Order",
        Short : "Swap"
    },
    {
        Title : "Deposit",
        Short : "Deposit"
    },
    {
        Title : "Withdraw",
        Short : "Withdraw"
    }
];

export const USDT_NETWORKS = [
    "ERC-20",
    "TRC-20"
]

export const PYMNTMETHOD = [
    {
        Name : "Bank Transfer",
        Code : "Transfer"
    },
    {
        Name : "Wallet Balance",
        Code : "Wallet"
    }
]

export const APPROVED = "Approved";

export const DECLINED = "Declined";

export const DAYS = [...Array(31).keys()].map(i => i+1);

export const MONTHS = ["January","February","March","April","May","June","July",
                    "August","September","October","November","December"];

function generateArrayOfYears() {
    var max = new Date().getFullYear()
    var min = max - 80
    var years = []
    
    for (var i = max; i >= min; i--) {
        years.push(i)
    }
    return years
    }

export const YEARS = generateArrayOfYears();