// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_ForgotPassword__rb7V2 > h3{color: var(--gray-900, #101828);font-size: 24px;font-style: normal;font-weight: 500;line-height: 32px;margin: 0px;margin-bottom: 32px;text-align: left; }
.css_ErrorMessage__G28jF{color: red; font-size: 14px;margin-top: 12px;display: block;}`, "",{"version":3,"sources":["webpack://./src/Pages/ForgotPassword/css/index.module.css"],"names":[],"mappings":"AAAA,gCAAqB,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,WAAW,CAAC,mBAAmB,CAAC,gBAAgB,EAAE;AAC7K,yBAAc,UAAU,EAAE,eAAe,CAAC,gBAAgB,CAAC,cAAc,CAAC","sourcesContent":[".ForgotPassword > h3{color: var(--gray-900, #101828);font-size: 24px;font-style: normal;font-weight: 500;line-height: 32px;margin: 0px;margin-bottom: 32px;text-align: left; }\n.ErrorMessage{color: red; font-size: 14px;margin-top: 12px;display: block;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ForgotPassword": `css_ForgotPassword__rb7V2`,
	"ErrorMessage": `css_ErrorMessage__G28jF`
};
export default ___CSS_LOADER_EXPORT___;
