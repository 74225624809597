// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_LoadingCtnr__j5h5G{display: flex;align-items: center;justify-content: center;flex-grow: 1;}
.css_Loading__wXY2a{width: 28px; height: 28px;}`, "",{"version":3,"sources":["webpack://./src/Components/Loading/css/index.module.css"],"names":[],"mappings":"AAAA,wBAAa,aAAa,CAAC,mBAAmB,CAAC,uBAAuB,CAAC,YAAY,CAAC;AACpF,oBAAS,WAAW,EAAE,YAAY,CAAC","sourcesContent":[".LoadingCtnr{display: flex;align-items: center;justify-content: center;flex-grow: 1;}\n.Loading{width: 28px; height: 28px;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LoadingCtnr": `css_LoadingCtnr__j5h5G`,
	"Loading": `css_Loading__wXY2a`
};
export default ___CSS_LOADER_EXPORT___;
