import Footer from "../Footer/Index";
import Header from "../Header/Index";

const UnAuthContainer = ({children}) => {

    return(
        <>
        <Header/>
        {children}
        <Footer/>
        </>
    );

}

export default UnAuthContainer;