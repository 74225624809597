// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_Button__xAYa2{width: 100%;padding: 14px 0px;border-radius: 8px;border: none;box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);}
.css_Button__xAYa2 > span{color: #FFF;text-align: center;font-size: 16px;font-style: normal;font-weight: 600;line-height: 24px; }
.css_Active__ekG3t{background: var(--accent, #F60);}
.css_InActive__1vdeS{background: var(--gray-400, #98A2B3);}`, "",{"version":3,"sources":["webpack://./src/Components/Button/css/index.module.css"],"names":[],"mappings":"AAAA,mBAAQ,WAAW,CAAC,iBAAiB,CAAC,kBAAkB,CAAC,YAAY,CAAC,kDAAkD,CAAC;AACzH,0BAAe,WAAW,CAAC,kBAAkB,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,EAAE;AACrH,mBAAQ,+BAA+B,CAAC;AACxC,qBAAU,oCAAoC,CAAC","sourcesContent":[".Button{width: 100%;padding: 14px 0px;border-radius: 8px;border: none;box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);}\n.Button > span{color: #FFF;text-align: center;font-size: 16px;font-style: normal;font-weight: 600;line-height: 24px; }\n.Active{background: var(--accent, #F60);}\n.InActive{background: var(--gray-400, #98A2B3);}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Button": `css_Button__xAYa2`,
	"Active": `css_Active__ekG3t`,
	"InActive": `css_InActive__1vdeS`
};
export default ___CSS_LOADER_EXPORT___;
