import styles from "./../css/index.module.css"

const CarouselCard = ({initial, name, text}) => {

    return(
        <div className={styles.CarouselCard}>
            <p>{text}</p>
            <div>
                <div>
                    <span>{initial}</span>
                </div>
                <label>{name}</label>
            </div>
        </div>
    );

}

export default CarouselCard