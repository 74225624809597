// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_AuthAdminContainer__taYAr{height: 100vh;display: flex;flex-direction: column;}
.css_AuthAdminContainer__taYAr > div:nth-of-type(2){display: flex;flex: 1 1;}
.css_Outlet__WG1Bq{width: 100%;}`, "",{"version":3,"sources":["webpack://./src/Components/AuthAdminContainer/css/index.module.css"],"names":[],"mappings":"AAAA,+BAAoB,aAAa,CAAC,aAAa,CAAC,sBAAsB,CAAC;AACvE,oDAAyC,aAAa,CAAC,SAAO,CAAC;AAC/D,mBAAQ,WAAW,CAAC","sourcesContent":[".AuthAdminContainer{height: 100vh;display: flex;flex-direction: column;}\n.AuthAdminContainer > div:nth-of-type(2){display: flex;flex: 1;}\n.Outlet{width: 100%;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AuthAdminContainer": `css_AuthAdminContainer__taYAr`,
	"Outlet": `css_Outlet__WG1Bq`
};
export default ___CSS_LOADER_EXPORT___;
