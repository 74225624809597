import { useContext, useState } from "react";
import Button from "../../../../../Components/Button/Index";
import Input from "../../../../../Components/Input/Index";
import InputCtnr from "../../../../../Components/InputCtnr/Index";
import SettingsHeader from "../../../../../Components/SettingsHeader/Index";
import { BASE_URL, CHANGE_PWD } from "../../../../../Utils/Endpoint";
import styles from "./css/index.module.css"
import AuthContext from "../../../../../store/AuthContext";

const ChangePassword = ({isAdmin = false}) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPwd] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const AuthCtx = useContext(AuthContext);

    let SetPassword = async () => {

        if(newPassword !== confirmPassword){
            setErrorMessage("New Password and Confirm Password do not match.")
            return null;
        }

        var obj = {
            "newPassword": newPassword,
            "oldPassword": oldPassword
          };
        
        console.log(obj);

        if(oldPassword !== '' && newPassword !== ''){
        var response = await fetch(BASE_URL + CHANGE_PWD,
            {
                method : "POST",
                body : JSON.stringify(obj),
                headers : {
                    'content-type': 'application/json',
                    "Authorization" : "Bearer " + AuthCtx.token,
                    'Accept': 'application/json'
                }
            })

            let json = await response.json();
            console.log(json);

            if(response.ok){
               
                setSuccessMessage("Password successfully changed.");
                setErrorMessage('');
                setConfirmPwd('');
                setNewPassword('');
                setOldPassword('');
                return null;
            }

            setSuccessMessage('');
            setErrorMessage(json.statusText);
            return null;
        } 
    }

    return(
        <div className={styles.ChangePassword}>
            <SettingsHeader
                heading={"Change Password"}
                backText={"Security"}
                backLink={isAdmin ? "/Admin/Settings/Security" : "/Dashboard/Settings/Security"}
            />
            <div className={styles.ChangePasswordBody}>
                <InputCtnr label={"Current Password"}>
                    <Input
                        type={"password"}
                        isPassword={true}
                        value={oldPassword}
                        setChange={setOldPassword}
                    />
                </InputCtnr>
                <InputCtnr label={"New Password"}>
                    <Input
                        type={"password"}
                        isPassword={true}
                        value={newPassword}
                        setChange={setNewPassword}
                    />
                </InputCtnr>
                <InputCtnr label={"Confirm Password"}>
                    <Input
                        type={"password"}
                        isPassword={true}
                        value={confirmPassword}
                        setChange={setConfirmPwd}
                    />
                </InputCtnr>
                <span className={styles.ErrorMessage}>{errorMessage}</span>
                <span className={styles.SuccessMessage}>{successMessage}</span>
                <Button isEnabled={true} text={"Change Password"} action={SetPassword}/>
            </div>
        </div>
    )

}

export default ChangePassword;