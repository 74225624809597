import OTPInput from "react-otp-input";
import ModalContainer from "../ModalContainer/Index";
import styles from "./css/index.module.css";


const ConfirmPin = ({setPinModal, pin, setPin, setPinConfirm, errorMessage}) => {
    
    return(
        <ModalContainer showModal={setPinModal}>
            <div className={styles.Pin}>
                <h3>Confirm New Pin</h3>
                <p>Please enter your new transaction pin again</p>
                <div>
                    <OTPInput
                        numInputs={4}
                        value={pin}
                        onChange={setPin}
                        renderInput={(props) => <input {...props} />}
                        containerStyle={styles.OtpInputCtnr}
                        inputStyle={styles.OtpInput}
                        inputType={"tel"}
                    />
                </div>
                <span className={styles.ErrorMessage}>{errorMessage}</span>
                <div>
                    <button onClick={() => setPinConfirm()}>
                        Done
                    </button>
                </div>
            </div>
        </ModalContainer>
    )
}

export default ConfirmPin;