import SettingsHeader from "../../../../../Components/SettingsHeader/Index";
import styles from "./css/index.module.css";
import InputCtnr from "../../../../../Components/InputCtnr/Index";
import { useContext } from "react";
import { BASE_URL, DELETE_BANK_ACCOUNT } from "../../../../../Utils/Endpoint";
import AuthContext from "../../../../../store/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";

const BankAccountDetails = () => {
    
    const AuthCtx = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const state = location.state;

    let deleteAccountAction = async () => {
        
        var response = await fetch(BASE_URL + DELETE_BANK_ACCOUNT + "?bankAccountId=" + state.id,
            {
                method : "DELETE",
                body : JSON.stringify({
                  }),
                headers : {
                    'content-type': 'application/json',
                    "Authorization" : "Bearer " + AuthCtx.token,
                    'Accept': 'application/json'
                }
            })

            if(response.ok){
                let data = await response.json();
                console.log(data);
                navigate('/Dashboard/Settings/BankAccounts',{replace : true})
                
            }
    }

    return(
        <div className={styles.BankAccountDetails}>
            <SettingsHeader
                    heading={"Account Details"}
                    backText={"Bank Accounts"}
                    backLink={"/Dashboard/Settings/BankAccounts"}
            /> 
            <div className={styles.BankAccountDetailsBody}>
                <div>
                    <InputCtnr label={"Bank Name"}>
                        <div className={styles.BankNameDropdown}>
                            <span>{state.bankName}</span>
                        </div>
                    </InputCtnr>
                    <InputCtnr label={"Account Number"}>
                        <div className={styles.BankNameDropdown}>
                            <span>{state.accountNumber}</span>
                        </div>
                    </InputCtnr>
                    <InputCtnr label={"Account Name"}>
                        <div className={styles.BankNameDropdown}>
                            <span>{state.accountName}</span>
                        </div>
                    </InputCtnr>
                </div>
                <div className={styles.Footer}>
                    <button onClick={() => deleteAccountAction()}>
                        Delete Account
                    </button>
                </div>
            </div>
        </div>
    )

}

export default BankAccountDetails;