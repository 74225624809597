import { useContext, useEffect, useState } from "react";
import AmountHide from "./../../../../img/AmountView.png";
import AmountView from "./../../../../img/PwdEye.png";
import styles from "./../css/index.module.css";
import Deposit from "../../Modals/Deposit/Index";
import Withdraw from "../../Modals/Withdraw/Index";
import { BASE_URL, DEPOSIT, GET_WALLET_AMOUNT, WITHDRAW } from "../../../../Utils/Endpoint";
import AuthContext from "../../../../store/AuthContext";
import { GetBankAccounts, GetRates, /* GetLimitStatus */ } from "../../../../Utils/GetData";
import BankTransfer from "../../Modals/BankTransfer/Index";
import Pending from "../../Modals/Pending/Index";
import Completed from "../../Modals/Completed/Index";
import Failed from "../../Modals/Failed/Index";
import Pin from "../../Modals/Pin/Index";
import loading from "./../../../../img/loading.gif";
import refresh from "./../../../../img/refresh.png";
import IsLimited from "../../Modals/IsLimited/Index";
import { ACTIONS, CURRENCIES } from "../../../../Utils/StaticData";

const Wallet = () => {
    let currencies = ["USD", "NGN"];
    const [currency, setCurrency] = useState(currencies[1]);
    const [amount, setAmount] = useState(null);
    const [rate, setRate] = useState(null);
    const [rateId, setRateId] = useState(null);
    const [bankAccts, setBankAccts] = useState(null);
    const [activebank, setActiveBank] = useState(null);
    const [pin, setPin] = useState('');
    const [showAmount, setShowAmount] = useState(true);
    const [showDepositModal, setDepositModal] = useState(false);
    const [showWithdrawModal, setWithdrawModal] = useState(false);
    const [showBankTransfer, setBankTransfer] = useState(false);
    const [showPinModal, setShowPinModal] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [isWithdrawing, setIsWithdrawing] = useState(false);
    const [depAmount, setDepAmount] = useState('');
    const [withAmount, setWithAmount] = useState('');
    const [amountCnt,setAmountCnt] = useState(0);
    const [limitedModal,setLimitedModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const AuthCtx = useContext(AuthContext);

    /*
    let checkLimit = async (amount) => {
        let response = await GetLimitStatus(AuthCtx.token, amount);
        return response;
    }
    */

    let depositAction = async () => {
        
        setIsLoading(true)
        setIsWithdrawing(false);
        var response = await fetch(BASE_URL + DEPOSIT,
            {
                method : "POST",
                body : JSON.stringify({
                    "amount": parseFloat(depAmount),
                    "nairaRate": rateId
                  }),
                headers : {
                    'content-type': 'application/json',
                    "Authorization" : "Bearer " + AuthCtx.token,
                    'Accept': 'application/json'
                }
            })

            if(response.ok){
                setIsLoading(false);
                setBankTransfer(false);
                setIsPending(true);
                setDepAmount("");
                return null;
            }
    }

    let setModalCheck = async (setTransModal, amount) => {

        /*
        let response = await checkLimit(amount);

        if(!response){
            setTransModal();
            return null;
        }

        setWithdrawModal(false);
        setDepositModal(false)
        setLimitedModal(true);
        */
        setTransModal();
    }

    


    let withdrawAction = async () => {

        setIsLoading(true)
        setIsWithdrawing(true)
        var obj = {
                    "amount": parseFloat(withAmount),
                    "bankAccountId": activebank?.id,
                    "nairaRate": rateId,
                    "pin": pin
                };
        console.log(obj);
        var response = await fetch(BASE_URL + WITHDRAW,
            {
                method : "POST",
                body : JSON.stringify(obj),
                headers : {
                    'content-type': 'application/json',
                    "Authorization" : "Bearer " + AuthCtx.token,
                    'Accept': 'application/json'
                }
            })

            if(response.ok){
                setIsLoading(false);
                setShowPinModal(false);
                setIsPending(true);
                setWithAmount("");
                return null;
            }

            let json = await response.json();

            setErrorMessage(json.statusText);
    }

    useEffect(() => {
        let GetBankAccts = async () => {
            let response = await GetBankAccounts(AuthCtx.token);
            setBankAccts(response);
        }

        GetBankAccts();
    },[AuthCtx])

    useEffect(() => {

        let getAmount = async () => {

            setAmount(null);
            let rates = await GetRates();
            let rateObj = rates.filter(x => x.currencyShort === `${CURRENCIES[4].ShortCode}-${ACTIONS[0].Short}`)[0]
            setRate(rateObj.rate);
            setRateId(rateObj.id);
            
            var response = await fetch(BASE_URL + GET_WALLET_AMOUNT,
                {
                    method : "GET",
                    headers : {
                        'content-type': 'application/json',
                        "Authorization" : "Bearer " + AuthCtx.token,
                        'Accept': 'application/json'
                    }
                })

                if(response.ok){
                    let json = await response.json();
                    setAmount(json.data.amount);
                    //setAmount(770000);
                }
        }
    
        getAmount();
    },[AuthCtx,amountCnt])

    

    return(<>
            {limitedModal && <IsLimited showModal={setLimitedModal}/>}
            {isPending && <Pending setIsPending={setIsPending} setIsCompleted={setIsCompleted} showCancel={true} type={isWithdrawing ? 'withdraw' :'deposit'}/>}
            {isCompleted && <Completed setIsCompleted={setIsCompleted}/>}
            {false && <Failed/>}
            {showDepositModal && <Deposit walletBalance={amount} rate={rate} setDepositModal={setDepositModal} depAmount={depAmount} setDepAmount={setDepAmount} setBankTransfer={setBankTransfer} setModalCheck={setModalCheck}/>}
            {showWithdrawModal && <Withdraw  walletBalance={amount} rate={rate} setWithdrawModal={setWithdrawModal} withAmount={withAmount} setWithAmount={setWithAmount} bankAccts={bankAccts} setActiveBank={setActiveBank} activebank={activebank} setShowPinModal={setShowPinModal} setModalCheck={setModalCheck}/>}
            {showBankTransfer && <BankTransfer depAmount={depAmount} rate={rate} setBankTransfer={setBankTransfer} depositAction={depositAction} isLoading={isLoading}/>}
            {showPinModal && <Pin pin={pin} setPin={setPin} setPinModal={setShowPinModal} setPinConfirm={withdrawAction} errorMessage={errorMessage} isLoading={isLoading}/>}
            <div className={styles.Wallet}>
                
                <div>
                    <label>Wallet balance</label>
                    <div>
                        <button 
                            className={currency === currencies[0] ? styles.ActiveCurrency : ""} 
                            onClick={() => setCurrency(currencies[0])}>
                                USD
                        </button>
                        <button 
                        onClick={() => setCurrency(currencies[1])}
                        className={currency === currencies[1] ? styles.ActiveCurrency : ""} 
                        >
                            NGN
                        </button>
                    </div>
                </div>
                <div>{amount === null ?
                        <img src={loading} alt="loading" className={styles.Loading} /> 
                        :
                        <h3>{showAmount ? (currency === currencies[0] ? `$ ${(amount/rate).toFixed(2)}` : `₦ ${amount.toFixed(2)}`) : "--------"}</h3>
                    }
                    <img src={showAmount ? AmountHide : AmountView} alt="View Money Toggle"  onClick={() => setShowAmount(prevState => !prevState)}/>
                </div>
                <div>
                    <button onClick={() => setDepositModal(true)}>Deposit</button>
                    <button onClick={() => setWithdrawModal(true)}>Withdraw</button>
                    <img src={refresh} alt="refresh" className={styles.Refresh} onClick={() => setAmountCnt(prev => ++prev)}/>
                </div>
            </div>
        </>
    )

}

export default Wallet;