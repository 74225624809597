// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_ModalContainerBackGround__3rvZn{position: fixed;top: 0;left: 0;width:100%;height: 100%;background: rgba(0, 0, 0, 0.6);z-index: 10;}
.css_ModalContainerForeground__JQod2{position:fixed;width: 390px;height: auto;top:50%;left:50%;transform: translate(-50%,-50%);padding: 32px;border-radius: 12px;border: 1px solid var(--gray-100, #F2F4F7);background: #FFF;box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);z-index: 20;}
.css_ModalContainerBackGround__3rvZn > img{position: absolute;top: 12px;right: 12px;}

@media screen and (max-width : 400px) { 
    .css_ModalContainerForeground__JQod2{width: 75%;}
}`, "",{"version":3,"sources":["webpack://./src/Pages/Customer/Modals/ModalContainer/css/index.module.css"],"names":[],"mappings":"AAAA,qCAA0B,eAAe,CAAC,MAAM,CAAC,OAAO,CAAC,UAAU,CAAC,YAAY,CAAC,8BAA8B,CAAC,WAAW,CAAC;AAC5H,qCAA0B,cAAc,CAAC,YAAY,CAAC,YAAY,CAAC,OAAO,CAAC,QAAQ,CAAC,+BAA+B,CAAC,aAAa,CAAC,mBAAmB,CAAC,0CAA0C,CAAC,gBAAgB,CAAC,kDAAkD,CAAC,WAAW,CAAC;AACjR,2CAAgC,kBAAkB,CAAC,SAAS,CAAC,WAAW,CAAC;;AAEzE;IACI,qCAA0B,UAAU,CAAC;AACzC","sourcesContent":[".ModalContainerBackGround{position: fixed;top: 0;left: 0;width:100%;height: 100%;background: rgba(0, 0, 0, 0.6);z-index: 10;}\n.ModalContainerForeground{position:fixed;width: 390px;height: auto;top:50%;left:50%;transform: translate(-50%,-50%);padding: 32px;border-radius: 12px;border: 1px solid var(--gray-100, #F2F4F7);background: #FFF;box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);z-index: 20;}\n.ModalContainerBackGround > img{position: absolute;top: 12px;right: 12px;}\n\n@media screen and (max-width : 400px) { \n    .ModalContainerForeground{width: 75%;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ModalContainerBackGround": `css_ModalContainerBackGround__3rvZn`,
	"ModalContainerForeground": `css_ModalContainerForeground__JQod2`
};
export default ___CSS_LOADER_EXPORT___;
