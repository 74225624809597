// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_DateOfBirth__RbcCn{display: flex; gap: 16px;text-align: left;}
.css_DateOfBirth__RbcCn span{color: var(--gray-500, #667085);font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px; /* 150% */}
.css_DateOfBirth__RbcCn > div > div:nth-of-type(1){border-radius: 8px;border: 1px solid var(--gray-300, #D0D5DD);background: var(--White, #FFF);padding: 14px;display: flex; justify-content: space-between; align-items: center;box-sizing: border-box;}
.css_Day__X-wkZ, .css_Month__d-sb-, .css_Year__8kIQK {position: relative;cursor: pointer;}
.css_Day__X-wkZ > div:nth-of-type(2), .css_Month__d-sb- > div:nth-of-type(2), .css_Year__8kIQK > div:nth-of-type(2) {position: absolute;width: 100%;box-sizing: border-box;display: flex;flex-direction: column;border-radius: 8px;border: 1px solid var(--gray-300, #D0D5DD);background: var(--White, #FFF);height: 200px;overflow-y: scroll;}
.css_Day__X-wkZ {width: 20%;}
.css_Month__d-sb-{width: 50%;}
.css_Year__8kIQK{width: 30%;}
.css_Day__X-wkZ > div:nth-of-type(2) > span, .css_Month__d-sb- > div:nth-of-type(2) > span, .css_Year__8kIQK > div:nth-of-type(2) > span {padding: 14px;}`, "",{"version":3,"sources":["webpack://./src/Components/DateOfBirth/css/index.module.css"],"names":[],"mappings":"AAAA,wBAAa,aAAa,EAAE,SAAS,CAAC,gBAAgB,CAAC;AACvD,6BAAkB,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,EAAE,SAAS,CAAC;AACnI,mDAAwC,kBAAkB,CAAC,0CAA0C,CAAC,8BAA8B,CAAC,aAAa,CAAC,aAAa,EAAE,8BAA8B,EAAE,mBAAmB,CAAC,sBAAsB,CAAC;AAC7O,sDAAqB,kBAAkB,CAAC,eAAe,CAAC;AACxD,qHAAoF,kBAAkB,CAAC,WAAW,CAAC,sBAAsB,CAAC,aAAa,CAAC,sBAAsB,CAAC,kBAAkB,CAAC,0CAA0C,CAAC,8BAA8B,CAAC,aAAa,CAAC,kBAAkB,CAAC;AAC7S,iBAAM,UAAU,CAAC;AACjB,kBAAO,UAAU,CAAC;AAClB,iBAAM,UAAU,CAAC;AACjB,0IAAyG,aAAa,CAAC","sourcesContent":[".DateOfBirth{display: flex; gap: 16px;text-align: left;}\n.DateOfBirth span{color: var(--gray-500, #667085);font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px; /* 150% */}\n.DateOfBirth > div > div:nth-of-type(1){border-radius: 8px;border: 1px solid var(--gray-300, #D0D5DD);background: var(--White, #FFF);padding: 14px;display: flex; justify-content: space-between; align-items: center;box-sizing: border-box;}\n.Day, .Month, .Year {position: relative;cursor: pointer;}\n.Day > div:nth-of-type(2), .Month > div:nth-of-type(2), .Year > div:nth-of-type(2) {position: absolute;width: 100%;box-sizing: border-box;display: flex;flex-direction: column;border-radius: 8px;border: 1px solid var(--gray-300, #D0D5DD);background: var(--White, #FFF);height: 200px;overflow-y: scroll;}\n.Day {width: 20%;}\n.Month{width: 50%;}\n.Year{width: 30%;}\n.Day > div:nth-of-type(2) > span, .Month > div:nth-of-type(2) > span, .Year > div:nth-of-type(2) > span {padding: 14px;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DateOfBirth": `css_DateOfBirth__RbcCn`,
	"Day": `css_Day__X-wkZ`,
	"Month": `css_Month__d-sb-`,
	"Year": `css_Year__8kIQK`
};
export default ___CSS_LOADER_EXPORT___;
