import styles from "./css/index.module.css";
import SearchIcon from "./../../../img/Search.png";
import FilterIcon from "./../../../img/Filter.png";
import DownloadIcon from "./../../../img/Download.png";
import Transactions from "../../../Components/Transactions/Index";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../store/AuthContext";
import { BASE_URL, GET_TRANSACTION_LIST } from "../../../Utils/Endpoint";

const ATransactions = () => {

    const [transactions, setTransactions] = useState(null);
    const actions = ["Buy", "Sell", "Deposit", "Withdraw", "Swap"];

    const AuthCtx = useContext(AuthContext);


    useEffect(() => {
        let getTransactionsAction = async () => {
        
            var response = await fetch(BASE_URL + GET_TRANSACTION_LIST + "?isAdmin=" + true,
                {
                    method : "GET",
                    headers : {
                        'content-type': 'application/json',
                        "Authorization" : "Bearer " + AuthCtx.token,
                        'Accept': 'application/json'
                    }
                })
    
                if(response.ok){
                    let json = await response.json();
                    setTransactions(json.data);
                }
        }

        getTransactionsAction()
        
    },[AuthCtx])

    let rtnCurrencyLong = (currency) => {
        switch(currency){
            case "BTC":
                return "Bitcoin";
            case "PM":
                return "Perfect Money";
            case "NGN":
                return "Naira";
            case "USDT":
                return "USDT";
            case "USD":
                return "US Dollar";
            default:
                return "";
        }
    }

    let rtnActionDetails = (trans) => {
        switch(trans.transactionType){
            case actions[0]:
                return `Buy ${rtnCurrencyLong(trans.destinationCurrency)}`;
            case actions[1]:
                return `Sell ${rtnCurrencyLong(trans.sourceCurrency)}`;
            case actions[2]:
                return "Wallet Deposit";
            case actions[3]:
                return "Wallet Withdrawal";
            case actions[4]:
                return `${rtnCurrencyLong(trans.sourceCurrency)} to ${rtnCurrencyLong(trans.destinationCurrency)}`;
            default:
                return null;
        }
    }

    let rtnRate = (trans) => {
        switch(trans.transactionType){
            case actions[0]:
                return `$${trans.cryptoTradeInfo.destinationRate.rate}`;
            case actions[1]:
                return `$${trans.cryptoTradeInfo.sourceRate.rate}`;
            case actions[2]:
                return `NGN ${trans.buyNairaRate.rate}`;
            case actions[3]:
                return `NGN ${trans.buyNairaRate.rate}`;
            case actions[4]:
                return `$${trans.cryptoTradeInfo.sourceRate.rate}`;
            default:
                return null;
        }
    }

    let rtnAmount = (trans, isModal = false) => {
        switch(trans.transactionType){
            case actions[0]:
                return `${(trans.amount / (trans.buyNairaRate.rate * trans.cryptoTradeInfo.destinationRate.rate)).toFixed(8)} ${trans.destinationCurrency}`;
            case actions[1]:
                return `${(trans.amount / (trans.sellNairaRate.rate * trans.cryptoTradeInfo.sourceRate.rate)).toFixed(8)} ${trans.sourceCurrency}`;
            case actions[2]:
                return isModal ? `${(trans.amount/trans.buyNairaRate.rate).toFixed(2)} USD` : `${trans.amount.toFixed(2)} NGN`;
            case actions[3]:
                return isModal ? `${(trans.amount/trans.buyNairaRate.rate).toFixed(2)} USD` : `${trans.amount.toFixed(2)} NGN`;
            case actions[4]:
                return `${(trans.amount / (trans.buyNairaRate.rate * trans.cryptoTradeInfo.sourceRate.rate)).toFixed(8)} ${trans.sourceCurrency}`;
            default:
                return null;
        }
    }

    let rtnDate = (date) => {

        let newDate = new Date(date);
        var strDate = newDate.toLocaleDateString('en-US', {day:"numeric", year:"numeric", month:"numeric"});
        return strDate;

    }

    const downloadObjectAsCsv = (arr, filename) => {
        let csvString = '';
        
        // Convert object to CSV string
        // Add column headers if needed
        csvString += 'Order No,Customer Name,Customer Email,Transaction,Rate,Amount,Status,Date Created\n';
        
        // Iterate over the object to construct CSV string
        arr.forEach(key => {
            csvString += `${key.orderNo},${key.customerName},${key.customerEmail},${key.transaction},${key.rate},${key.amount},${key.status},${key.dateCreated}\n`;
        });
    
        // Create blob and download
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename + '.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return(
        <div className={styles.ATransactions}>
            <h3>Transactions</h3>
            <div>
                <div>
                    <img src={SearchIcon} alt="Search icon"/>
                    <input  placeholder="Search"/>
                </div>
                <div>
                    <button>
                        <img src={FilterIcon} alt="Filter icon"/>
                        <span>Sort by</span>
                    </button>
                    <button onClick={() => downloadObjectAsCsv(transactions.map((transaction, i) =>  {
                        return {
                            orderNo: '#' + transaction.id.substring(0,6),
                            customerName: transaction.customer.firstName + " " + transaction.customer.lastName,
                            customerEmail: transaction.customer.email,
                            transaction: rtnActionDetails(transaction),
                            rate: rtnRate(transaction),
                            amount: rtnAmount(transaction),
                            status: transaction.status,
                            dateCreated: rtnDate(transaction.dateCreated)
                        }
                    }), `transactions-${new Date()}`)}>
                        <img src={DownloadIcon} alt="Download icon"/>
                        <span>Download CSV</span>
                    </button>
                </div>
            </div>
            <Transactions transactions={transactions} isAdmin={true} setTransactions={setTransactions}/>
        </div>
    );

}

export default ATransactions;