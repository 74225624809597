// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_AdminNavigation__RjbJp{border-right: 1px solid #EAECF0;height: 100%;}
.css_AdminNavigation__RjbJp > ul{margin: 0px; padding: 32px 24px 0px 32px;list-style-type: none;text-align: left;display: flex;flex-direction: column;gap: 16px;width: /*204px*/auto;}
.css_AdminNavigation__RjbJp > ul > li{display: flex;}
.css_AdminNavigation__RjbJp > ul > li > a{text-decoration: none;color: var(--gray-500, #667085);padding: 10px;display: flex;}
.css_AdminNavigation__RjbJp > ul > li > a.css_ActiveNav__UOj8f{border-radius: 8px;background: var(--gray-100, #F2F4F7);}
.css_AdminNavigation__RjbJp > ul > li > a > div{display: flex;gap: 10px; align-items: center;font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px;}
.css_AdminNavigation__RjbJp > ul > li > a.css_ActiveNav__UOj8f > div > span{color: var(--primary-900, #060661);}
.css_AdminNavigation__RjbJp > ul > li > a > div > span{display: none;}

.css_AdminNavigation__RjbJp > ul > li > a.css_IsHovered__bd01g > div > span{display: flex;width: 120px;}

@media screen and (max-width : 800px) { 
    .css_AdminNavigation__RjbJp > ul{position: absolute;background-color: white;z-index: 100;border-right: 1px solid #EAECF0;height: calc(100% - 32px);}
}`, "",{"version":3,"sources":["webpack://./src/Components/AdminNavigation/css/index.module.css"],"names":[],"mappings":"AAAA,4BAAiB,+BAA+B,CAAC,YAAY,CAAC;AAC9D,iCAAsB,WAAW,EAAE,2BAA2B,CAAC,qBAAqB,CAAC,gBAAgB,CAAC,aAAa,CAAC,sBAAsB,CAAC,SAAS,CAAC,oBAAoB,CAAC;AAC1K,sCAA2B,aAAa,CAAC;AACzC,0CAA+B,qBAAqB,CAAC,+BAA+B,CAAC,aAAa,CAAC,aAAa,CAAC;AACjH,+DAAyC,kBAAkB,CAAC,oCAAoC,CAAC;AACjG,gDAAqC,aAAa,CAAC,SAAS,EAAE,mBAAmB,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC;AACxJ,4EAAsD,kCAAkC,CAAC;AACzF,uDAA4C,aAAa,CAAC;;AAE1D,4EAAsD,aAAa,CAAC,YAAY,CAAC;;AAEjF;IACI,iCAAsB,kBAAkB,CAAC,uBAAuB,CAAC,YAAY,CAAC,+BAA+B,CAAC,yBAAyB,CAAC;AAC5I","sourcesContent":[".AdminNavigation{border-right: 1px solid #EAECF0;height: 100%;}\n.AdminNavigation > ul{margin: 0px; padding: 32px 24px 0px 32px;list-style-type: none;text-align: left;display: flex;flex-direction: column;gap: 16px;width: /*204px*/auto;}\n.AdminNavigation > ul > li{display: flex;}\n.AdminNavigation > ul > li > a{text-decoration: none;color: var(--gray-500, #667085);padding: 10px;display: flex;}\n.AdminNavigation > ul > li > a.ActiveNav{border-radius: 8px;background: var(--gray-100, #F2F4F7);}\n.AdminNavigation > ul > li > a > div{display: flex;gap: 10px; align-items: center;font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px;}\n.AdminNavigation > ul > li > a.ActiveNav > div > span{color: var(--primary-900, #060661);}\n.AdminNavigation > ul > li > a > div > span{display: none;}\n\n.AdminNavigation > ul > li > a.IsHovered > div > span{display: flex;width: 120px;}\n\n@media screen and (max-width : 800px) { \n    .AdminNavigation > ul{position: absolute;background-color: white;z-index: 100;border-right: 1px solid #EAECF0;height: calc(100% - 32px);}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AdminNavigation": `css_AdminNavigation__RjbJp`,
	"ActiveNav": `css_ActiveNav__UOj8f`,
	"IsHovered": `css_IsHovered__bd01g`
};
export default ___CSS_LOADER_EXPORT___;
