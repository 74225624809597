import styles from "./css/index.module.css";
import Buy from "./../../img/BuyBig.png";
import Sell from "./../../img/SellBig.png";
import Deposit from "./../../img/DepositBig.png";
import Withdraw from "./../../img/WithdrawBig.png";
import Swap from "./../../img/SwapBig.png";
import User from "./../../img/User.png";
import { useEffect, useState } from "react";
import TransDetails from "../TransDetails/Index";
import { PYMNTMETHOD } from "../../Utils/StaticData";

const Transactions = ({transactions, setTransactions ,isAdmin = false, filterType = "All"}) => {
    const actions = ["Buy", "Sell", "Deposit", "Withdraw", "Swap"];
    const stati = ["Approved", "Declined", "Pending"];
    const [showModal, setModal] = useState(false);
    const [details, setDetails] = useState({});
    const [filteredTransaction, setFilter] = useState(transactions);

    let rtnImg = (action) => {

        switch(action){
            case actions[0]:
                return Buy;
            case actions[1]:
                return Sell;
            case actions[2]:
                return Deposit;
            case actions[3]:
                return Withdraw;
            case actions[4]:
                return Swap;
            default:
                return null;
        }
    }

    let rtnClass = (action) => {

        switch(action){
            case stati[0]:
                return styles.Approved;
            case stati[1]:
                return styles.Declined;
            case stati[2]:
                return styles.Pending;
            default:
                return null;
        }
    }

    let rtnCurrencyLong = (currency) => {
        switch(currency){
            case "BTC":
                return "Bitcoin";
            case "PM":
                return "Perfect Money";
            case "NGN":
                return "Naira";
            case "USDT":
                return "USDT";
            case "USD":
                return "US Dollar";
            default:
                return "";
        }
    }

    let rtnActionDetails = (trans) => {
        switch(trans.transactionType){
            case actions[0]:
                return `Buy ${rtnCurrencyLong(trans.destinationCurrency)}`;
            case actions[1]:
                return `Sell ${rtnCurrencyLong(trans.sourceCurrency)}`;
            case actions[2]:
                return "Wallet Deposit";
            case actions[3]:
                return "Wallet Withdrawal";
            case actions[4]:
                return `${rtnCurrencyLong(trans.sourceCurrency)} to ${rtnCurrencyLong(trans.destinationCurrency)}`;
            default:
                return null;
        }
    }

    let rtnRate = (trans) => {
        switch(trans.transactionType){
            case actions[0]:
                return `$${trans.cryptoTradeInfo.destinationRate.rate}`;
            case actions[1]:
                return `$${trans.cryptoTradeInfo.sourceRate.rate}`;
            case actions[2]:
                return `NGN ${trans.buyNairaRate.rate}`;
            case actions[3]:
                return `NGN ${trans.buyNairaRate.rate}`;
            case actions[4]:
                return `$${trans.cryptoTradeInfo.sourceRate.rate}`;
            default:
                return null;
        }
    }

    let rtnAmount = (trans, isModal = false) => {
        switch(trans.transactionType){
            case actions[0]:
                return `${(trans.amount / (trans.buyNairaRate.rate * trans.cryptoTradeInfo.destinationRate.rate)).toFixed(8)} ${trans.destinationCurrency}`;
            case actions[1]:
                return `${(trans.amount / (trans.sellNairaRate.rate * trans.cryptoTradeInfo.sourceRate.rate)).toFixed(8)} ${trans.sourceCurrency}`;
            case actions[2]:
                return isModal ? `${(trans.amount/trans.buyNairaRate.rate).toFixed(2)} USD` : `${trans.amount.toFixed(2)} NGN`;
            case actions[3]:
                return isModal ? `${(trans.amount/trans.buyNairaRate.rate).toFixed(2)} USD` : `${trans.amount.toFixed(2)} NGN`;
            case actions[4]:
                return `${(trans.amount / (trans.buyNairaRate.rate * trans.cryptoTradeInfo.sourceRate.rate)).toFixed(8)} ${trans.sourceCurrency}`;
            default:
                return null;
        }
    }

    /* let rtnAmountinUSD = (trans) => {
        
        return `$${trans.amount / trans.nairaRate.rate}`;

    } */

    let setTransactionsState = (index, state) => {
        setTransactions(prevState => {
            var temp = [...prevState];
            temp[index].status = state;
            return temp;
        })
    }

    let rtnDate = (date) => {

        let newDate = new Date(date);
        var strDate = newDate.toLocaleDateString('en-US', {day:"numeric", year:"numeric", month:"short"});
        return strDate;

    }

    let rtnPymntMethod = (trans) => {
        switch(trans.transactionType){
            case actions[0]:
                return PYMNTMETHOD.filter(x => x.Code === trans.paymentMethod)[0].Name;
            case actions[1]:
                return trans.paymentMethod === PYMNTMETHOD[1].Code ? "Wallet Balance" : `${trans.bankAccount.bankName} ${trans.bankAccount.accountNumber}`;
            case actions[2]:
                return "Bank Transfer";
            case actions[3]:
                return `${trans.bankAccount.bankName} ${trans.bankAccount.accountNumber}`;
            case actions[4]:
                return "Swapping";
            default:
                return null;
        }
    }

    useEffect(() => {

        if(filterType !== "All")
            setFilter(transactions.filter((transaction) => transaction.transactionType === filterType))
        else
            setFilter(transactions);

    },[filterType, transactions])

    return(
        <>
        {showModal && <TransDetails
            setModal={setModal}
            isAdmin={isAdmin}
            transactionDetails={details}
            setTransactions={setTransactionsState}
        /> }
        <div className={styles.Transactions}>
            <div>
                <label className={styles.OrderNo}>Order No</label>
                {isAdmin && <label className={styles.Customer}>Customer</label>}
                <label className={styles.Transaction}>Transaction</label>
                <label className={styles.Amount}>Amount</label>
                <label className={styles.Status}>Status</label>
                <label className={styles.Date}>Date</label>
            </div>
            <div className={styles.TransactionsList} onClick={() => setModal(true)}>
                {(filteredTransaction !== null && filteredTransaction?.length > 0)  && 
                filteredTransaction.map((transaction, index) => 
                (
                    <div key={index} onClick={() => setDetails(prevstate => {
                        return {
                            ...prevstate,
                            ...transaction, 
                            date : rtnDate(transaction.dateCreated),
                            orderNo : transaction.id.substring(0,6),
                            details : rtnActionDetails(transaction),
                            amount : transaction.amount,
                            amountInCrncy : rtnAmount(transaction, true),
                            rate : transaction.transactionType === "Sell" || transaction.transactionType === "Swap" ? transaction.sellNairaRate.rate : transaction.buyNairaRate.rate,
                            status :  transaction.status,
                            paymentMethod : rtnPymntMethod(transaction),
                            type : transaction.transactionType,
                            id : transaction.id,
                            index : index
                            }
                        })}>
                        <div className={styles.OrderNo}>
                            <span>#{transaction.id.substring(0,6)}</span>
                        </div>
                        {isAdmin &&<div className={styles.Customer}>
                            <div>
                                <img src={User}  alt="Default Profile pic"/>
                            </div>
                            <div>
                                <span>{transaction.customer.firstName + " " + transaction.customer.lastName}</span>
                                <span>{transaction.customer.email}</span>
                            </div>
                        </div>}
                        <div className={styles.Transaction}>
                            <img src={rtnImg(transaction.transactionType)} alt="Action" className={styles.ImgSize} />
                            <div>
                                <span>{rtnActionDetails(transaction)}</span>
                                <span>{rtnRate(transaction)}</span>
                            </div>
                        </div>
                        <div className={styles.Amount}>
                            <span>{rtnAmount(transaction)}</span>
                            {/* <span>{rtnAmountinUSD(transaction)}</span> */}
                        </div>
                        <div className={styles.Status}>
                            <span className={rtnClass(transaction.status)}>{transaction.status}</span>
                        </div>
                        <div className={styles.Date}>
                            <span>{rtnDate(transaction.dateCreated)}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        </>
    );

}

export default Transactions;