import SettingsHeader from "../../../../../Components/SettingsHeader/Index";
import styles from "./css/index.module.css";
import Gift from "./../../../../../img/Refer.png";
import Copy from "./../../../../../img/Copy.png";
import People from "./../../../../../img/People.png";
import Coins from "./../../../../../img/Coins.png";

const ReferAndEarn = () => {

    return(
        <div className={styles.ReferAndEarn}>
            <SettingsHeader
                heading={"Refer and Earn"}
                backText={"Settings"}
                backLink={"/Dashboard/Settings"}
            />
            <div className={styles.ReferAndEarnBody}>
                <div>
                    <div>
                        <div>
                            <h3>How it Works</h3>
                            <p>Invite a friend to sign up and trade, you will earn 50% of all the commissions we collect from them within their first 14 days.</p>
                        </div>
                        <div>
                            <span>https://app.coinnest.africa/signup?ref=michael_reyes</span>
                            <img src={Copy}  alt="Copy"/>
                        </div>
                    </div>
                    <img src={Gift}  alt="Refer Gift"/>
                </div>
                <div>
                    <div>
                        <img src={People} alt="People icon"/>
                        <div>
                            <label>Referrals</label>
                            <h4>12</h4>
                        </div>
                    </div>
                    <div>
                        <img src={Coins} alt="Coin icon"/>
                        <div>
                            <label>Total Earnings</label>
                            <h4>$562.00</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default ReferAndEarn;