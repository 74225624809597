import styles from "./css/index.module.css";
import Calendar from "./../../../img/Calendar.png";
import Transactions from "../../../Components/Transactions/Index";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../store/AuthContext";
import { BASE_URL, GET_TRANSACTION_LIST } from "../../../Utils/Endpoint";



const CAccount = () => {

    const actions = ["Buy", "Sell", "Deposit", "Withdraw", "Swap", "All"];
    const [transactionFilter, setFilter] = useState(actions[5])
    const [transactions, setTransactions] = useState(null);
    const [startDate, setStartDate] = useState('---');
    const [endDate, setEndDate] = useState('---');
    const AuthCtx = useContext(AuthContext);


    useEffect(() => {
        let getTransactionsAction = async () => {
        
            var response = await fetch(BASE_URL + GET_TRANSACTION_LIST,
                {
                    method : "GET",
                    headers : {
                        'content-type': 'application/json',
                        "Authorization" : "Bearer " + AuthCtx.token,
                        'Accept': 'application/json'
                    }
                })
    
                if(response.ok){
                    let json = await response.json();
                    setTransactions(json.data);
                }
        }

        getTransactionsAction()
        
    },[AuthCtx])

    useEffect(() => {
        if(transactions !== null && transactions?.length !== 0){
            setStartDate(rtnDate(transactions[0]?.dateCreated))
            setEndDate(rtnDate(transactions[transactions.length - 1]?.dateCreated))
        }
    },[transactions])

    let rtnDate = (date) => {

        let newDate = new Date(date);
        var strDate = newDate.toLocaleDateString('en-US', {day:"numeric", year:"numeric", month:"short"});
        return strDate;

    }

    return(
        <div className={styles.CAccount}>
            <div>
                <h3>Account</h3>
                <p>View your trades and transactions.</p>
            </div>
            <div>
                <ul>
                    <li className={transactionFilter === actions[5] ? styles.IsActiveTab : ""}>
                        <button onClick={() => setFilter(actions[5])}>
                            All
                        </button>
                    </li>
                    <li className={transactionFilter === actions[0] ? styles.IsActiveTab : ""}>
                        <button onClick={() => setFilter(actions[0])}>
                            Buy
                        </button>
                    </li>
                    <li className={transactionFilter === actions[1] ? styles.IsActiveTab : ""}>
                        <button onClick={() => setFilter(actions[1])}>
                            Sell
                        </button>
                    </li>
                    <li className={transactionFilter === actions[4] ? styles.IsActiveTab : ""}>
                        <button onClick={() => setFilter(actions[4])}>
                            Swap
                        </button>
                    </li>
                    <li className={transactionFilter === actions[2] ? styles.IsActiveTab : ""}>
                        <button onClick={() => setFilter(actions[2])}>
                            Deposit
                        </button>
                    </li>
                    <li className={transactionFilter === actions[3] ? styles.IsActiveTab : ""}>
                        <button onClick={() => setFilter(actions[3])}>
                            Withdraw
                        </button>
                    </li>
                </ul>
                <button>
                    <img src={Calendar}  alt="Calendar"/>
                    <p>{endDate} - {startDate}</p>
                </button>
            </div>
            <Transactions transactions={transactions} filterType={transactionFilter}/>
        </div>
    );

}

export default CAccount;