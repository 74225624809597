import Title from "../../Components/Title/Index";
import UnAuthContainer from "../../Components/UnAuthContainer/Index";
import QAs from "./Components/QAs";
import styles from './css/index.module.css';
import { Link } from "react-router-dom";
import useGaTracker from "./../../Utils/useGATracker";

const FAQs = () => {

    useGaTracker();

    return(
        <UnAuthContainer>
            <div className={styles.FirstSection}>
                <Title
                    label={"FAQs"}
                    heading={"Frequently Asked Questions"}
                    text={"Need something cleared up? Here are our most frequently asked questions."}
                    isFAQs={true}
                />
                <ul>
                    <li className={styles.IsActive}>
                        <span>
                        General
                        </span>
                    </li>
                    <li>
                        <span>
                        Deposits & Withdrawals
                        </span>
                    </li>
                    <li>
                        <span>
                        Trading
                        </span>
                    </li>
                    <li>
                        <span>
                        Security
                        </span>
                    </li>
                </ul>
            </div>
            <div className={styles.SecondSection}>
                <QAs 
                header={"What is CoinNest?"}
                >
                    <p>CoinNest Africa is a digital exchange where you can buy, sell and swap Bitcoin, Altcoins and Perfect Money at your convenience.</p>
                </QAs>
                <QAs 
                header={"How does it work?"}
                >
                    <p>You simply click on the preferred transaction you want to perform and follow the simple steps and prompt. Seamless.</p>
                </QAs>
                <QAs 
                header={"Why should I use CoinNest?"}
                >
                    <p>With CoinNest, you can:<br/>- Fund your account within the app<br/>- Trade without hidden fees <br/>- Get credited instantly when you deposit or trade <br/>- Trade with advanced tools<br/></p>
                </QAs>
                <QAs 
                header={"What do I need to own a CoinNest account?"}
                >
                    <p>Your email address and phone number are required to sign up on the platform, enjoying up to date offers available to you.</p>
                </QAs>
                <QAs 
                header={"What is the minimum amount I can start with?"}
                >
                    <p>Coinnest allows you own cryptocurrency of as low as N5,000 in Naira value</p>
                </QAs>
                <QAs 
                header={"Does CoinNest work outside Nigeria?"}
                >
                    <p>Coinnest allows for seamless trading anywhere with internet access.</p>
                </QAs>
                <QAs 
                header={"Are there limits to transactions I can make on CoinNest?"}
                >
                    <p>Coinnest allows for a no-limit trading experience.</p>
                </QAs>
            </div>
            <div className={styles.ThirdSection}>
            <h2>Still have questions?</h2>
            <p>Can’t find the answer you’re looking for? Send us a message</p>
            <Link to={'/Contact'}>
                <span>Contact Us</span>
            </Link>
            </div>
        </UnAuthContainer>
    );

}

export default FAQs;