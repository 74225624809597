import ModalContainer from "../ModalContainer/Index";
import styles from "./css/index.module.css";
import DropDownArrow from "./../../../../img/DropdownArrow.png";
import Bank from "./../../../../img/BankSmall.png";
import Wallet from "./../../../../img/Wallet.png";
import { useContext, useEffect, useState } from "react";
import { GetBankAccounts } from "../../../../Utils/GetData";
import AuthContext from "../../../../store/AuthContext";
import { PYMNTMETHOD } from "../../../../Utils/StaticData";


const Sell = ({setSellModal, amount, nairaRate, asset, sellRec, setSellRec, setSellConfirm, address}) => {
    const [showRec, setShowRec] = useState(false);
    const [userWallet, setUserWallet] = useState(null);
    const AuthCtx = useContext(AuthContext);

    useEffect(() => {

        let getUserWallet = async () => {
            let response = await GetBankAccounts(AuthCtx.token);
            setUserWallet(response);
        }

        getUserWallet();

    },[AuthCtx])

    return(
        <ModalContainer showModal={setSellModal}>
            <div className={styles.Sell}>
                <h3>Sell Order</h3>
                <div>
                    <div className={styles.InputGroup}>
                        <label>Send your crypto to Address below</label>
                        <div className={styles.InputDropDown}>
                            <div className={styles.SelectedInput}>
                            <img src={asset.img} alt="Bank Transfer Icon" className={styles.coinSize}/>
                            <label>{asset.name} Address</label>
                            </div>
                        </div>
                    </div>
                    <div className={styles.InputGroup}>
                        <label>Wallet Address</label>
                        <div>
                            <span className={styles.CryptoAddress}>{address}</span>
                        </div>
                    </div>
                    <div className={styles.InputGroup}>
                        <label>Receive at</label>
                        <div className={styles.InputDropDownCtr} onClick={() => setShowRec(prev => !prev)}>
                            <div className={styles.InputDropDown}>
                                <div className={styles.SelectedInput}>
                                    {sellRec === null ?
                                    <label>Select where to receive it</label>
                                    :
                                    sellRec.type === PYMNTMETHOD[1].Code ?
                                    <>
                                        <img src={Wallet} alt="Wallet Icon"/>
                                        <label>Wallet</label>
                                    </>
                                    :
                                    <>
                                        <img src={Bank} alt="Bank Transfer Icon"/>
                                        <label>{sellRec.bankName} - {sellRec.accountNumber}</label>
                                    </>}
                                </div>
                                <img src={DropDownArrow} alt="Dropdown Arrow Icon"/>
                            </div>
                            {showRec &&
                            <div>
                                <div onClick={() => setSellRec({type : PYMNTMETHOD[1].Code})}>
                                    <img src={Wallet} alt="Wallet Icon"/>
                                    <label>Wallet</label>
                                </div>
                                {userWallet === null ? 
                                    <div>
                                        <label>Go to Settings to add a Bank Account</label>
                                    </div>
                                    :
                                    <>
                                    {userWallet.map((wallet, index) =>(
                                        <div key={index} onClick={() => setSellRec({...wallet, type : PYMNTMETHOD[0].Code})}>
                                            <img src={Bank} alt="Bank Transfer Icon"/>
                                            <label>{wallet.bankName} - {wallet.accountNumber}</label>
                                        </div>
                                    ))}
                                    </>
                                }
                            </div>}
                        </div>
                    </div>
                    <div className={styles.Summary}>
                        <div>
                            <span>You will receive</span>
                            <span>{parseFloat(amount).toFixed(2)} USD</span>
                        </div>
                        <div>
                            <span>Exchange rate</span>
                            <span>NGN {parseFloat(nairaRate.rate).toFixed(2)} / USD</span>
                        </div>
                    </div>
                </div>
                <div>
                    <button onClick={() => setSellModal(false)}>
                        Cancel
                    </button>
                    <button onClick={() => setSellConfirm()}>
                        Confirm
                    </button>
                </div>
            </div>
        </ModalContainer>
    )
}

export default Sell;