// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_Header__-kFlV{display: flex;flex-direction: column;align-items: center;}
.css_Header__-kFlV > label{color: var(--accent, #F60);text-align: center;font-size: 16px;font-style: normal;font-weight: 500;line-height: 24px;}
.css_Header__-kFlV > h2{color: var(--gray-900, #101828);text-align: center;font-size: 36px;font-style: normal;font-weight: 500;line-height: 44px;letter-spacing: -0.72px;margin: 12px 0px 20px 0px;}
.css_Header__-kFlV > p{color: var(--gray-500, #667085);text-align: center;font-size: 20px;font-style: normal;font-weight: 450;line-height: 30px;margin:0px;width: 768px;}
.css_Header__-kFlV > h2.css_IsBigger__uyYq2{font-size: 48px;margin-bottom: 24px;font-weight: 700;line-height: 60px;letter-spacing: -0.96px;}
.css_Header__-kFlV > h2.css_IsFAQs__BHFw0{margin-top: 32px;}

@media screen and (max-width : 800px) {

    .css_Header__-kFlV > p{width: 100%;}
    
}`, "",{"version":3,"sources":["webpack://./src/Components/Title/css/index.module.css"],"names":[],"mappings":"AAAA,mBAAQ,aAAa,CAAC,sBAAsB,CAAC,mBAAmB,CAAC;AACjE,2BAAgB,0BAA0B,CAAC,kBAAkB,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC;AACpI,wBAAa,+BAA+B,CAAC,kBAAkB,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,uBAAuB,CAAC,yBAAyB,CAAC;AACxL,uBAAY,+BAA+B,CAAC,kBAAkB,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,UAAU,CAAC,YAAY,CAAC;AAC7J,4CAAsB,eAAe,CAAC,mBAAmB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,uBAAuB,CAAC;AACrH,0CAAoB,gBAAgB,CAAC;;AAErC;;IAEI,uBAAY,WAAW,CAAC;;AAE5B","sourcesContent":[".Header{display: flex;flex-direction: column;align-items: center;}\n.Header > label{color: var(--accent, #F60);text-align: center;font-size: 16px;font-style: normal;font-weight: 500;line-height: 24px;}\n.Header > h2{color: var(--gray-900, #101828);text-align: center;font-size: 36px;font-style: normal;font-weight: 500;line-height: 44px;letter-spacing: -0.72px;margin: 12px 0px 20px 0px;}\n.Header > p{color: var(--gray-500, #667085);text-align: center;font-size: 20px;font-style: normal;font-weight: 450;line-height: 30px;margin:0px;width: 768px;}\n.Header > h2.IsBigger{font-size: 48px;margin-bottom: 24px;font-weight: 700;line-height: 60px;letter-spacing: -0.96px;}\n.Header > h2.IsFAQs{margin-top: 32px;}\n\n@media screen and (max-width : 800px) {\n\n    .Header > p{width: 100%;}\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header": `css_Header__-kFlV`,
	"IsBigger": `css_IsBigger__uyYq2`,
	"IsFAQs": `css_IsFAQs__BHFw0`
};
export default ___CSS_LOADER_EXPORT___;
