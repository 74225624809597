import { useContext, useState } from "react";
import Input from "../../Components/Input/Index";
import InputCtnr from "../../Components/InputCtnr/Index";
import OnBoardingCtnr from "../../Components/OnBoardingCtnr/Index";
import Button from "../../Components/Button/Index";
import GreenCheck from "./../../img/GreenCheck.png";
import RedCross from "./../../img/RedCross.png";
import styles from "./css/index.module.css";
import { useSearchParams } from "react-router-dom";
import { BASE_URL, CONFIRM_PWD_RESET } from "../../Utils/Endpoint";
import AuthContext from "../../store/AuthContext";
import useGaTracker from "./../../Utils/useGATracker";

const ResetPassword = () => {

    useGaTracker();

    const [password, setPassword] = useState('');
    const [isSent ,setIsSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();

    const AuthCtx = useContext(AuthContext);

    let isCapitalLetters = (/[A-Z]+/.test(password))?true:false;
    let isNumber = (/[0-9]+/.test(password))?true:false;
    let isSpecial = (/[!”$%&/#()=?@~`\\.’;:+=^*_-]+/.test(password))?true:false;
    let isLong = (password?.length > 7)?true:false;


    let setPasswordHandler = (value) => {
        setPassword(value);
    }

    let submitPwdReset = async () => {
        if(isSpecial && isNumber && isCapitalLetters && isLong){
            var obj = {
                "id": searchParams.get('userId'),
                "token": searchParams.get('token'),
                "password": password
              };
            console.log(obj);
            var response = await fetch(BASE_URL + CONFIRM_PWD_RESET,
                {
                    method : "POST",
                    body : JSON.stringify(obj),
                    headers : {
                        'content-type': 'application/json',
                        "Authorization" : "Bearer " + AuthCtx.token
                    }
            })

            let json = await response.json();
            console.log(json);
    
            if(response.ok){
                setIsSent(true);
                setSearchParams({ 'status': 'success' });
                return null;
            }  
            
            setErrorMessage(json.statusText);
            return null;
        }
    }

    return(
        <OnBoardingCtnr>
            <div className={styles.ResetPassword}>
                <h3>Choose a new password</h3>
                {isSent ?
                <h3>Password Successfully Reset</h3>
                :
                <>
                <InputCtnr label={"Password"} smallMargin={true}>
                    <Input
                        type={"password"}
                        isPassword={true}
                        setChange={setPasswordHandler}
                        value={password}
                        placeholder={"New Password Here"}
                    />
                </InputCtnr>
                <span className={styles.ErrorMessage}>{errorMessage}</span>
                <ul>
                    <li>
                        <img src={isLong ? GreenCheck : RedCross} alt={"bool value"}/>
                        <span className={isLong ? styles.Valid : styles.InValid}>At least 8 characters</span>
                    </li>
                    <li>
                        <img src={isCapitalLetters ? GreenCheck : RedCross} alt={"bool value"}/>
                        <span className={isCapitalLetters ? styles.Valid : styles.InValid}>At least one uppercase character</span>
                    </li>
                    <li>
                        <img src={isNumber ? GreenCheck : RedCross} alt={"bool value"}/>
                        <span className={isNumber ? styles.Valid : styles.InValid}>At least one number</span>
                    </li>
                    <li>
                        <img src={isSpecial ? GreenCheck : RedCross} alt={"bool value"}/>
                        <span className={isSpecial ? styles.Valid : styles.InValid}>At least one special character (!@#&$)</span>
                    </li>
                </ul>
                <Button
                    text={"Reset Password"}
                    isEnabled={isSpecial && isNumber && isCapitalLetters && isLong}
                    action={submitPwdReset}
                />
                </>}
            </div>
        </OnBoardingCtnr>
    )

}

export default ResetPassword;