import styles from "./css/index.module.css";
import PwdEye from "./../../img/PwdEye.png";
import { useState } from "react";

const Input = ({type, value, setChange, isPassword = false, placeholder}) => {
    const [passwordType, setPasswordType] = useState("password");

    let togglePasswordVision = () => {
        setPasswordType( prevState => prevState === "password" ? "text" : "password");
    }


    return(
        <div className={styles.InputWrapper}>
            <input type={isPassword ? passwordType :  type} value={value} onChange={e => setChange(e.target.value)} placeholder={placeholder}/>
            {isPassword ? <img src={PwdEye} alt="Password Eye" onClick={() => togglePasswordVision()}/> : null}
        </div>
    );

}

export default Input;