// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_FirstSection__wv\\+cj{padding:96px 0px;}
.css_SecondSection__JO0ZE{color: var(--gray-500, #667085);font-size: 18px;font-style: normal;font-weight: 450;line-height: 28px;text-align: left;display: flex;flex-direction: column;align-items: center;padding-bottom: 96px;}
.css_SecondSection__JO0ZE > div{width:75%}
.css_SecondSection__JO0ZE  p{margin: 0px;margin-bottom: 12px;}
.css_SecondSection__JO0ZE h3{margin: 12px 0px 24px 0px;color: var(--gray-900, #101828);font-size: 30px;font-style: normal;font-weight: 500;line-height: 38px; /* 126.667% */}
.css_SecondSection__JO0ZE li{margin-bottom: 8px;}
.css_SecondSection__JO0ZE ol > li > ul > li{list-style-type: disc;}
@media screen and (max-width : 800px) { 
    .css_FirstSection__wv\\+cj{padding: 48px 32px;}
}`, "",{"version":3,"sources":["webpack://./src/Pages/TermsOfUse/css/index.module.css"],"names":[],"mappings":"AAAA,0BAAc,gBAAgB,CAAC;AAC/B,0BAAe,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,gBAAgB,CAAC,aAAa,CAAC,sBAAsB,CAAC,mBAAmB,CAAC,oBAAoB,CAAC;AACpN,gCAAqB,SAAS;AAC9B,6BAAkB,WAAW,CAAC,mBAAmB,CAAC;AAClD,6BAAkB,yBAAyB,CAAC,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,EAAE,aAAa,CAAC;AACjK,6BAAkB,kBAAkB,CAAC;AACrC,4CAAiC,qBAAqB,CAAC;AACvD;IACI,0BAAc,kBAAkB,CAAC;AACrC","sourcesContent":[".FirstSection{padding:96px 0px;}\n.SecondSection{color: var(--gray-500, #667085);font-size: 18px;font-style: normal;font-weight: 450;line-height: 28px;text-align: left;display: flex;flex-direction: column;align-items: center;padding-bottom: 96px;}\n.SecondSection > div{width:75%}\n.SecondSection  p{margin: 0px;margin-bottom: 12px;}\n.SecondSection h3{margin: 12px 0px 24px 0px;color: var(--gray-900, #101828);font-size: 30px;font-style: normal;font-weight: 500;line-height: 38px; /* 126.667% */}\n.SecondSection li{margin-bottom: 8px;}\n.SecondSection ol > li > ul > li{list-style-type: disc;}\n@media screen and (max-width : 800px) { \n    .FirstSection{padding: 48px 32px;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FirstSection": `css_FirstSection__wv+cj`,
	"SecondSection": `css_SecondSection__JO0ZE`
};
export default ___CSS_LOADER_EXPORT___;
