// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_ResetPassword__X4UG-{text-align: left;}
.css_ResetPassword__X4UG- > h3{color: var(--gray-900, #101828);font-size: 24px;font-style: normal;font-weight: 500;line-height: 32px;margin: 0px;margin-bottom: 32px;}

.css_ResetPassword__X4UG- > ul{padding: 0px; margin: 0px;text-align: left;list-style-type: none;margin-bottom: 20px;}
.css_ResetPassword__X4UG- > ul > li{display: flex;align-items: center;}
.css_ResetPassword__X4UG- > ul > li > span{font-size: 14px;font-style: normal;font-weight: 450;line-height: 20px;}
.css_Valid__4Btad{color: var(--success-500, #12B76A);}
.css_InValid__Yoivj{color: var(--error-500, #F04438);}
.css_ErrorMessage__3GA1n{color: red; font-size: 14px;margin-top: 12px;display: block;}`, "",{"version":3,"sources":["webpack://./src/Pages/ResetPassword/css/index.module.css"],"names":[],"mappings":"AAAA,0BAAe,gBAAgB,CAAC;AAChC,+BAAoB,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,WAAW,CAAC,mBAAmB,CAAC;;AAE1J,+BAAoB,YAAY,EAAE,WAAW,CAAC,gBAAgB,CAAC,qBAAqB,CAAC,mBAAmB,CAAC;AACzG,oCAAyB,aAAa,CAAC,mBAAmB,CAAC;AAC3D,2CAAgC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC;AACtG,kBAAO,kCAAkC,CAAC;AAC1C,oBAAS,gCAAgC,CAAC;AAC1C,yBAAc,UAAU,EAAE,eAAe,CAAC,gBAAgB,CAAC,cAAc,CAAC","sourcesContent":[".ResetPassword{text-align: left;}\n.ResetPassword > h3{color: var(--gray-900, #101828);font-size: 24px;font-style: normal;font-weight: 500;line-height: 32px;margin: 0px;margin-bottom: 32px;}\n\n.ResetPassword > ul{padding: 0px; margin: 0px;text-align: left;list-style-type: none;margin-bottom: 20px;}\n.ResetPassword > ul > li{display: flex;align-items: center;}\n.ResetPassword > ul > li > span{font-size: 14px;font-style: normal;font-weight: 450;line-height: 20px;}\n.Valid{color: var(--success-500, #12B76A);}\n.InValid{color: var(--error-500, #F04438);}\n.ErrorMessage{color: red; font-size: 14px;margin-top: 12px;display: block;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ResetPassword": `css_ResetPassword__X4UG-`,
	"Valid": `css_Valid__4Btad`,
	"InValid": `css_InValid__Yoivj`,
	"ErrorMessage": `css_ErrorMessage__3GA1n`
};
export default ___CSS_LOADER_EXPORT___;
