import styles from "./css/index.module.css";

const InputCtnr = ({children, label, smallMargin = false}) => {
    return(
    <div className={`${styles.InputCtnr} ${smallMargin ? styles.smallMargin : styles.bigMargin}`}>
        <label>{label}</label>
        {children}
    </div>
    )
}

export default InputCtnr;