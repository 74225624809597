import SettingsHeader from "../../../../../Components/SettingsHeader/Index";
import styles from "./css/index.module.css";
import NotifyOn from "./../../../../../img/NotifyOn.png";
import NotifyOff from "./../../../../../img/NotifyOff.png";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../store/AuthContext";
import { BASE_URL, GET_NOTIFICATIONS, TOGGLE_DEPOSITS, TOGGLE_GUPDATES, TOGGLE_TRADES, TOGGLE_WITHDRAWALS } from "../../../../../Utils/Endpoint";

const ANotifications = () => {

    const [notes, setNotes] = useState(null);
    const AuthCtx = useContext(AuthContext);
    console.log(notes);

    useEffect(() => {
        let getNotes = async () => {
            var response = await fetch(BASE_URL + GET_NOTIFICATIONS,
                {
                    method : "GET",
                    headers : {
                        'content-type': 'application/json',
                        "Authorization" : "Bearer " + AuthCtx.token,
                        'Accept': 'application/json'
                    }
                })
    
                let json = await response.json();
                console.log(json);
        
                if(response.ok){
                    setNotes(json.data);
                    return null;
                }   
        }

        getNotes();

    },[AuthCtx]);

    let ToggleNotif = async (type) => {

            const types = ["trades", "deposits", "withdrawals", "generalUpdates"]
            let ENDPOINT = "";

            if (types[0] === type)
                ENDPOINT = TOGGLE_TRADES;

            if (types[1] === type)
                ENDPOINT = TOGGLE_DEPOSITS;

            if (types[2] === type)
                ENDPOINT = TOGGLE_WITHDRAWALS;

            if (types[3] === type)
                ENDPOINT = TOGGLE_GUPDATES;

            var response = await fetch(BASE_URL + ENDPOINT,
            {
                method : "POST",
                headers : {
                    'content-type': 'application/json',
                    "Authorization" : "Bearer " + AuthCtx.token,
                    'Accept': 'application/json'
                }
            })

            let json = await response.json();
            console.log(json);
    
            if(response.ok){
                setNotes(prev => {return {...prev, [type] : !prev[type]}});
                return null;
            }   
    }

    return(
        <div className={styles.Notifications}>
            <SettingsHeader
                heading={"Notifications"}
                backText={"Settings"}
                backLink={"/Admin/Settings"}
            />
            {notes !== null &&
            <div className={styles.NotificationsBody}>
                <div>
                    <div>
                        <h4>Trades</h4>
                        <span>Get email alerts for all trades</span>
                    </div>
                    <img src={notes?.trades ? NotifyOn : NotifyOff}  alt="Notification Toggle"  onClick={() => ToggleNotif("trades")}/>
                </div>
                <div>
                    <div>
                        <h4>Deposits</h4>
                        <span>Get email alerts for all deposits</span>
                    </div>
                    <img src={notes?.deposits ? NotifyOn : NotifyOff}  alt="Notification Toggle"  onClick={() => ToggleNotif("deposits")}/>
                </div>
                <div>
                    <div>
                        <h4>Withdrawals</h4>
                        <span>Get email alerts for withdrawals</span>
                    </div>
                    <img src={notes?.withdrawals ? NotifyOn : NotifyOff}  alt="Notification Toggle"  onClick={() => ToggleNotif("withdrawals")}/>
                </div>
                <div>
                    <div>
                        <h4>General Updates</h4>
                        <span>Get email alerts for account actions</span>
                    </div>
                    <img src={notes?.generalUpdates ? NotifyOn : NotifyOff}  alt="Notification Toggle"  onClick={() => ToggleNotif("generalUpdates")}/>
                </div>
            </div>}
        </div>
    )
}

export default ANotifications;