import { useContext } from "react";
import { BASE_URL, UPDATE_TRANSACTION } from "../../Utils/Endpoint";
import ModalContainer from "../ModalContainer/Index";
import styles from "./css/index.module.css"
import AuthContext from "../../store/AuthContext";

const TransDetails = ({setModal, isAdmin, transactionDetails, setTransactions}) => {
    const stati = ["Approved", "Declined", "Pending"];
    const AuthCtx = useContext(AuthContext);
    //console.log(transactionDetails);

    let UpdateAction = async (status1) => {
        var obj = {
            "transactionId": transactionDetails.id,
            "transactionStatus": status1
          };
        console.log(obj);
    var response = await fetch(BASE_URL + UPDATE_TRANSACTION,
        {
            method : "PUT",
            body : JSON.stringify(obj),
            headers : {
                'content-type': 'application/json',
                "Authorization" : "Bearer " + AuthCtx.token
            }
    })

        if(response.ok){

            setTransactions(transactionDetails.index, status1);
            setModal(false);
        }
    }

    let rtnClass = (action) => {

        switch(action){
            case stati[0]:
                return styles.Approved;
            case stati[1]:
                return styles.Declined;
            case stati[2]:
                return styles.Pending;
            default:
                return null;
        }
    }

    return(
        <ModalContainer showModal={setModal}>
            <div className={styles.TransDetails}>
                <div>
                    <h4>Transaction Details</h4>
                    <span className={rtnClass(transactionDetails.status)}>{transactionDetails.status}</span>
                </div>
                <div className={styles.TransBody}>
                    <div>
                        <label>Date</label>
                        <span>{transactionDetails.date}</span>
                    </div>
                    <div>
                        <label>Order No</label>
                        <span>#{transactionDetails.orderNo}</span>
                    </div>
                </div>
                <div className={`${styles.TransBody} ${styles.LoneBody}`}>
                        <label>Transaction</label>
                        <span>{transactionDetails.details}</span>
                </div>
                <div className={`${styles.TransBody} ${styles.LoneBody}`}>
                        <label>Payment Method</label>
                        <span>{transactionDetails.paymentMethod}</span>
                </div>
                {transactionDetails?.cryptoTradeInfo?.sourceRate?.currencyShort === "USDT" &&
                <div className={`${styles.TransBody} ${styles.LoneBody}`}>
                        <label>Source Network Type</label>
                        <span>{transactionDetails?.cryptoTradeInfo?.sourceNetworkType}</span>
                </div>
                }
                {(transactionDetails.transactionType === "Buy" || transactionDetails.transactionType === "Swap") &&
                <div className={`${styles.TransBody} ${styles.LoneBody}`}>
                        <label>Destination Wallet Address</label>
                        <span>{transactionDetails?.cryptoTradeInfo?.destinationAddress}</span>
                </div>
                }
                {transactionDetails?.cryptoTradeInfo?.destinationRate?.currencyShort === "USDT" &&
                <div className={`${styles.TransBody} ${styles.LoneBody}`}>
                        <label>Destination Network Type</label>
                        <span>{transactionDetails?.cryptoTradeInfo?.destinationNetworkType}</span>
                </div>
                }
                <div className={styles.Summary}>
                    <div>
                        <span>Total</span>
                        <span>{transactionDetails.amountInCrncy}</span>
                    </div>
                    <div>
                        <span>Total in Naira</span>
                        <span>{transactionDetails.amount.toFixed(2)} NGN</span>
                    </div>
                    <div>
                        <span>Exchange Rate</span>
                        <span>{transactionDetails.rate}</span>
                    </div>
                </div>
                {isAdmin && transactionDetails.status === stati[2] ?
                <div className={styles.FooterButton}>
                    <button onClick={() => {UpdateAction(stati[1])}}>Decline</button>
                    <button onClick={() => {UpdateAction(stati[0])}}>Approve</button>
                </div>
                :
                <button className={styles.CloseButton} onClick={() => setModal(false)}>
                    Close
                </button>
                }
            </div>
        </ModalContainer>
    )
}

export default TransDetails;