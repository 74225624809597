import { useState, useCallback, useContext } from "react";
import ModalContainer from "../ModalContainer/Index";
import { useDropzone } from 'react-dropzone';
import { BASE_URL, UPLOAD_FILE } from "../../../../Utils/Endpoint";
import styles from "./css/index.module.css";
import Arrow from "./../../../../img/DropdownArrow.png";
import AuthContext from "../../../../store/AuthContext";

const UploadId = ({showModal}) => {
    const AuthCtx = useContext(AuthContext);

    const IdTypes = [
        {
            code : "DriversLicense",
            Name : "Driver's license"
        },
        {
            code : "Passport",
            Name : "International Passport"
        }
    ]
    const [type, setType] = useState(null);
    const [showTypes, setShow] = useState(false);
    const [preview, setPreview] = useState(null);
    const [textPreview, setTextPreview] = useState(null);
    const [response, setResponse] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const onDrop = useCallback((acceptedFiles) => {
        const file = new FileReader();
        setPreview(null);
        setTextPreview(null)
    
        file.onload = function() {
            console.log(acceptedFiles[0]);
            if(acceptedFiles[0].type.includes("image"))
                setPreview(file.result);
            else
                setTextPreview(acceptedFiles[0].name);
        }
    
        file.readAsDataURL(acceptedFiles[0])
      }, [])
    
      const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*, .pdf'
      });

      async function handleOnSubmit() {
        //e.preventDefault();
    
        if ( typeof acceptedFiles[0] === 'undefined'){
            setErrorMessage("Image is not set.");
            return
        };

        if(type === null){
            setErrorMessage("File type is not set.");
            return
        };
    
        const formData = new FormData();
    
        formData.append('FormFile', acceptedFiles[0]);
        formData.append('Type', type.code);
        //formData.append('api_key', import.meta.env.VITE_CLOUDINARY_API_KEY);
    
        const response = await fetch(BASE_URL + UPLOAD_FILE, {
          method: 'POST',
          body: formData,
          headers : {
            "Authorization" : "Bearer " + AuthCtx.token,
            'Accept': 'application/text'
        }
        });

        if(response.ok){
            setResponse("File Uploaded Successfully!");
            setErrorMessage('');
            return;
        }
    
        setResponse('');
        setErrorMessage("File not uploaded successfully.");
        return;
        //console.log('results', results);
      }

    return(
        <ModalContainer showModal={showModal}>
            <div className={styles.UploadId}>
                <h4>Government-issued ID Upload</h4>
                <p>Please use a document that is valid, as expired documents may not be approved</p>
                <div className={styles.InputCtnr}>
                    <label className={styles.Label}>Document type</label>
                    <div onClick={() => setShow(prev => !prev)} className={styles.DropdownCtnr}>
                        <div>
                            <span>{type === null ? "Select type of Identification" : type.Name}</span>
                            <img alt="Arrow" src={Arrow}/>
                        </div>
                        {showTypes && 
                        <div className={styles.Dropdown}>
                          {IdTypes.map((id, index) =>
                          (<span key={index} onClick={() => setType(id)}>{id.Name}</span>))}  
                        </div>}
                    </div>
                </div>
                <div className={styles.InputCtnr}>
                    <label className={styles.Label}>Upload document</label>
                  
                    <div {...getRootProps()} className={styles.Upload}>
                        <input {...getInputProps()} />
                        {preview === null && textPreview === null ?
                        <>
                        {
                            
                            isDragActive ?
                            <p>Drag 'n' drop or click to select file</p> :
                            <p>Drag 'n' drop or click to select file</p>
                        }</>
                        :
                        <div>
                            {textPreview === null ?
                            <img src={preview} alt="Upload preview" />
                            : 
                            <label>{textPreview}</label>
                            }
                        </div>}
                    </div>
                    
                </div>
                <span className={styles.ErrorMessage}>{errorMessage}</span>
                <span className={styles.SuccessMessage}>{response}</span>
                <div className={styles.Buttons}>
                    <button onClick={() => showModal(false)}>
                        Cancel
                    </button>
                    <button onClick={() => handleOnSubmit()}>
                        Confirm
                    </button>
                </div>
            </div>
        </ModalContainer>
    )

}

export default UploadId;