import Title from "../../Components/Title/Index";
import UnAuthContainer from "../../Components/UnAuthContainer/Index";
import styles from "./css/index.module.css";
import useGaTracker from "./../../Utils/useGATracker";

const PrivacyPolicy = () => {

    useGaTracker();

    return(
        <UnAuthContainer>
            <div>
                <div className={styles.FirstSection}>
                    <Title
                        label={"Current as of 20 Jan 2023"}
                        heading={"Privacy Policy"}
                        text={"Your privacy is important to us at CoinNest. We respect your privacy regarding any information we may collect from you across our website."}
                        bigTitle={true}
                    />   
                </div>
                <div className={styles.SecondSection}>
                    <div>
                        <h3>What do we collect and how do we use it?</h3>
                        <div>
                            <p>Your privacy is critical to us. Likewise, we have built up this Policy with the end goal you should see how we gather, utilize, impart and reveal and make utilization of individual data. The following blueprints our privacy policy.</p>
                            <ul>
                                <li>Before or at the time of collecting personal information, we will identify the purposes for which information is being collected.</li>
                                <li>We will gather and utilization of individual data singularly with the target of satisfying those reasons indicated by us and for other good purposes, unless we get the assent of the individual concerned or as required by law.</li>
                                <li>We will just hold individual data the length of essential for the satisfaction of those reasons.</li>
                                <li>We will gather individual data by legal and reasonable means and, where fitting, with the information or assent of the individual concerned.</li>
                                <li>Personal information ought to be important to the reasons for which it is to be utilized, and, to the degree essential for those reasons, ought to be exact, finished, and updated.</li>
                                <li>We will protect individual data by security shields against misfortune or burglary, and also unapproved access, divulgence, duplicating, use or alteration.</li>
                                <li>We will promptly provide customers with access to our policies and procedures for the administration of individual data.</li>
                            </ul>
                            <p>We are focused on leading our business as per these standards with a specific end goal to guarantee that the privacy of individual data is secure and maintained.</p>
                        </div>
                    </div>
                </div>
            </div>
        </UnAuthContainer>
    );

}

export default PrivacyPolicy;