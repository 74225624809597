import IconLogo from "./../../img/IconLogo.png";
import User from "./../../img/User.png";
import NotifyBell from "./../../img/NotifyBell.png";
import styles from "./css/index.module.css";
import logout from "./../../img/logout.png";
import { useContext } from "react";
import AuthContext from "../../store/AuthContext";
import { Link } from "react-router-dom";

const AuthHeader = ({setShowNav, isAdmin = false}) => {

    const AuthCtx = useContext(AuthContext);

    return(
        <div className={styles.AuthHeader}>
            <img src={IconLogo} alt={"Icon Logo"}/>
            <div>
                <img src={NotifyBell} alt={"Notify Bell"}/>
                <Link to={isAdmin ? "/Admin/Settings/General" : "/Dashboard/Settings/General"}>
                    <div>
                        <img src={User} alt={"User Icon"} />
                    </div>
                </Link>
                <button className={styles.menuToggle} onClick={() => setShowNav(prev => !prev)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <img src={logout} alt="logout" onClick={() => AuthCtx.logout()} className={styles.Logout}/>
            </div>
        </div>
    );

}

export default AuthHeader;