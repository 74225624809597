import { Link } from "react-router-dom";
import SettingsHeader from "../../../../../Components/SettingsHeader/Index";
import styles from "./css/index.module.css";
import Buy from "./../../../../../img/BuyBig.png";
import Sell from "./../../../../../img/SellBig.png";
import Swap from "./../../../../../img/SwapBig.png";
import Deposit from "./../../../../../img/DepositBig.png";
import Withdraw from "./../../../../../img/WithdrawBig.png";
import { ACTIONS } from "../../../../../Utils/StaticData";
import { useEffect, useState } from "react";
import { BASE_URL, GET_TIMERS } from "../../../../../Utils/Endpoint";

const Timers = () => {
    const [timers, setTimers] = useState(null)

    useEffect(() => {
        (async () => {
          const response = await fetch(BASE_URL + GET_TIMERS) 
          
          var result = await response.json()

          if(response.ok){
                setTimers(result.data)
          }
          
        })()
    },[])

    const formatTimer = (times) => {
        var hours, minutes, seconds;

        if(times.hours < 10){
            hours = `0${times.hours}`
        }
        else
        {
            hours = times.hours;
        }

        if(times.minutes < 10){
            minutes = `0${times.minutes}`
        }
        else
        {
            minutes = times.minutes;
        }

        if(times.seconds < 10){
            seconds = `0${times.seconds}`
        }
        else
        {
            seconds = times.seconds;
        }

        return `${hours}:${minutes}:${seconds}`
    }

    return(
        <>
    {timers !== null &&
    <div className={styles.Timers}>
        <SettingsHeader
         heading={"Countdown Timers"}
         backText={"Settings / Transactions "}
         backLink={"/Admin/Settings/Transactions"}
        />
        <div className={styles.TimersBody}>
            <Link to={ACTIONS[0].Short}>
                <div>
                    <img src={Buy} alt="Buy Sign" />
                    <span>{ACTIONS[0].Title}</span>
                </div>
                <span>{formatTimer(timers.filter(timer => timer.orderType === "Buy")[0])}</span>
            </Link>
            <Link to={ACTIONS[1].Short}>
                <div>
                    <img src={Sell} alt="Sell Sign" />
                    <span>{ACTIONS[1].Title}</span>
                </div>
                <span>{formatTimer(timers.filter(timer => timer.orderType === "Sell")[0])}</span>
            </Link>
            <Link to={ACTIONS[2].Short}>
                <div>
                    <img src={Swap} alt="Swap Sign" />
                    <span>{ACTIONS[2].Title}</span>
                </div>
                <span>{formatTimer(timers.filter(timer => timer.orderType === "Swap")[0])}</span>
            </Link>
            <Link to={ACTIONS[3].Short}>
                <div>
                    <img src={Deposit} alt="Deposit Sign" />
                    <span>{ACTIONS[3].Title}</span>
                </div>
                <span>{formatTimer(timers.filter(timer => timer.orderType === "Deposit")[0])}</span>
            </Link>
            <Link to={ACTIONS[4].Short}>
                <div>
                    <img src={Withdraw} alt="Withdraw Sign" />
                    <span>{ACTIONS[4].Title}</span>
                </div>
                <span>{formatTimer(timers.filter(timer => timer.orderType === "Withdraw")[0])}</span>
            </Link>
        </div>
    </div>}
    </>
    );

}

export default Timers;