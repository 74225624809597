import styles from "./css/index.module.css";
import InstateTrades from "./../../img/InstantTrades.png";
import SecureTransaction from "./../../img/SecureTransaction.png";
import TradeRemotely from "./../../img/TradeRemotely.png";
import Support from "./../../img/Support.png";

const Feature = ({source, label, description}) => {

    return(
        <div className={styles.Feature}>
            <img src={source} alt={label} />
            <div>
                <label>{label}</label>
                <p>{description}</p>
            </div>
        </div>
    );

}

const Features = () => {

    return(
        <div>
            <Feature source={InstateTrades} label={"Instant trades"} description={"Exchange assets within minutes, seamlessly"} />
            <Feature source={SecureTransaction} label={"Secure transactions"} description={"Ensuring safe and secure trades"} />
            <Feature source={TradeRemotely} label={"Trade remotely"} description={"Exchange from anywhere in the world"} />
            <Feature source={Support} label={"24/7 Support"} description={"Round-the-clock support available"} />
        </div>
    )

}

export default Features;