import styles from "./css/index.module.css";

const Action = ({source, label, description,addBorder}) => {

    return(
        <div className={`${styles.Action} ${addBorder ? styles.AddBorder : ""}`}>
            <img src={source} alt={label} />
            <label>{label}</label>
            <p>{description}</p>
        </div>
    );

}

export default Action;