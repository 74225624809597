import styles from "./css/index.module.css"
import PlusSign from "./../../../img/PlusSign.png";
import User from "./../../../img/User.png";
import Edit from "./../../../img/Edit.png";
import Delete from "./../../../img/Delete.png";
import AddUser from "./Components/AddUser";
import { useEffect, useState } from "react";
import { BASE_URL, DELETE_USER, GET_ADMINS, GET_MANAGERS, GET_SUPPORTS, REGISTER, UPDATE_USER } from "../../../Utils/Endpoint";
import EditUser from "./Components/EditUser";
import DeleteUser from "./Components/DeleteUser";

const Users = () => {
    const [showAddUser, setShowAddUser] = useState(false);
    const [showEditUser, setShowEditUser] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [reflectChanges, setReflectChanges] = useState(0);
    const [activeRole, setRole] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [id, setId] = useState('');
    const [error, setError] = useState('');
    const [admins, setAdmins] = useState(null);
    const [support, setSupport] = useState(null);
    const [managers, setManagers] = useState(null);



    let AddUserAction = async () => {

        var response = await fetch(BASE_URL + REGISTER,
            {
                method : "POST",
                body : JSON.stringify({
                    "firstName": firstName,
                    "lastName": lastName,
                    "userType": activeRole,
                    "email": email,
                    "password": password,
                }),
                headers : { 
                    'content-type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            if(response.ok){
                setShowAddUser(false);
                setReflectChanges(prev => ++prev);
                return null;
            } 

            let json = await response.json();

            setError(json.statusText);
            return null;
    }

    let EditUserAction = async () => {

        var response = await fetch(BASE_URL + UPDATE_USER + "?userId=" + id,
            {
                method : "PUT",
                body : JSON.stringify({
                    "firstName": firstName,
                    "lastName": lastName,
                    //"userType": activeRole,
                    "email": email,
                }),
                headers : { 
                    'content-type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            if(response.ok){
                setShowEditUser(false);
                setReflectChanges(prev => ++prev);
            } 

    }

    let DeleteUserAction = async () => {

        var response = await fetch(BASE_URL + DELETE_USER + "?userId=" + id,
            {
                method : "DELETE",
                headers : { 
                    'content-type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            if(response.ok){
                setShowDeleteModal(false);
                setReflectChanges(prev => ++prev);
            }

    }

    useEffect(() => {

        let GetAdmins = async () => {
            var response = await fetch(BASE_URL + GET_ADMINS,
                {
                    method : "GET",
                    headers : { 
                        'content-type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                if(response.ok){
                    let json = await response.json();
                    setAdmins(json.data);
                } 
        }

        let GetSupports = async () => {
            var response = await fetch(BASE_URL + GET_SUPPORTS,
                {
                    method : "GET",
                    headers : { 
                        'content-type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                if(response.ok){
                    let json = await response.json();
                    setSupport(json.data);
                } 
        }

        let GetManagers = async () => {
            var response = await fetch(BASE_URL + GET_MANAGERS,
                {
                    method : "GET",
                    headers : { 
                        'content-type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                if(response.ok){
                    let json = await response.json();
                    setManagers(json.data);
                } 
        }

        GetAdmins();
        GetSupports();
        GetManagers();

    },[reflectChanges])

    let rtnDate = (date) => {

        let newDate = new Date(date);
        var strDate = newDate.toLocaleDateString('en-US', {day:"numeric", year:"numeric", month:"short"});
        return strDate;

    }

    return(
        <>
            {showAddUser && <AddUser 
                activeRole={activeRole} 
                setRole={setRole} 
                showModal={setShowAddUser}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                AddUserAction={AddUserAction}
                error={error}/>}
                {showEditUser && <EditUser
                    showModal={setShowEditUser}
                    setRole={setRole} 
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    email={email}
                    setEmail={setEmail}
                    EditUserAction={EditUserAction}
                />}
                {showDeleteModal && <DeleteUser
                    showModal={setShowDeleteModal}
                    DeleteUserAction={DeleteUserAction}
                />}
            <div className={styles.Users}>
                <div>
                    <h3>Users</h3>
                    <button onClick={() => setShowAddUser(true)}>
                        <img alt="Plus Sign" src={PlusSign} />
                        <span>Add User</span>
                    </button>
                </div>
                <div className={styles.UsersBody}>
                    <div className={styles.UserGroup}>
                        <div className={styles.UserGroupDescription}>
                            <h4>Admin</h4>
                            <p>Admins can add, remove users and<br/>manage transactions.</p>
                        </div>
                        <div className={styles.UserGroupList}>
                            <div>
                                <label className={styles.Name}>User</label>
                                <label className={styles.Date}>Date Created</label>
                                <label className={styles.Active}>Last Active</label>
                                <label className={styles.Action}></label>
                            </div>
                            <div>
                                {admins !== null && admins.map((admin, index) => (
                                <div key={index}>
                                    <div className={styles.Name}>
                                        <div>
                                            <img src={User} alt="User Icon"/>
                                        </div>
                                        <div>
                                            <span>{admin.firstName} {admin.lastName}</span>
                                            <span>{admin.email}</span>
                                        </div>
                                        
                                    </div>
                                    <div className={styles.Date}>
                                        <span>{rtnDate(admin.dateCreated)}</span>
                                    </div>
                                    <div className={styles.Active}>
                                        <span>{rtnDate(admin?.lastActive)}</span>
                                    </div>
                                    <div className={styles.Action}>
                                        <button onClick={() => {
                                            setFirstName(admin.firstName);
                                            setLastName(admin.lastName);
                                            setEmail(admin.email);
                                            setId(admin.id);
                                            setShowEditUser(true);
                                        }}>
                                            <img src={Edit} alt="Edit Icon" />
                                        </button>
                                        <button onClick={() => {
                                            setId(admin.id);
                                            setShowDeleteModal(true);
                                        }}>
                                            <img src={Delete} alt="Delete Icon" />
                                        </button>
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>  
                    </div>
                    <div className={styles.UserGroup}>
                        <div className={styles.UserGroupDescription}>
                            <h4>Manager</h4>
                            <p>Mangers can add, remove admins and<br/>manage organization-level settings</p>
                        </div>
                        <div className={styles.UserGroupList}>
                            <div>
                                <label className={styles.Name}>User</label>
                                <label className={styles.Date}>Date Created</label>
                                <label className={styles.Active}>Last Active</label>
                                <label className={styles.Action}></label>
                            </div>
                            <div>
                            {managers !== null && managers.map((manager, index) => (
                                <div key={index}>
                                    <div className={styles.Name}>
                                        <div>
                                            <img src={User} alt="User Icon"/>
                                        </div>
                                        <div>
                                            <span>{manager.firstName} {manager.lastName}</span>
                                            <span>{manager.email}</span>
                                        </div>
                                        
                                    </div>
                                    <div className={styles.Date}>
                                        <span>{rtnDate(manager.dateCreated)}</span>
                                    </div>
                                    <div className={styles.Active}>
                                        <span>{rtnDate(manager?.lastActive)}</span>
                                    </div>
                                    <div className={styles.Action}>
                                        <button onClick={() => {
                                            setFirstName(manager.firstName);
                                            setLastName(manager.lastName);
                                            setEmail(manager.email);
                                            setId(manager.id);
                                            setShowEditUser(true);
                                        }}>
                                            <img src={Edit} alt="Edit Icon" />
                                        </button>
                                        <button onClick={() => {
                                            setId(manager.id);
                                            setShowDeleteModal(true);
                                        }}>
                                            <img src={Delete} alt="Delete Icon" />
                                        </button>
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={styles.UserGroup}>
                        <div className={styles.UserGroupDescription}>
                            <h4>Support</h4>
                            <p>Support users can see transactions<br/>and chat with customers.</p>
                        </div>
                        <div className={styles.UserGroupList}>
                            <div>
                                <label className={styles.Name}>User</label>
                                <label className={styles.Date}>Date Created</label>
                                <label className={styles.Active}>Last Active</label>
                                <label className={styles.Action}></label>
                            </div>
                            <div>
                            {support !== null && support.map((supp, index) => (
                                <div key={index}>
                                    <div className={styles.Name}>
                                        <div>
                                            <img src={User} alt="User Icon"/>
                                        </div>
                                        <div>
                                            <span>{supp.firstName} {supp.lastName}</span>
                                            <span>{supp.email}</span>
                                        </div>
                                        
                                    </div>
                                    <div className={styles.Date}>
                                        <span>{rtnDate(supp.dateCreated)}</span>
                                    </div>
                                    <div className={styles.Active}>
                                        <span>{rtnDate(supp?.lastActive)}</span>
                                    </div>
                                    <div className={styles.Action}>
                                        <button onClick={() => {
                                            setFirstName(supp.firstName);
                                            setLastName(supp.lastName);
                                            setEmail(supp.email);
                                            setId(supp.id);
                                            setShowEditUser(true);
                                        }}>
                                            <img src={Edit} alt="Edit Icon" />
                                        </button>
                                        <button onClick={() => {
                                            setId(supp.id);
                                            setShowDeleteModal(true);
                                        }}>
                                            <img src={Delete} alt="Delete Icon" />
                                        </button>
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>     
    );

}

export default Users;