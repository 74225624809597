import ModalContainer from "../ModalContainer/Index";
import styles from "./css/index.module.css";
import FailedIcon from "./../../../../img/Failed.gif";
import { useNavigate } from "react-router-dom";

const IsLimited = ({showModal, limit}) => {
    const navigate = useNavigate();

    return(
        <ModalContainer showModal={showModal}>
            <div className={styles.Failed}>
                <img src={FailedIcon} alt="Failed Icon" />
                <h3>Transaction Limit Reached</h3>
                <p>You have exceeded your limit per transaction (${limit}) for your account level. Please upgrade your account through the link below</p>
                <button onClick={() => {showModal(false);navigate('/Dashboard/Settings/AccountUpgrade');}}>Account Upgrade</button>
            </div>
        </ModalContainer>
    );

}

export default IsLimited;