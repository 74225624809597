import Loading from "./../../img/loading.gif";
import styles from "./css/index.module.css";

const LoadingCircle = () => {
    return(
        <div className={styles.LoadingCtnr}>
            <img src={Loading} alt="Loading circle" className={styles.Loading} />
        </div>
    )
}

export default LoadingCircle