import ModalContainer from "../ModalContainer/Index";
import styles from "./css/index.module.css";
import FailedIcon from "./../../../../img/Failed.gif";

const Failed = () => {

    return(
        <ModalContainer>
            <div className={styles.Failed}>
                <img src={FailedIcon} alt="Failed Icon" />
                <h3>Transaction Failed</h3>
                <p>We were unable to process your request.</p>
                <button>Contact Support</button>
            </div>
        </ModalContainer>
    );

}

export default Failed;