import { Link } from "react-router-dom";
import SettingsHeader from "../../../../../Components/SettingsHeader/Index";
import styles from "./css/index.module.css";
import CurrentPin from "../../../Modals/CurrentPin/Index";
import NewPin from "../../../Modals/NewPin/Index";
import ConfirmPin from "../../../Modals/ConfirmPin/Index";
import { useContext, useState } from "react";
import { BASE_URL, CHANGE_PIN, VERIFY_PIN } from "../../../../../Utils/Endpoint";
import AuthContext from "../../../../../store/AuthContext";
import PinSuccess from "../../../Modals/PinSuccess/Index";

const Security = ({isAdmin = false}) => {
    const [showCurrentPin, setCurrent] = useState(false);
    const [showNewPin, setNew] = useState(false);
    const [showConfirmPin, setConfirm] = useState(false);
    const [showPinSuccess, setPinSuccess] = useState(false);

    const [currentPin, setCurrentPin] = useState('');
    const [newPin, setNewPin] = useState('');
    const [confirmPin, setConfirmPin] = useState('');

    const [errorMessage, setErrorMessage] = useState('');


    const AuthCtx = useContext(AuthContext);

    let CurrentPinAction = async () => {

        var obj = {
            "pin": currentPin,
          };
        
        console.log(obj);

        var response = await fetch(BASE_URL + VERIFY_PIN,
            {
                method : "POST",
                body : JSON.stringify(obj),
                headers : {
                    'content-type': 'application/json',
                    "Authorization" : "Bearer " + AuthCtx.token,
                    'Accept': 'application/json'
                }
            })

            let json = await response.json();
            console.log(json);

            if(response.ok){
               
                setErrorMessage('');
                setCurrent(false);
                setNew(true);
                return null;
            }

            setErrorMessage(json.statusText);
            return null;
        
    }

    let NewPinAction = async () => {
  
        setNew(false);
        setConfirm(true);    

    }

    let ConfirmPinAction = async () => {

        if(newPin !== confirmPin){
            setErrorMessage("Confirm Pin does not match New Pin.")
            return null;
        }
        var obj = {
            "pin": newPin,
          };
        
        console.log(obj);

        var response = await fetch(BASE_URL + CHANGE_PIN,
            {
                method : "POST",
                body : JSON.stringify(obj),
                headers : {
                    'content-type': 'application/json',
                    "Authorization" : "Bearer " + AuthCtx.token,
                    'Accept': 'application/json'
                }
            })

            let json = await response.json();
            console.log(json);

            if(response.ok){
               
                setErrorMessage('');
                setConfirm(false);
                setPinSuccess(true);
                return null;
            }

            setErrorMessage(json.statusText);
            return null;
    }

    return(
        <>
        {showCurrentPin && 
        <CurrentPin
            setPinModal={setCurrent}
            pin={currentPin}
            setPin={setCurrentPin}
            setPinConfirm={CurrentPinAction}
            errorMessage={errorMessage}
        />}
        {showNewPin && 
        <NewPin
            setPinModal={setNew}
            pin={newPin}
            setPin={setNewPin}
            setPinConfirm={NewPinAction}
        />}
        { showConfirmPin && 
        <ConfirmPin
            setPinModal={setConfirm}
            pin={confirmPin}
            setPin={setConfirmPin}
            setPinConfirm={ConfirmPinAction}
            errorMessage={errorMessage}
        />}
        {showPinSuccess && 
        <PinSuccess
            setIsCompleted={setPinSuccess}
        />}
        <div className={styles.Security}>
            <SettingsHeader
                heading={"Security"}
                backText={"Settings"}
                backLink={isAdmin ? "/Admin/Settings" : "/Dashboard/Settings"}
            />
            <div className={styles.SecurityBody}>
                <div>
                    <div>
                        <h4>Password</h4>
                        <span>Last updated : 20th January, 2023</span>
                    </div>
                    {true ? 
                    <Link to={'ChangePassword'}>Change</Link> : 
                    <button>Change</button>}
                </div>
                {!isAdmin && 
                <div>
                    <div>
                        <h4>Transaction Pin</h4>
                        <span>A unique pin to protect your transactions  </span>
                    </div>
                    {false ? 
                    <Link>Change</Link> : 
                    <button onClick={() => setCurrent(true)}>Change</button>}
                </div>}
            </div>
        </div>
        </>
    );

}

export default Security;