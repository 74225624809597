import { NavLink } from "react-router-dom";
import styles from "./css/index.module.css";
import DashboardActive from "./../../img/ADashboardActive.png";
import DashboardInactive from "./../../img/ADashboardInactive.png";
import TransactionActive from "./../../img/ATransactionActive.png";
import TransactionInactive from "./../../img/ATransactionInactive.png";
import CustomerActive from "./../../img/ACustomerActive.png";
import CustomerInactive from "./../../img/ACustomerInactive.png";
import UsersActive from "./../../img/AUserActive.png";
import UsersInactive from "./../../img/AUserInactive.png";
import SettingsActive from "./../../img/ASettingsActive.png";
import SettingsInactive from "./../../img/ASettingsInactive.png";
import { useState } from "react";


const AdminNavigation = ({setShowNav}) => {
    const [activeHoverIndex, setIndex] = useState(0);

    return(
        <div className={styles.AdminNavigation}>
            <ul onClick={() => setShowNav(false)}>
                <li>
                    <NavLink 
                        className={({isActive, isPending}) => isActive ? `${styles.ActiveNav} ${activeHoverIndex === 1 ? styles.IsHovered : ''}` : activeHoverIndex === 1 ? styles.IsHovered : ''}
                        onMouseEnter={() => setIndex(1)}
                        onMouseLeave={() => setIndex(0)}
                        end
                        to={'/Admin'}>
                        {({isActive, isPending}) => (
                            <div>
                            <img src={isActive ? DashboardActive : DashboardInactive } alt="Dashboard Icon" />
                            <span>Dashboard</span>
                            </div>
                        )}       
                    </NavLink>
                </li>
                <li>
                    <NavLink 
                        className={({isActive, isPending}) => isActive ? `${styles.ActiveNav} ${activeHoverIndex === 2 ? styles.IsHovered : ''}` : activeHoverIndex === 2 ? styles.IsHovered : ''}
                        onMouseEnter={() => setIndex(2)}
                        onMouseLeave={() => setIndex(0)}
                        to={'Transactions'}>
                        {({isActive, isPending}) => (
                            <div>
                            <img src={isActive ? TransactionActive : TransactionInactive } alt="Transaction Icon" />
                            <span>Transactions</span>
                            </div>
                        )}       
                    </NavLink>
                </li>
                <li>
                    <NavLink 
                        className={({isActive, isPending}) => isActive ? `${styles.ActiveNav} ${activeHoverIndex === 3 ? styles.IsHovered : ''}` : activeHoverIndex === 3 ? styles.IsHovered : ''}
                        onMouseEnter={() => setIndex(3)}
                        onMouseLeave={() => setIndex(0)}
                        to={'Customers'}>
                        {({isActive, isPending}) => (
                            <div>
                            <img src={isActive ? CustomerActive : CustomerInactive } alt="Customer Icon" />
                            <span>Customers</span>
                            </div>
                        )}       
                    </NavLink>
                </li>
                <li>
                    <NavLink 
                        className={({isActive, isPending}) => isActive ? `${styles.ActiveNav} ${activeHoverIndex === 4 ? styles.IsHovered : ''}` : activeHoverIndex === 4 ? styles.IsHovered : ''}
                        onMouseEnter={() => setIndex(4)}
                        onMouseLeave={() => setIndex(0)}
                        to={'Users'}>
                        {({isActive, isPending}) => (
                            <div>
                            <img src={isActive ? UsersActive : UsersInactive } alt="Users Icon" />
                            <span>Users</span>
                            </div>
                        )}       
                    </NavLink>
                </li>
                <li>
                    <NavLink 
                        className={({isActive, isPending}) => isActive ? `${styles.ActiveNav} ${activeHoverIndex === 5 ? styles.IsHovered : ''}` : activeHoverIndex === 5 ? styles.IsHovered : ''}
                        onMouseEnter={() => setIndex(5)}
                        onMouseLeave={() => setIndex(0)}
                        to={'Settings'}>
                        {({isActive, isPending}) => (
                            <div>
                            <img src={isActive ? SettingsActive : SettingsInactive } alt="Settings Icon" />
                            <span>Settings</span>
                            </div>
                        )}       
                    </NavLink>
                </li>
            </ul>
        </div>
    );

}

export default AdminNavigation;