import { useContext, useEffect, useState } from "react";
import Transactions from "../../../../Components/Transactions/Index";
import styles from "./../css/index.module.css";
import { Link } from "react-router-dom";
import { BASE_URL, GET_TRANSACTION_LIST } from "../../../../Utils/Endpoint";
import AuthContext from "../../../../store/AuthContext";

const RecentTransactions = () => {
    const [transactions, setTransactions] = useState(null);
    const AuthCtx = useContext(AuthContext);


    useEffect(() => {
        let getTransactionsAction = async () => {
        
            var response = await fetch(BASE_URL + GET_TRANSACTION_LIST,
                {
                    method : "GET",
                    headers : {
                        'content-type': 'application/json',
                        "Authorization" : "Bearer " + AuthCtx.token,
                        'Accept': 'application/json'
                    }
                })
    
                if(response.ok){
                    let json = await response.json();
                    setTransactions(json.data);
                }
        }

        getTransactionsAction()
        
    },[AuthCtx])

    return(
        <div className={styles.RecentTransactions}>
            <div>
                <h3>Recent Transactions</h3>
                <Link to={'Account'}>View All</Link>
            </div>
            <Transactions transactions={transactions}/>
        </div>
    )

}

export default RecentTransactions;