import { Link } from "react-router-dom";
import styles from "./css/index.module.css";
import SettingsHeader from "../../../../../Components/SettingsHeader/Index";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../store/AuthContext";
import { BASE_URL, GET_USER } from "../../../../../Utils/Endpoint";

const General = ({isAdmin = false}) => {

    const [user, setUser] = useState(null);


    const AuthCtx = useContext(AuthContext);


    useEffect(() => {
        let getTransactionsAction = async () => {
        
            var response = await fetch(BASE_URL + GET_USER,
                {
                    method : "GET",
                    headers : {
                        'content-type': 'application/json',
                        "Authorization" : "Bearer " + AuthCtx.token,
                        'Accept': 'application/json'
                    }
                })
    
                if(response.ok){
                    let json = await response.json();
                    setUser(json.data);
                }
        }

        getTransactionsAction()
        
    },[AuthCtx])

    let rtnDate = (date) => {

        let newDate = new Date(date);
        var strDate = newDate.toLocaleDateString('en-US', {day:"numeric", year:"numeric", month:"short"});
        return strDate;

    }

    return(
        <div className={styles.General}>
            <SettingsHeader
                heading={"General"}
                backText={"Settings"}
                backLink={isAdmin ? "/Admin/Settings" : "/Dashboard/Settings"}
            />
            {user !== null &&
            <div className={styles.GeneralBody}>
                <div>
                    <div>
                        <h4>{user.firstName + " " + user.lastName}</h4>
                        <span>{user.email}</span>
                    </div>
                </div>
                <div className={styles.Person}>
                    <div>
                        <h5>Personal Information</h5>
                        <Link to={'Edit'}>
                            Edit
                        </Link>
                    </div>
                    <div>
                        <div>
                            <label>First Name</label>
                            <span>{user.firstName}</span>
                        </div>
                        <div>
                            <label>Last Name</label>
                            <span>{user.lastName}</span>
                        </div>
                        <div>
                            <label>Date of Birth</label>
                            <span>{rtnDate(user.dateOfBirth) ?? "--"}</span>
                        </div>
                        <div>
                            <label>Phone Number</label>
                            <span>{user.phoneNumber}</span>
                        </div>
                        <div>
                            <label>Country</label>
                            <span>{user.country ?? "--"}</span>
                        </div>
                        <div>
                            <label>State</label>
                            <span>{user.state ?? "--"}</span>
                        </div>
                        <div>
                            <label>Street Address</label>
                            <span>{user.streetAddress ?? "--"}</span>
                        </div>
                    </div>
                    <button>Close Account</button>
                </div>    
            </div>}
        </div>
    )

}

export default General;