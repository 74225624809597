import { Link } from "react-router-dom";
import styles from "./css/index.module.css";
import ArrowRight from "./../../../img/ArrowRight.png";

const Settings = () => {

    return(
        <div className={styles.Settings}>
            <div>
                <h3>Settings</h3>
            </div>
            <div>
                <Link to={'General'}>
                    <div>
                        <h4>General</h4>
                        <p>View your account information</p>
                    </div>
                    <img src={ArrowRight}  alt="Arrow Right"/>
                </Link>
                <Link to={"Transactions"}>
                    <div>
                        <h4>Transactions</h4>
                        <p>Manage transactions rates and timers.</p>
                    </div>
                    <img src={ArrowRight}  alt="Arrow Right"/>
                </Link>
                <Link to={"Notifications"}>
                    <div>
                        <h4>Notifications</h4>
                        <p>Manage how we notify you</p>
                    </div>
                    <img src={ArrowRight}  alt="Arrow Right"/>
                </Link>
                <Link to={"Security"}>
                    <div>
                        <h4>Security</h4>
                        <p>Protect your account and transactions.</p>
                    </div>
                    <img src={ArrowRight}  alt="Arrow Right"/>
                </Link>
            </div>
        </div>
    );

}

export default Settings