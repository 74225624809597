import Button from "../../../Components/Button/Index";
import Input from "../../../Components/Input/Index";
import InputCtnr from "../../../Components/InputCtnr/Index";
import Title from "./Title";

const StepTwo = ({setLevel, stepTwoInfo, setStepTwoInfo}) => {

    let GoBackHandler = () => {
        setLevel(0);
    }

    let setFirstChange = (value) => {
        setStepTwoInfo(prevState => {
            return{
                ...prevState,
                firstName : value
            }
        })
    }
    let setLastChange = (value) => {
        setStepTwoInfo(prevState => {
            return{
                ...prevState,
                lastName : value
            }
        })
    }

    let continueAction = () => {
        if(stepTwoInfo.firstName.length > 0 && stepTwoInfo.lastName.length > 0){
            console.log(stepTwoInfo);
            setLevel(2);
        }
    }

    return(
        <div>
            <Title
                title={"Personal information"}
                currentValue={2}
                goBack={GoBackHandler}
            />
            <InputCtnr label={"First Name"}>
                <Input 
                    placeholder={"John"} 
                    type={"text"}
                    setChange={setFirstChange}
                    value={stepTwoInfo.firstName}
                />
            </InputCtnr>
            <InputCtnr label={"Last Name"}>
                <Input 
                    placeholder={"Doe"} 
                    type={"text"}
                    setChange={setLastChange}
                    value={stepTwoInfo.lastName}
                />
            </InputCtnr>
            <Button 
                text={"Continue"} 
                isEnabled={stepTwoInfo.firstName.length > 0 && stepTwoInfo.lastName.length > 0}
                action={continueAction}
            />
        </div>
    );

}

export default StepTwo;