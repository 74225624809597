// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_Action__4MsIi{display: flex;flex-direction: column;align-items: center;width: 343px;}
.css_AddBorder__4\\+Kps{border-left: 1px solid var(--gray-200, #EAECF0); border-right: 1px solid var(--gray-200, #EAECF0);padding: 0px 32px;}

.css_Action__4MsIi > label{color: var(--gray-900, #101828);text-align: center;font-size: 20px;font-style: normal;font-weight: 500;line-height: 30px;margin: 20px 0px 8px 0px;}
.css_Action__4MsIi > p{color: var(--gray-500, #667085);text-align: center;font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px;margin: 0px;}

@media screen and (max-width : 800px) {
    .css_Action__4MsIi{width: 100%;}
    .css_AddBorder__4\\+Kps{border: none;padding: 0px;}
}`, "",{"version":3,"sources":["webpack://./src/Components/Action/css/index.module.css"],"names":[],"mappings":"AAAA,mBAAQ,aAAa,CAAC,sBAAsB,CAAC,mBAAmB,CAAC,YAAY,CAAC;AAC9E,uBAAW,+CAA+C,EAAE,gDAAgD,CAAC,iBAAiB,CAAC;;AAE/H,2BAAgB,+BAA+B,CAAC,kBAAkB,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,wBAAwB,CAAC;AAClK,uBAAY,+BAA+B,CAAC,kBAAkB,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,WAAW,CAAC;;AAEjJ;IACI,mBAAQ,WAAW,CAAC;IACpB,uBAAW,YAAY,CAAC,YAAY,CAAC;AACzC","sourcesContent":[".Action{display: flex;flex-direction: column;align-items: center;width: 343px;}\n.AddBorder{border-left: 1px solid var(--gray-200, #EAECF0); border-right: 1px solid var(--gray-200, #EAECF0);padding: 0px 32px;}\n\n.Action > label{color: var(--gray-900, #101828);text-align: center;font-size: 20px;font-style: normal;font-weight: 500;line-height: 30px;margin: 20px 0px 8px 0px;}\n.Action > p{color: var(--gray-500, #667085);text-align: center;font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px;margin: 0px;}\n\n@media screen and (max-width : 800px) {\n    .Action{width: 100%;}\n    .AddBorder{border: none;padding: 0px;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Action": `css_Action__4MsIi`,
	"AddBorder": `css_AddBorder__4+Kps`
};
export default ___CSS_LOADER_EXPORT___;
