// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_InputWrapper__sQkA2{width: 100%;position: relative;}
.css_InputWrapper__sQkA2 > img{position: absolute;top: 14px;right: 14px;}
.css_InputWrapper__sQkA2 > input{width: 100%;padding: 14px;border-radius: 8px;border: 1px solid var(--gray-300, #D0D5DD);background: var(--white, #FFF);color: var(--gray-500, #667085);font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px;box-sizing: border-box;}
`, "",{"version":3,"sources":["webpack://./src/Components/Input/css/index.module.css"],"names":[],"mappings":"AAAA,yBAAc,WAAW,CAAC,kBAAkB,CAAC;AAC7C,+BAAoB,kBAAkB,CAAC,SAAS,CAAC,WAAW,CAAC;AAC7D,iCAAsB,WAAW,CAAC,aAAa,CAAC,kBAAkB,CAAC,0CAA0C,CAAC,8BAA8B,CAAC,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,sBAAsB,CAAC","sourcesContent":[".InputWrapper{width: 100%;position: relative;}\n.InputWrapper > img{position: absolute;top: 14px;right: 14px;}\n.InputWrapper > input{width: 100%;padding: 14px;border-radius: 8px;border: 1px solid var(--gray-300, #D0D5DD);background: var(--white, #FFF);color: var(--gray-500, #667085);font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px;box-sizing: border-box;}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InputWrapper": `css_InputWrapper__sQkA2`
};
export default ___CSS_LOADER_EXPORT___;
