import Title from "./Title";
import styles from "./../css/index.module.css";
import OtpInput from 'react-otp-input';
//import { useState } from "react";
import Button from "../../../Components/Button/Index";
import InputCtnr from "../../../Components/InputCtnr/Index";
//import { useNavigate } from "react-router-dom"


const StepPin = ({pin, setPin, setLevel, errorMessage}) => {
    //const [pin1, setPin1] = useState('');
    //const navigate = useNavigate();

    let GoBackHandler = () => {
        setLevel(2);
    }


    let CreateAction = () => {

        if(pin.length === 4){
            console.log(pin);
            setLevel(4);
        }
    }


    return(
    <div className={styles.StepFour}>
        <Title
            title={"Set Up Pin"}
            currentValue={4}
            goBack={GoBackHandler}
        />
        <InputCtnr label={"Enter your Pin"}>
            <OtpInput
                value={pin}
                onChange={setPin}
                numInputs={4}
                renderInput={(props) => <input {...props}/>}
                containerStyle={styles.OtpInputCtnr}
                inputStyle={styles.OtpInput}
                inputType={"tel"}
            />
        </InputCtnr>
        <p>Enter the Pin you will be using in our app to confirm transactions.</p>
        <Button 
            text={"Create Account"}
            isEnabled={pin.length === 4}
            action={CreateAction}
        />
        <span className={styles.ErrorMessage}>{errorMessage}</span>
    </div>)

}

export default StepPin;