import ModalContainer from "../ModalContainer/Index";
import styles from "./css/index.module.css";
import Transfer from "./../../../../img/Transfer.png";
import { useState } from "react";

const Deposit = ({setDepositModal, walletBalance, rate, depAmount, setDepAmount, setBankTransfer, setModalCheck}) => {
    const [error, setError] = useState('');

    let setConfirm = () => {
        if(depAmount === ''){
            setError("Enter an Amount to deposit");
            return;
        }
        setError('')
        setDepositModal(false);
        setBankTransfer(true)
    }

    let check = async () => {

        await setModalCheck(setConfirm, depAmount);
    }

    let setCancel = () => {
        setDepAmount('');
        setDepositModal(false);
    }

    return(
        <ModalContainer showModal={setDepositModal}>
            <div className={styles.Deposit}>
                <h3>Deposit</h3>
                <div>
                    <div>
                        <label>Amount</label>
                        <div>
                            <label>NGN</label>
                            <input value={depAmount}  type={'number'} onChange={(e) => setDepAmount(e.target.value)}/>
                        </div>
                        <div>
                            <span>Wallet Balance</span>
                            <span>NGN {walletBalance.toFixed(2)}</span>
                        </div>
                    </div>
                    <div>
                        <label>Payment Method</label>
                        <div>
                            <img src={Transfer} alt="Bank Transfer Icon"/>
                            <label>Bank Transfer</label>
                        </div>
                    </div>
                    {/* <div>
                        <div>
                            <span>You will receive</span>
                            <span>{depAmount} NGN</span>
                        </div>
                        <div>
                            <span>You will pay</span>
                            <span>{depAmount.toFixed(2)} NGN</span>
                        </div>
                        <div>
                            <span>Exchange rate</span>
                            <span>NGN {rate.toFixed(2)}</span>
                        </div>
                    </div> */}
                </div>
                <span className={styles.ErrorMessage}>{error}</span>
                <div>
                    <button onClick={() => setCancel()}>
                        Cancel
                    </button>
                    <button onClick={() => {check()}}>
                        Confirm
                    </button>
                </div>
            </div>
        </ModalContainer>
    )
}

export default Deposit;