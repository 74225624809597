import styles from './css/index.module.css';
import logo from './../../img/CoinNestLogo.png';
import { Link } from "react-router-dom";
import { useState } from 'react';

const Header = () => {
    const [showToggle, setShowToggle] = useState(false);

    return(
        <>
        <div className={styles.HeaderContainer}>
            <Link to={'/'}>
                <img src={logo} alt='CoinNest Logo'/>
            </Link>
            <ul className={styles.HeaderNav}>
                <li>
                    <Link to={'/About'}>
                        <span>About</span>
                    </Link>
                </li>
                <li>
                    <Link to={'/FAQs'}>
                        <span>FAQs</span>
                    </Link>
                </li>
                <li>
                    <Link to={'/'}>
                        <span>Blog</span>
                    </Link>
                </li>
                <li>
                    <Link to={'/Contact'}>
                        <span>Contact</span>
                    </Link>
                </li>
            </ul>
            <div className={styles.HeaderUser}>
                <div className={styles.Login}>
                    <Link to={'/Login'}>
                        <span>Log in</span>
                    </Link>
                </div>
                <div className={styles.SignUp}>
                    <Link to={'/OnBoarding'}>
                        <span>Sign up</span>
                    </Link>
                </div>
            </div>
            <button className={styles.menuToggle} onClick={() => setShowToggle(prev => !prev)}>
                <span></span>
                <span></span>
                <span></span>
            </button>
        </div>
        {showToggle &&
        <div className={styles.MobMenuCtnr}>
            <div onClick={() => setShowToggle(false)}></div>
            <div>
                <ul className={`${styles.HeaderNav} ${styles.Mob}`}>
                    <li>
                        <Link to={'/About'}>
                            <span>About</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/FAQs'}>
                            <span>FAQs</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/'}>
                            <span>Blog</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/Contact'}>
                            <span>Contact</span>
                        </Link>
                    </li>
                </ul>
                <div className={`${styles.HeaderUser} ${styles.Mob}`}>
                    <div className={styles.Login}>
                        <Link to={'/Login'}>
                            <span>Log in</span>
                        </Link>
                    </div>
                    <div className={styles.SignUp}>
                        <Link to={'/OnBoarding'}>
                            <span>Sign up</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>}
        </>
    );
}

export default Header;