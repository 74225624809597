import React, { useState, useRef, useEffect, useCallback } from 'react';
import styles from "./css/index.module.css";
import OnBoardingCtnr from "../../Components/OnBoardingCtnr/Index";

const CountDown = () => {

    const Ref = useRef(null);
 
    // The state for our timer
    const [timer, setTimer] = useState(null);
 
    const getTimeRemaining = useCallback( (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        const days = Math.floor((total / 1000 / 60 / 60 / 24) % 24);
        return {
            total, days, hours, minutes, seconds
        };
    },[])
 
    const startTimer = useCallback((e) => {
        let { total, days, hours, minutes, seconds } 
                    = getTimeRemaining(e);
        if (total >= 0) {
 
            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the beginning of the variable
            setTimer(
                (days) + '.' +
                (hours > 9 ? hours : '0' + hours) + ':' +
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }
    },[getTimeRemaining])
 
    const clearTimer = useCallback((e) => {
 
        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next    
        //setTimer('00:00:10');
 
        // If you try to remove this line the 
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    },[startTimer])
 
    const getDeadTime = () => {
        let deadline = new Date("October 24, 2023 00:00:00");
 
        // This is where you need to adjust if 
        // you entend to add more time
        deadline.setDate(deadline.getDate() + 3);
        deadline.setHours(deadline.getHours() + 12)
        return deadline;
    }
 
    // We can use useEffect so that when the component
    // mount the timer will start as soon as possible
 
    // We put empty array to act as componentDid
    // mount only
    useEffect(() => {
        clearTimer(getDeadTime());
    }, [clearTimer]);

    return(<OnBoardingCtnr isBig={true}>
        <div className={styles.CountDown}>
            <h2>{timer}</h2>
        </div>
    </OnBoardingCtnr>)

}

export default CountDown;