import styles from "./../css/index.module.css";
import User from "./../../../../img/User.png";
import Edit from "./../../../../img/Edit.png";
import Delete from "./../../../../img/Delete.png";
import { Link, useNavigate } from "react-router-dom";

const CustomerTable = ({customers = []}) => {
    const navigate = useNavigate();

    let rtnDate = (date) => {

        let newDate = new Date(date);
        var strDate = newDate.toLocaleDateString('en-US', {day:"numeric", year:"numeric", month:"short"});
        return strDate;

    }

    return(
        <div className={styles.CustomerTable}>
            <div>
                <label className={styles.Name}>Customer</label>
                <label className={styles.Email}>Email Address</label>
                <label className={styles.Number}>Phone Number</label>
                <label className={styles.Date}>Date Created</label>
                <label className={styles.Action}></label>
            </div>
            <div>
                {customers !== null && 
                customers.map((customer, index) => (
                <Link 
                    key={index} 
                    to={'/Admin/Customer/' + customer.id} 
                    >
                    <div className={styles.Name}>
                        <div>
                            <img src={User}  alt="User icon"/>
                        </div>
                        <span>{customer.firstName + " " + customer.lastName}</span>
                    </div>
                    <div className={styles.Email}>
                        <span>{customer.email}</span>
                    </div>
                    <div className={styles.Number}>
                        <span>{customer.phoneNumber}</span>
                    </div>
                    <div className={styles.Date}>
                        <span>{rtnDate(customer.dateCreated)}</span>
                    </div>
                    <div className={styles.Action}>
                        <button onClick={(e) =>  {e.preventDefault(); navigate('/Admin/Customer/' + customer.id + '/Profile/Edit')}}>
                            <img src={Edit} alt="Edit Icon" />
                        </button>
                        <button onClick={(e) =>  {e.preventDefault(); navigate('/Admin/Customer/' + customer.id + '/Profile')}}>
                            <img src={Delete} alt="Delete Icon" />
                        </button>
                    </div>
                </Link>
                ))}
            </div>
        </div>
    );

}

export default CustomerTable;