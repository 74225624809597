// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_Notifications__-0BjG{padding: 32px;text-align: left;}
.css_NotificationsBody__9dVU2{margin-top: 32px;margin-bottom: 126px;}
.css_NotificationsBody__9dVU2 > a{padding: 16px 0px; display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #EAECF0;text-decoration: none;}
.css_NotificationsBody__9dVU2 > a > div{display: flex;flex-direction: column;}
.css_NotificationsBody__9dVU2 > a > div > h4{margin: 0px;color: var(--gray-900, #101828);font-size: 18px;font-style: normal;font-weight: 500;line-height: 28px; /* 155.556% */}
.css_NotificationsBody__9dVU2 > a > div > span{color: var(--gray-500, #667085);font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px; /* 150% */}`, "",{"version":3,"sources":["webpack://./src/Pages/Admin/Settings/Pages/Transactions/css/index.module.css"],"names":[],"mappings":"AAAA,0BAAe,aAAa,CAAC,gBAAgB,CAAC;AAC9C,8BAAmB,gBAAgB,CAAC,oBAAoB,CAAC;AACzD,kCAAuB,iBAAiB,EAAE,aAAa,CAAC,8BAA8B,CAAC,mBAAmB,CAAC,gCAAgC,CAAC,qBAAqB,CAAC;AAClK,wCAA6B,aAAa,CAAC,sBAAsB,CAAC;AAClE,6CAAkC,WAAW,CAAC,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,EAAE,aAAa,CAAC;AACnK,+CAAoC,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,EAAE,SAAS,CAAC","sourcesContent":[".Notifications{padding: 32px;text-align: left;}\n.NotificationsBody{margin-top: 32px;margin-bottom: 126px;}\n.NotificationsBody > a{padding: 16px 0px; display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #EAECF0;text-decoration: none;}\n.NotificationsBody > a > div{display: flex;flex-direction: column;}\n.NotificationsBody > a > div > h4{margin: 0px;color: var(--gray-900, #101828);font-size: 18px;font-style: normal;font-weight: 500;line-height: 28px; /* 155.556% */}\n.NotificationsBody > a > div > span{color: var(--gray-500, #667085);font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px; /* 150% */}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Notifications": `css_Notifications__-0BjG`,
	"NotificationsBody": `css_NotificationsBody__9dVU2`
};
export default ___CSS_LOADER_EXPORT___;
