// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_CountDown__tfA2Q{display: flex;justify-content: center;align-items: center; text-align: left;}

.css_CountDown__tfA2Q > h2{font-size: 72px;margin: 0px; color: #292977;}

@media screen and (max-width : 400px) {
    .css_CountDown__tfA2Q > h2{font-size: 48px;} 
}`, "",{"version":3,"sources":["webpack://./src/Pages/CountDown/css/index.module.css"],"names":[],"mappings":"AAAA,sBAAW,aAAa,CAAC,uBAAuB,CAAC,mBAAmB,EAAE,gBAAgB,CAAC;;AAEvF,2BAAgB,eAAe,CAAC,WAAW,EAAE,cAAc,CAAC;;AAE5D;IACI,2BAAgB,eAAe,CAAC;AACpC","sourcesContent":[".CountDown{display: flex;justify-content: center;align-items: center; text-align: left;}\n\n.CountDown > h2{font-size: 72px;margin: 0px; color: #292977;}\n\n@media screen and (max-width : 400px) {\n    .CountDown > h2{font-size: 48px;} \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CountDown": `css_CountDown__tfA2Q`
};
export default ___CSS_LOADER_EXPORT___;
