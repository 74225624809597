// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_FirstSection__5uflq{padding: 96px 0px;}

.css_SecondSection__PMj7x{padding-bottom: 96px;text-align: left;display: flex;align-items: center; flex-direction: column;}

.css_SecondSection__PMj7x > div{width: 75%;}
.css_SecondSection__PMj7x > div > h3{margin: 0px;color: var(--gray-900, #101828);font-size: 30px;font-style: normal;font-weight: 500;line-height: 38px;margin-bottom: 48px;}
.css_SecondSection__PMj7x > div > div{color: var(--gray-500, #667085);font-size: 18px;font-style: normal;font-weight: 450;line-height: 28px;text-align: left;}
@media screen and (max-width : 800px) { 
    .css_FirstSection__5uflq{padding: 48px 32px;}
}`, "",{"version":3,"sources":["webpack://./src/Pages/PrivacyPolicy/css/index.module.css"],"names":[],"mappings":"AAAA,yBAAc,iBAAiB,CAAC;;AAEhC,0BAAe,oBAAoB,CAAC,gBAAgB,CAAC,aAAa,CAAC,mBAAmB,EAAE,sBAAsB,CAAC;;AAE/G,gCAAqB,UAAU,CAAC;AAChC,qCAA0B,WAAW,CAAC,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,mBAAmB,CAAC;AAChK,sCAA2B,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,gBAAgB,CAAC;AAClJ;IACI,yBAAc,kBAAkB,CAAC;AACrC","sourcesContent":[".FirstSection{padding: 96px 0px;}\n\n.SecondSection{padding-bottom: 96px;text-align: left;display: flex;align-items: center; flex-direction: column;}\n\n.SecondSection > div{width: 75%;}\n.SecondSection > div > h3{margin: 0px;color: var(--gray-900, #101828);font-size: 30px;font-style: normal;font-weight: 500;line-height: 38px;margin-bottom: 48px;}\n.SecondSection > div > div{color: var(--gray-500, #667085);font-size: 18px;font-style: normal;font-weight: 450;line-height: 28px;text-align: left;}\n@media screen and (max-width : 800px) { \n    .FirstSection{padding: 48px 32px;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FirstSection": `css_FirstSection__5uflq`,
	"SecondSection": `css_SecondSection__PMj7x`
};
export default ___CSS_LOADER_EXPORT___;
