import styles from "./css/index.module.css";
import SearchIcon from "./../../../img/Search.png";
import FilterIcon from "./../../../img/Filter.png";
import DownloadIcon from "./../../../img/Download.png";
import CustomerTable from "./Components/CustomerTable";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../store/AuthContext";
import { BASE_URL, GET_CUSTOMERS } from "../../../Utils/Endpoint";

const Customers = () => {
    const [customers, setCustomers] = useState(null);
    const AuthCtx = useContext(AuthContext);


    useEffect(() => {
        let getTransactionsAction = async () => {
        
            var response = await fetch(BASE_URL + GET_CUSTOMERS,
                {
                    method : "GET",
                    headers : {
                        'content-type': 'application/json',
                        "Authorization" : "Bearer " + AuthCtx.token,
                        'Accept': 'application/json'
                    }
                })
    
                if(response.ok){
                    let json = await response.json();
                    setCustomers(json.data);
                }
        }

        getTransactionsAction()
        
    },[AuthCtx])


    

    return(
        <div className={styles.Customers}>
            <h3>Customers</h3>
            <div>
                <div>
                    <img src={SearchIcon} alt="Search icon"/>
                    <input  placeholder="Search"/>
                </div>
                <div>
                    <button>
                        <img src={FilterIcon} alt="Filter icon"/>
                        <span>Sort by</span>
                    </button>
                    <button>
                        <img src={DownloadIcon} alt="Download icon"/>
                        <span>Download CSV</span>
                    </button>
                </div>
            </div>
            <CustomerTable customers={customers} />
        </div>
    );

}

export default Customers;