// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_Settings__L\\+Pa\\+{margin:32px; text-align: left;}
.css_Settings__L\\+Pa\\+ > div:nth-of-type(1){border-bottom: 1px solid #EAECF0; padding-bottom: 16px;}
.css_Settings__L\\+Pa\\+ > div:nth-of-type(1) > h3{margin: 0px;color: var(--primary, #060661);font-size: 30px;font-style: normal;font-weight: 500;line-height: 38px; /* 126.667% */}
.css_Settings__L\\+Pa\\+ > div:nth-of-type(2) {display: flex;flex-direction: column;margin-top: 34px;margin-bottom: 76px;}
.css_Settings__L\\+Pa\\+ > div:nth-of-type(2) > a{display: flex;padding: 16px 0px;justify-content: space-between;align-items: center;text-decoration: none;border-bottom: 1px solid #EAECF0;}
.css_Settings__L\\+Pa\\+ > div:nth-of-type(2) > a > div > h4{margin: 0px; padding: 0px;color: var(--gray-700, #344054);font-size: 18px;font-style: normal;font-weight: 500;line-height: 28px; /* 155.556% */}
.css_Settings__L\\+Pa\\+ > div:nth-of-type(2) > a > div > p{margin: 0px; padding: 0px;color: var(--gray-500, #667085); font-size: 18px;font-style: normal;font-weight: 450;line-height: 28px; /* 155.556% */}`, "",{"version":3,"sources":["webpack://./src/Pages/Admin/Settings/css/index.module.css"],"names":[],"mappings":"AAAA,uBAAU,WAAW,EAAE,gBAAgB,CAAC;AACxC,4CAA+B,gCAAgC,EAAE,oBAAoB,CAAC;AACtF,iDAAoC,WAAW,CAAC,8BAA8B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,EAAE,aAAa,CAAC;AACpK,6CAAgC,aAAa,CAAC,sBAAsB,CAAC,gBAAgB,CAAC,mBAAmB,CAAC;AAC1G,gDAAmC,aAAa,CAAC,iBAAiB,CAAC,8BAA8B,CAAC,mBAAmB,CAAC,qBAAqB,CAAC,gCAAgC,CAAC;AAC7K,2DAA8C,WAAW,EAAE,YAAY,CAAC,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,EAAE,aAAa,CAAC;AAC7L,0DAA6C,WAAW,EAAE,YAAY,CAAC,+BAA+B,EAAE,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,EAAE,aAAa,CAAC","sourcesContent":[".Settings{margin:32px; text-align: left;}\n.Settings > div:nth-of-type(1){border-bottom: 1px solid #EAECF0; padding-bottom: 16px;}\n.Settings > div:nth-of-type(1) > h3{margin: 0px;color: var(--primary, #060661);font-size: 30px;font-style: normal;font-weight: 500;line-height: 38px; /* 126.667% */}\n.Settings > div:nth-of-type(2) {display: flex;flex-direction: column;margin-top: 34px;margin-bottom: 76px;}\n.Settings > div:nth-of-type(2) > a{display: flex;padding: 16px 0px;justify-content: space-between;align-items: center;text-decoration: none;border-bottom: 1px solid #EAECF0;}\n.Settings > div:nth-of-type(2) > a > div > h4{margin: 0px; padding: 0px;color: var(--gray-700, #344054);font-size: 18px;font-style: normal;font-weight: 500;line-height: 28px; /* 155.556% */}\n.Settings > div:nth-of-type(2) > a > div > p{margin: 0px; padding: 0px;color: var(--gray-500, #667085); font-size: 18px;font-style: normal;font-weight: 450;line-height: 28px; /* 155.556% */}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Settings": `css_Settings__L+Pa+`
};
export default ___CSS_LOADER_EXPORT___;
