import ModalContainer from "../ModalContainer/Index";
import styles from "./css/index.module.css";
import PendingIcon from "./../../../../img/PendingTrans.gif";
import { useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "../../../../store/AuthContext";

const Pending = ({setIsPending, setIsCompleted, type, showCancel = false}) => {
    const AuthCtx = useContext(AuthContext);
    const [started, setStarted] = useState(false);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    

    const getTime = useCallback((deadline) => {
        const time = Date.parse(deadline) - Date.now();
        //setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
        setStarted(true);
    },[]);

    useEffect(() => {
        let date = new Date();
        console.log(AuthCtx.timers)
        date.setHours(date.getHours() + AuthCtx.timers[type.toLowerCase()].hours);
        date.setMinutes(date.getMinutes() + AuthCtx.timers[type.toLowerCase()].minutes);
        date.setSeconds(date.getSeconds() + AuthCtx.timers[type.toLowerCase()].seconds);

        // Prompt confirmation when reload page is triggered
        window.onbeforeunload = () => { return "" };

        const interval = setInterval(() => getTime(date), 1000);
        
        return () => {clearInterval(interval); window.onbeforeunload = null}

    },[getTime,AuthCtx.timers, type])

    useEffect(() => {
        if(seconds === 0 && minutes === 0 && started){
            setIsPending(false);
            setIsCompleted(true);
        }

            
    },[seconds,setIsCompleted,setIsPending,minutes,started])

    return(
        <ModalContainer showCancel={showCancel} showModal={setIsPending}>
            <div className={styles.Pending}>
                <img src={PendingIcon} alt="Pending Icon" />
                <h3>Transaction Pending</h3>
                <p>Please wait while we process your transaction.</p>
                <span>Time left {`${hours}:${minutes}:${seconds}`}</span>
                <button onClick={(e) => {e.preventDefault(); window.location.href = "mailto:info@coinnest.africa"}}>Please Contact Us</button>
            </div>
        </ModalContainer>
    );

}

export default Pending;