// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_AuthHeader__b9zhC{display: flex;padding:16px 32px;justify-content: space-between;border-bottom: 1px solid #EAECF0;align-items: center;}
.css_AuthHeader__b9zhC > div{display: flex;gap: 32px;align-items: center;}
.css_AuthHeader__b9zhC > div > a > div{width: 44px;height: 44px; border-radius: 22px;background: var(--accent-5, rgba(255, 102, 0, 0.05));display: flex;align-items: center;justify-content: center;}
.css_AuthHeader__b9zhC > div > a > div > img{width: 22px; height: 22px;}
.css_menuToggle__4f3Kh {background-color: var(--accent, #F60);border: 1px solid var(--accent, #F60);display: none;flex-direction: column;justify-content: space-between;align-items: stretch;gap: 8px;padding: 8px;width: 40px;border-radius: 4px;}
.css_menuToggle__4f3Kh > span{height: 2px; background: white;width: 100%;}
.css_Logout__FIIk8{width: 28px; height: 28px;cursor: pointer;}

@media screen and (max-width: 800px) {
    .css_menuToggle__4f3Kh{display: flex;}
}`, "",{"version":3,"sources":["webpack://./src/Components/AuthHeader/css/index.module.css"],"names":[],"mappings":"AAAA,uBAAY,aAAa,CAAC,iBAAiB,CAAC,8BAA8B,CAAC,gCAAgC,CAAC,mBAAmB,CAAC;AAChI,6BAAkB,aAAa,CAAC,SAAS,CAAC,mBAAmB,CAAC;AAC9D,uCAA4B,WAAW,CAAC,YAAY,EAAE,mBAAmB,CAAC,oDAAoD,CAAC,aAAa,CAAC,mBAAmB,CAAC,uBAAuB,CAAC;AACzL,6CAAkC,WAAW,EAAE,YAAY,CAAC;AAC5D,wBAAa,qCAAqC,CAAC,qCAAqC,CAAC,aAAa,CAAC,sBAAsB,CAAC,8BAA8B,CAAC,oBAAoB,CAAC,QAAQ,CAAC,YAAY,CAAC,WAAW,CAAC,kBAAkB,CAAC;AACvO,8BAAmB,WAAW,EAAE,iBAAiB,CAAC,WAAW,CAAC;AAC9D,mBAAQ,WAAW,EAAE,YAAY,CAAC,eAAe,CAAC;;AAElD;IACI,uBAAY,aAAa,CAAC;AAC9B","sourcesContent":[".AuthHeader{display: flex;padding:16px 32px;justify-content: space-between;border-bottom: 1px solid #EAECF0;align-items: center;}\n.AuthHeader > div{display: flex;gap: 32px;align-items: center;}\n.AuthHeader > div > a > div{width: 44px;height: 44px; border-radius: 22px;background: var(--accent-5, rgba(255, 102, 0, 0.05));display: flex;align-items: center;justify-content: center;}\n.AuthHeader > div > a > div > img{width: 22px; height: 22px;}\n.menuToggle {background-color: var(--accent, #F60);border: 1px solid var(--accent, #F60);display: none;flex-direction: column;justify-content: space-between;align-items: stretch;gap: 8px;padding: 8px;width: 40px;border-radius: 4px;}\n.menuToggle > span{height: 2px; background: white;width: 100%;}\n.Logout{width: 28px; height: 28px;cursor: pointer;}\n\n@media screen and (max-width: 800px) {\n    .menuToggle{display: flex;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AuthHeader": `css_AuthHeader__b9zhC`,
	"menuToggle": `css_menuToggle__4f3Kh`,
	"Logout": `css_Logout__FIIk8`
};
export default ___CSS_LOADER_EXPORT___;
