import Title from "./Title";
import styles from "./../css/index.module.css";
import OtpInput from 'react-otp-input';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { BASE_URL, VERIFYOTP } from "../../../Utils/Endpoint";


const StepFour = ({email}) => {
    const [otp, setOtp] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {

        let verifyOtp = async () => {
            console.log(email)
            if(otp.length === 5){
                var response = await fetch(BASE_URL + VERIFYOTP,
                    {
                        method : "POST",
                        body : JSON.stringify({
                            "email": email,
                            "otp": otp
                        }),
                        headers : { 
                            'content-type': 'application/json',
                        }
                    })
                if(response.ok){
                    setErrorMessage('');
                    navigate('/Login',{replace : true})
                    return null;
                }
                
                let json = await response.json();
                console.log(json);
                
                setErrorMessage(json.statusText);
                return null;
            }
        }

        verifyOtp();
            
    },[otp, navigate,email])


    return(
    <div className={styles.StepFour}>
        <Title
            title={"Verification"}
            currentValue={5}
        />
        <p>
            To complete sign up, please enter the verification code we sent to you.
            <br/>It might take a few minutes to receive your code.
        </p>
        <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={5}
            renderInput={(props) => <input {...props} />}
            containerStyle={styles.OtpInputCtnr}
            inputStyle={styles.OtpInput}
            inputType={"tel"}
        />
        <span className={styles.ErrorMessage}>{errorMessage}</span>
        <p>
        Didn’t get a code? <span>Send again</span>
        </p>
    </div>)

}

export default StepFour;