// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_Rates__J8zef{margin: 32px; text-align: left;}
.css_RatesBody__-0h3U{margin-top: 32px;display: flex;flex-direction: column;gap: 21px;}
.css_RatesBody__-0h3U > a{padding: 16px;text-decoration: none;border-radius: 12px;border: 1px solid var(--gray-300, #D0D5DD);background: var(--White, #FFF);display: flex;justify-content: space-between;align-items: center;width: 390px;}
.css_RatesBody__-0h3U > a > div{display: flex;align-items: center; gap: 10px;}
.css_RatesBody__-0h3U > a > div > span{color: var(--gray-900, #101828);font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px; /* 150% */}

@media screen and (max-width : 800px) { 
    .css_RatesBody__-0h3U > a{width: 100%;box-sizing: border-box;}
}`, "",{"version":3,"sources":["webpack://./src/Pages/Admin/Settings/Pages/Rates/css/index.module.css"],"names":[],"mappings":"AAAA,kBAAO,YAAY,EAAE,gBAAgB,CAAC;AACtC,sBAAW,gBAAgB,CAAC,aAAa,CAAC,sBAAsB,CAAC,SAAS,CAAC;AAC3E,0BAAe,aAAa,CAAC,qBAAqB,CAAC,mBAAmB,CAAC,0CAA0C,CAAC,8BAA8B,CAAC,aAAa,CAAC,8BAA8B,CAAC,mBAAmB,CAAC,YAAY,CAAC;AAC/N,gCAAqB,aAAa,CAAC,mBAAmB,EAAE,SAAS,CAAC;AAClE,uCAA4B,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,EAAE,SAAS,CAAC;;AAE7I;IACI,0BAAe,WAAW,CAAC,sBAAsB,CAAC;AACtD","sourcesContent":[".Rates{margin: 32px; text-align: left;}\n.RatesBody{margin-top: 32px;display: flex;flex-direction: column;gap: 21px;}\n.RatesBody > a{padding: 16px;text-decoration: none;border-radius: 12px;border: 1px solid var(--gray-300, #D0D5DD);background: var(--White, #FFF);display: flex;justify-content: space-between;align-items: center;width: 390px;}\n.RatesBody > a > div{display: flex;align-items: center; gap: 10px;}\n.RatesBody > a > div > span{color: var(--gray-900, #101828);font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px; /* 150% */}\n\n@media screen and (max-width : 800px) { \n    .RatesBody > a{width: 100%;box-sizing: border-box;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Rates": `css_Rates__J8zef`,
	"RatesBody": `css_RatesBody__-0h3U`
};
export default ___CSS_LOADER_EXPORT___;
