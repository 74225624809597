// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_Security__dP54X{padding: 32px 96px 0px 32px;text-align: left;}
.css_SecurityBody__eZFXB{margin-top: 16px;}
.css_SecurityBody__eZFXB > div{padding: 16px 0px;display: flex; justify-content: space-between;border-bottom: 1px solid #EAECF0;align-items: center;}
.css_SecurityBody__eZFXB > div > div{display: flex;flex-direction: column;}
.css_SecurityBody__eZFXB > div > a,.css_SecurityBody__eZFXB > div > button{color: var(--gray-500, #667085);font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px;text-decoration: none;padding: 8px 16px;border-radius: 8px;border: 1px solid var(--gray-300, #D0D5DD);background: var(--transparent, rgba(252, 252, 253, 0.00));box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);}
.css_SecurityBody__eZFXB > div > div > h4{margin: 0px;color: var(--gray-700, #344054);font-size: 18px;font-style: normal;font-weight: 500;line-height: 28px; /* 155.556% */}
.css_SecurityBody__eZFXB > div > div > span{color: var(--gray-500, #667085);font-size: 18px;font-style: normal;font-weight: 450;line-height: 28px; /* 155.556% */}

@media screen and (max-width : 600px) { 
    .css_Security__dP54X{padding: 32px;}
}`, "",{"version":3,"sources":["webpack://./src/Pages/Customer/Settings/Pages/Security/css/index.module.css"],"names":[],"mappings":"AAAA,qBAAU,2BAA2B,CAAC,gBAAgB,CAAC;AACvD,yBAAc,gBAAgB,CAAC;AAC/B,+BAAoB,iBAAiB,CAAC,aAAa,EAAE,8BAA8B,CAAC,gCAAgC,CAAC,mBAAmB,CAAC;AACzI,qCAA0B,aAAa,CAAC,sBAAsB,CAAC;AAC/D,2EAAqD,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,qBAAqB,CAAC,iBAAiB,CAAC,kBAAkB,CAAC,0CAA0C,CAAC,yDAAyD,CAAC,kDAAkD,CAAC;AAC9W,0CAA+B,WAAW,CAAC,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,EAAE,aAAa,CAAC;AAChK,4CAAiC,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,EAAE,aAAa,CAAC;;AAEtJ;IACI,qBAAU,aAAa,CAAC;AAC5B","sourcesContent":[".Security{padding: 32px 96px 0px 32px;text-align: left;}\n.SecurityBody{margin-top: 16px;}\n.SecurityBody > div{padding: 16px 0px;display: flex; justify-content: space-between;border-bottom: 1px solid #EAECF0;align-items: center;}\n.SecurityBody > div > div{display: flex;flex-direction: column;}\n.SecurityBody > div > a,.SecurityBody > div > button{color: var(--gray-500, #667085);font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px;text-decoration: none;padding: 8px 16px;border-radius: 8px;border: 1px solid var(--gray-300, #D0D5DD);background: var(--transparent, rgba(252, 252, 253, 0.00));box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);}\n.SecurityBody > div > div > h4{margin: 0px;color: var(--gray-700, #344054);font-size: 18px;font-style: normal;font-weight: 500;line-height: 28px; /* 155.556% */}\n.SecurityBody > div > div > span{color: var(--gray-500, #667085);font-size: 18px;font-style: normal;font-weight: 450;line-height: 28px; /* 155.556% */}\n\n@media screen and (max-width : 600px) { \n    .Security{padding: 32px;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Security": `css_Security__dP54X`,
	"SecurityBody": `css_SecurityBody__eZFXB`
};
export default ___CSS_LOADER_EXPORT___;
