import styles from "./css/index.module.css";
import { Link } from "react-router-dom";
import LogoIcon from "./../../img/IconLogo.png";
import LogoText from "./../../img/LogoText.png";

const OnBoardingCtnr = ({children, isBig = false}) => {

    return(
        <div className={styles.OnBoardingCtnr}>
            <div>
                <Link to={"/"}>
                    <img src={LogoIcon} alt="Logo Icon"/>
                    <img src={LogoText} alt="Logo Text"/>
                </Link>
            </div>
            <div className={!isBig ? styles.BodyContainer : styles.BigStyle}>
            {children}
            </div>
        </div>
    );

}

export default OnBoardingCtnr;