// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_BankAccountDetails__4QGea{padding: 32px 96px 0px 32px;text-align: left;}
.css_BankAccountDetailsBody__FZA4d {margin-top: 32px;}
.css_BankAccountDetailsBody__FZA4d > div:nth-of-type(1){display: flex; flex-direction: column; gap: 12px;width: 432px;}
.css_BankNameDropdown__rqP9D{border-radius: 8px;border: 1px solid var(--gray-300, #D0D5DD);background: var(--White, #FFF);padding: 14px;display: flex;align-items: center;justify-content: space-between;width: 100%;}
.css_BankNameDropdown__rqP9D > span{color: var(--gray-500, #667085);font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px; /* 150% */}    

.css_Footer__TlCx1{margin-top: 12px;text-align: left;margin-bottom: 32px;}
.css_Footer__TlCx1 > button{border: none; margin: 0px;padding: 0px;background: rgba(0, 0, 0, 0);color: var(--error-500, #F04438);font-size: 16px;font-style: normal;font-weight: 500;line-height: 24px; /* 150% */}

@media screen and (max-width : 600px) { 
    .css_BankAccountDetails__4QGea{padding: 32px;}
    .css_BankAccountDetailsBody__FZA4d > div:nth-of-type(1){width: 100%;}
    .css_BankNameDropdown__rqP9D{box-sizing: border-box;}
}`, "",{"version":3,"sources":["webpack://./src/Pages/Customer/Settings/Pages/BankAccountDetails/css/index.module.css"],"names":[],"mappings":"AAAA,+BAAoB,2BAA2B,CAAC,gBAAgB,CAAC;AACjE,oCAAyB,gBAAgB,CAAC;AAC1C,wDAA6C,aAAa,EAAE,sBAAsB,EAAE,SAAS,CAAC,YAAY,CAAC;AAC3G,6BAAkB,kBAAkB,CAAC,0CAA0C,CAAC,8BAA8B,CAAC,aAAa,CAAC,aAAa,CAAC,mBAAmB,CAAC,8BAA8B,CAAC,WAAW,CAAC;AAC1M,oCAAyB,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,EAAE,SAAS,CAAC;;AAE1I,mBAAQ,gBAAgB,CAAC,gBAAgB,CAAC,mBAAmB,CAAC;AAC9D,4BAAiB,YAAY,EAAE,WAAW,CAAC,YAAY,CAAC,4BAA4B,CAAC,gCAAgC,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,EAAE,SAAS,CAAC;;AAEvM;IACI,+BAAoB,aAAa,CAAC;IAClC,wDAA6C,WAAW,CAAC;IACzD,6BAAkB,sBAAsB,CAAC;AAC7C","sourcesContent":[".BankAccountDetails{padding: 32px 96px 0px 32px;text-align: left;}\n.BankAccountDetailsBody {margin-top: 32px;}\n.BankAccountDetailsBody > div:nth-of-type(1){display: flex; flex-direction: column; gap: 12px;width: 432px;}\n.BankNameDropdown{border-radius: 8px;border: 1px solid var(--gray-300, #D0D5DD);background: var(--White, #FFF);padding: 14px;display: flex;align-items: center;justify-content: space-between;width: 100%;}\n.BankNameDropdown > span{color: var(--gray-500, #667085);font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px; /* 150% */}    \n\n.Footer{margin-top: 12px;text-align: left;margin-bottom: 32px;}\n.Footer > button{border: none; margin: 0px;padding: 0px;background: rgba(0, 0, 0, 0);color: var(--error-500, #F04438);font-size: 16px;font-style: normal;font-weight: 500;line-height: 24px; /* 150% */}\n\n@media screen and (max-width : 600px) { \n    .BankAccountDetails{padding: 32px;}\n    .BankAccountDetailsBody > div:nth-of-type(1){width: 100%;}\n    .BankNameDropdown{box-sizing: border-box;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BankAccountDetails": `css_BankAccountDetails__4QGea`,
	"BankAccountDetailsBody": `css_BankAccountDetailsBody__FZA4d`,
	"BankNameDropdown": `css_BankNameDropdown__rqP9D`,
	"Footer": `css_Footer__TlCx1`
};
export default ___CSS_LOADER_EXPORT___;
