import SearchIcon from "./../../../../../img/Search.png";
import FilterIcon from "./../../../../../img/Filter.png";
import DownloadIcon from "./../../../../../img/Download.png";
import styles from "./css/index.module.css";
import Transactions from "../../../../../Components/Transactions/Index";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../store/AuthContext";
import { BASE_URL, GET_TRANSACTION_LIST } from "../../../../../Utils/Endpoint";
import { useParams } from "react-router-dom";


const CustomerTransaction = () => {

    const [transactions, setTransactions] = useState(null);
    const AuthCtx = useContext(AuthContext);
    const { id } = useParams();


    useEffect(() => {
        let getTransactionsAction = async () => {
        
            var response = await fetch(BASE_URL + GET_TRANSACTION_LIST + "?isAdmin=" + true + "&customerId=" + id,
                {
                    method : "GET",
                    headers : {
                        'content-type': 'application/json',
                        "Authorization" : "Bearer " + AuthCtx.token,
                        'Accept': 'application/json'
                    }
                })
    
                if(response.ok){
                    let json = await response.json();
                    setTransactions(json.data);
                }
        }

        getTransactionsAction()
        
    },[AuthCtx, id])

   
    return(
        <div className={styles.CustomerTransaction}>
            <div>
                <div>
                    <img src={SearchIcon} alt="Search icon"/>
                    <input  placeholder="Search"/>
                </div>
                <div>
                    <button>
                        <img src={FilterIcon} alt="Filter icon"/>
                        <span>Sort by</span>
                    </button>
                    <button>
                        <img src={DownloadIcon} alt="Download icon"/>
                        <span>Download CSV</span>
                    </button>
                </div>
            </div>
            <Transactions transactions={transactions} setTransactions={setTransactions} isAdmin={true}/>
        </div>
    );

}

export default CustomerTransaction