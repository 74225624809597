import Button from "../../../Components/Button/Index";
import Input from "../../../Components/Input/Index";
import InputCtnr from "../../../Components/InputCtnr/Index";
import Title from "./Title";
import GreenCheck from "./../../../img/GreenCheck.png";
import RedCross from "./../../../img/RedCross.png";
import styles from "./../css/index.module.css";

const StepThree = ({setLevel, stepThreeInfo, setStepThreeInfo}) => {

    let isCapitalLetters = (/[A-Z]+/.test(stepThreeInfo.password))?true:false;
    let isNumber = (/[0-9]+/.test(stepThreeInfo.password))?true:false;
    let isSpecial = (/[!”$%&/#()=?@~`\\.’;:+=^*_-]+/.test(stepThreeInfo.password))?true:false;
    let isLong = (stepThreeInfo.password?.length > 7)?true:false;

    let GoBackHandler = () => {
        setLevel(1);
    }

    let setChange = (value) => {
        setStepThreeInfo(prevState => {
            return{
                ...prevState,
                password : value
            }
        })
    }

    let CreateAction = () => {

        if(isSpecial && isNumber && isCapitalLetters && isLong){
            console.log(stepThreeInfo);
            setLevel(3);
        }
    }

    return(
    <div className={styles.StepThree}>
        <Title
            title={"Choose a Password"}
            currentValue={3}
            goBack={GoBackHandler}
        />
        <InputCtnr label={"Password"} smallMargin={true}>
            <Input
                type={"password"}
                isPassword={true}
                placeholder={"Password here"}
                setChange={setChange}
                value={stepThreeInfo.password}
            />
        </InputCtnr>
        <ul>
            <li>
                <img src={isLong ? GreenCheck : RedCross} alt={"bool value"}/>
                <span className={isLong ? styles.Valid : styles.InValid}>At least 8 characters</span>
            </li>
            <li>
                <img src={isCapitalLetters ? GreenCheck : RedCross} alt={"bool value"}/>
                <span className={isCapitalLetters ? styles.Valid : styles.InValid}>At least one uppercase character</span>
            </li>
            <li>
                <img src={isNumber ? GreenCheck : RedCross} alt={"bool value"}/>
                <span className={isNumber ? styles.Valid : styles.InValid}>At least one number</span>
            </li>
            <li>
                <img src={isSpecial ? GreenCheck : RedCross} alt={"bool value"}/>
                <span className={isSpecial ? styles.Valid : styles.InValid}>At least one special character (!@#&$)</span>
            </li>
        </ul>
        <Button
            text={"Continue"}
            action={CreateAction}
            isEnabled={isSpecial && isNumber && isCapitalLetters && isLong}
        />
    </div>
    )

}

export default StepThree;