import { useContext, useEffect, useState } from "react";
import Button from "../../Components/Button/Index";
import Input from "../../Components/Input/Index";
import InputCtnr from "../../Components/InputCtnr/Index";
import OnBoardingCtnr from "../../Components/OnBoardingCtnr/Index";
import { Link /*, useNavigate */} from "react-router-dom";
import styles from "./css/index.module.css";
import AuthContext from "../../store/AuthContext";
import { BASE_URL, LOGIN } from "../../Utils/Endpoint";
import { useNavigate } from "react-router-dom";
import useGaTracker from "./../../Utils/useGATracker";



const Login = () => {

    useGaTracker();

    const [loginInfo, setLoginInfo] = useState({email : '', password : ''});
    const [errorMessage, setErrorMessage] = useState('');
    const AuthCtx = useContext(AuthContext);
    const navigate = useNavigate();

    let setEmailChange = (value) => {
        setLoginInfo(prevState => {
            return{
                ...prevState,
                email : value
            }
        });
    };

    let setPasswordChange = (value) => {
        setLoginInfo(prevState => {
            return{
                ...prevState,
                password : value
            }
        });
    };

    let submitAction = (e) => {
        e.preventDefault();

        LoginAction();
    }

    let LoginAction = async () => {
        if(loginInfo.email.length > 0 && loginInfo.password.length > 0){

            setErrorMessage('');
            var response = await fetch(BASE_URL + LOGIN,
                {
                    method : "POST",
                    body : JSON.stringify({
                        "email": loginInfo.email,
                        "password": loginInfo.password
                      }),
                    headers : {
                        'content-type': 'application/json',
                    }
                })

                let json = await response.json();
                console.log(json);

                if(response.ok){
                    setErrorMessage('');
                    //AuthCtx.login(json.data.token,json.data.roleName.includes("Customer") ? "/Dashboard" : "/Admin");
                    AuthCtx.login(json.data.token,json.data.roleName[0]);
                    return null;
                }

                setErrorMessage(json.statusText);
                return null;
            //console.log(loginInfo)
            //navigate('/Dashboard', {replace : true});
            
        }

    }

    useEffect(() => {
        
        if(AuthCtx.isLoggedIn)
            navigate(AuthCtx.role === "Customer" ? "/Dashboard" : "/Admin");

    },[AuthCtx,navigate])

    return(
        <OnBoardingCtnr>
            <form className={styles.Login} onSubmit={(e) => {submitAction(e)}}>
                <h3>Log in</h3>
                <InputCtnr label={"Email Address"}>
                    <Input 
                        type={"email"}
                        setChange={setEmailChange}
                        value={loginInfo.email}
                    />
                </InputCtnr>
                <InputCtnr label={"Password"} smallMargin={true}>
                    <Input 
                        type={"password"}
                        isPassword={true}
                        setChange={setPasswordChange}
                        value={loginInfo.password}
                    />
                </InputCtnr>
                <Link to={'/ForgotPassword'}>Forgot Password?</Link>
                <Button 
                    text={"Log in"}
                    isEnabled={loginInfo.email.length > 0 && loginInfo.password.length > 0}
                    action={LoginAction}
                />
                <span className={styles.ErrorMessage}>{errorMessage}</span>

                <p>New to CoinNest?<Link to={'/OnBoarding'}>Get started</Link></p>
            </form>
        </OnBoardingCtnr>
    );

}

export default Login;