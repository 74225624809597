import ModalContainer from "../../../../Components/ModalContainer/Index";
import styles from "./../css/index.module.css";
import Delete from "./../../../../img/DeleteUser.gif";

const DeleteUser = ({showModal, DeleteUserAction}) => {

    return(
        <ModalContainer>
            <div className={styles.DeleteUser}>
                <img alt="Delete User" src={Delete} />
                <h3>Delete User</h3>
                <p>Are you sure you want to delete this user? This action can’t be undone.</p>
                <div>
                    <button onClick={() => showModal(false)}>
                        Cancel
                    </button>
                    <button onClick={() => DeleteUserAction()}>
                        Delete
                    </button>
                </div>
            </div>
        </ModalContainer>
    )

}

export default DeleteUser;