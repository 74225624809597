import { useContext, useState } from "react";
import SettingsHeader from "../../../../../Components/SettingsHeader/Index";
import styles from "./css/index.module.css";
import AuthContext from "../../../../../store/AuthContext";
import { ADD_CRYPTO_WALLET, BASE_URL } from "../../../../../Utils/Endpoint";

const CryptoWalletSetting = ({cryptoWallet}) => {
    const [addressVal, setAddress] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const AuthCtx = useContext(AuthContext);

    let SetCryptoWalletVal = async () => {

        if(addressVal === ''){
            setErrorMessage("Address not set.")
            return null;
        }


        //var code = rate.isUSDRate ? `${rate.ShortCode}-${rate.Short}` : rate.ShortCode

        var obj = {
            "currencyShort": cryptoWallet,
            "address": addressVal
          };
        
        console.log(obj);

        var response = await fetch(BASE_URL + ADD_CRYPTO_WALLET,
            {
                method : "POST",
                body : JSON.stringify(obj),
                headers : {
                    'content-type': 'application/json',
                    "Authorization" : "Bearer " + AuthCtx.token,
                    'Accept': 'application/json'
                }
            })

            let json = await response.json();
            console.log(json);

            if(response.ok){
               
                setSuccessMessage("Wallet Address successfully changed.");
                setErrorMessage('');
                setAddress('');
                return null;
            }

            setSuccessMessage('');
            setErrorMessage(json.statusText);
            return null;
    }

    return(
        <div className={styles.RateSetting}>
            <SettingsHeader
                heading={cryptoWallet}
                backText={"Crypto Wallet Address Settings"}
                backLink={"/Admin/Settings/Transactions/CryptoWallet"}
            />
            <div className={styles.RateSettingBody}>
                <div>
                    <label>Address</label>
                    <div>
                        <input type={"text"} value={addressVal} onChange={(e) => setAddress(e.target.value)}/>
                    </div>
                </div>
                <span className={styles.ErrorMessage}>{errorMessage}</span>
                <span className={styles.SuccessMessage}>{successMessage}</span>
                <button onClick={() => SetCryptoWalletVal()}>Save</button>
            </div>
        </div>
    );

}

export default CryptoWalletSetting;