import styles from "./css/index.module.css";
import { Link } from "react-router-dom";
import ArrowRight from "./../../../img/ArrowRight.png";

const CSettings = () => {

    return(
        <div className={styles.CSettings}>
            <div>
                <h3>Settings</h3>
            </div>
            <div>
                <Link to={'General'}>
                    <div>
                        <h4>General</h4>
                        <p>View your account information</p>
                    </div>
                    <img src={ArrowRight}  alt="Arrow Right"/>
                </Link>
                <Link to={"Notifications"}>
                    <div>
                        <h4>Notifications</h4>
                        <p>Manage how we notify you</p>
                    </div>
                    <img src={ArrowRight}  alt="Arrow Right"/>
                </Link>
                <Link to={"AccountUpgrade"}>
                    <div>
                        <h4>Account Upgrade</h4>
                        <p>Verify your account levels to upgrade transaction limits</p>
                    </div>
                    <img src={ArrowRight}  alt="Arrow Right"/>
                </Link>
                <Link to={"BankAccounts"}>
                    <div>
                        <h4>Bank Accounts</h4>
                        <p>Manage your bank accounts</p>
                    </div>
                    <img src={ArrowRight}  alt="Arrow Right"/>
                </Link>
                {/* <Link to={"ReferAndEarn"}>
                    <div>
                        <h4>Refer and Earn</h4>
                        <p>Earn commissions on every referral</p>
                    </div>
                    <img src={ArrowRight}  alt="Arrow Right"/>
                </Link> */}
                <Link to={"Security"}>
                    <div>
                        <h4>Security</h4>
                        <p>Protect your account and transactions.</p>
                    </div>
                    <img src={ArrowRight}  alt="Arrow Right"/>
                </Link>
            </div>
        </div>
    );

}

export default CSettings;