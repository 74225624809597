// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_OnBoardingCtnr__Qe2O3{width: 100vw;height: 100vh;display: flex;justify-content: center;align-items: center;}
.css_OnBoardingCtnr__Qe2O3 > div:nth-of-type(1){position: absolute;width: 100vw;height: auto;top: 0px;left: 0px;padding: 16px 32px;box-sizing: border-box;}
.css_OnBoardingCtnr__Qe2O3 > div:nth-of-type(1) > a{display: flex; justify-content: flex-start;gap: 16px;align-items: center;}
.css_BodyContainer__K\\+0GC{width: 360px;}
.css_BigStyle__S6pWb{width: auto;}

@media screen and (max-width : 400px) { 
    .css_BodyContainer__K\\+0GC{padding: 0px 32px; width: 100%;box-sizing: border-box;}
    .css_BigStyle__S6pWb{width: 100%;box-sizing: border-box;}

}`, "",{"version":3,"sources":["webpack://./src/Components/OnBoardingCtnr/css/index.module.css"],"names":[],"mappings":"AAAA,2BAAgB,YAAY,CAAC,aAAa,CAAC,aAAa,CAAC,uBAAuB,CAAC,mBAAmB,CAAC;AACrG,gDAAqC,kBAAkB,CAAC,YAAY,CAAC,YAAY,CAAC,QAAQ,CAAC,SAAS,CAAC,kBAAkB,CAAC,sBAAsB,CAAC;AAC/I,oDAAyC,aAAa,EAAE,2BAA2B,CAAC,SAAS,CAAC,mBAAmB,CAAC;AAClH,2BAAe,YAAY,CAAC;AAC5B,qBAAU,WAAW,CAAC;;AAEtB;IACI,2BAAe,iBAAiB,EAAE,WAAW,CAAC,sBAAsB,CAAC;IACrE,qBAAU,WAAW,CAAC,sBAAsB,CAAC;;AAEjD","sourcesContent":[".OnBoardingCtnr{width: 100vw;height: 100vh;display: flex;justify-content: center;align-items: center;}\n.OnBoardingCtnr > div:nth-of-type(1){position: absolute;width: 100vw;height: auto;top: 0px;left: 0px;padding: 16px 32px;box-sizing: border-box;}\n.OnBoardingCtnr > div:nth-of-type(1) > a{display: flex; justify-content: flex-start;gap: 16px;align-items: center;}\n.BodyContainer{width: 360px;}\n.BigStyle{width: auto;}\n\n@media screen and (max-width : 400px) { \n    .BodyContainer{padding: 0px 32px; width: 100%;box-sizing: border-box;}\n    .BigStyle{width: 100%;box-sizing: border-box;}\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OnBoardingCtnr": `css_OnBoardingCtnr__Qe2O3`,
	"BodyContainer": `css_BodyContainer__K+0GC`,
	"BigStyle": `css_BigStyle__S6pWb`
};
export default ___CSS_LOADER_EXPORT___;
