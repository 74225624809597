import styles from "./css/index.module.css";
import Cancel from "./../../img/x.png";

const ModalContainer = ({children, showModal, showCancel = true}) => {

    return(
        <>
        <div className={styles.ModalContainerBackGround}>
            {showCancel && <img src={Cancel} alt="Cancel" onClick={() => showModal(false)} />}
        </div>
        <div className={styles.ModalContainerForeground}>
            {children}
        </div>
        </>
    )

}

export default ModalContainer;