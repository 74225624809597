import { useEffect, useState } from "react";
import styles from "./css/index.module.css";
import Dropdown from "./../../img/DropdownArrow.png";
import { DAYS, MONTHS, YEARS } from "../../Utils/StaticData";

const DateOfBirth = ({setDob, dob}) => {
    const [day, setDay] = useState(null);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);
    const [showDay, setShowDay] = useState(false);
    const [showMonth, setShowMonth] = useState(false);
    const [showYear, setShowYear] = useState(false);

    useEffect(() => {
        let dateStr = `${day}, ${month} ${year}`;
        if(day !== null && month !== null && year !== null){
            let date = new Date(dateStr)
            date.setHours(date.getHours() + 1);
            let dateJson = date.toJSON();
            console.log(dateJson);
            setDob(dateJson);
        }
    },[setDob,day,year,month])

    useEffect(() => {

        let date = new Date(dob);
        console.log(date.getFullYear())
        if(date.getFullYear() === 1){
            setDay(null);
            setMonth(null);
            setYear(null);
        }
        else
        {
            setDay(date.getDate());
            setMonth(MONTHS[date.getMonth()]);
            setYear(date.getFullYear());
        }

    },[dob])


    return(
        <div className={styles.DateOfBirth}>
            <div className={styles.Day} onClick={() => setShowDay(prev => !prev)}>
                <div>
                    {day === null ?
                    <span>Day</span>
                    :
                    <>
                        <span>{day}</span>
                        
                    </>
                    }
                    <img alt="Dropdown" src={Dropdown}/>
                </div>
                {showDay && 
                <div>
                    {DAYS.map((d, i) => (
                        <span key={i} onClick={() => setDay(d)}>{d}</span>
                    ))}
                </div>}
            </div>
            <div className={styles.Month} onClick={() => setShowMonth(prev => !prev)}>
                <div>
                {month === null ?
                    <span>Month</span>
                    :
                    <>
                        <span>{month}</span>
                        
                    </>
                    }
                    <img alt="Dropdown" src={Dropdown}/>
                </div>
                {showMonth && 
                <div>
                {MONTHS.map((m, i) => (
                        <span key={i} onClick={() => setMonth(m)}>{m}</span>
                    ))}
                </div>}
            </div>
            <div className={styles.Year}  onClick={() => setShowYear(prev => !prev)}>
                <div>
                {year === null ?
                    <span>Year</span>
                    :
                    <>
                        <span>{year}</span>
                        
                    </>
                    }
                    <img alt="Dropdown" src={Dropdown}/>
                </div>
                {showYear &&
                <div>
                {YEARS.map((y, i) => (
                        <span key={i} onClick={() => setYear(y)}>{y}</span>
                    ))}
                </div>}
            </div>
        </div>
    )
    
}

export default DateOfBirth;