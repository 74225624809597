import OTPInput from "react-otp-input";
import ModalContainer from "../ModalContainer/Index";
import styles from "./css/index.module.css";
import LoadingCircle from "../../../../Components/Loading/Index";


const Pin = ({setPinModal, pin, setPin, setPinConfirm, errorMessage, isLoading}) => {
    
    return(
        <ModalContainer showModal={setPinModal}>
            <div className={styles.Pin}>
                <h3>Transaction Pin</h3>
                <p>Please enter your security pin</p>
                <div>
                    <OTPInput
                        numInputs={4}
                        value={pin}
                        onChange={setPin}
                        renderInput={(props) => <input {...props} />}
                        containerStyle={styles.OtpInputCtnr}
                        inputStyle={styles.OtpInput}
                        inputType={"tel"}
                    />
                </div>
                <span className={styles.ErrorMessage}>{errorMessage}</span>
                <div>{isLoading ? 
                    <LoadingCircle/> :
                    <>
                        <button onClick={() => setPinModal(false)}>
                            Cancel
                        </button>
                        <button onClick={() => setPinConfirm()}>
                            Confirm
                        </button>
                    </>
                    }
                </div>
            </div>
        </ModalContainer>
    )
}

export default Pin;