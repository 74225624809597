import { NavLink } from "react-router-dom";
import DashboardActive from "./../../img/DashboardActive.png";
import DashboardInactive from "./../../img/DashboardInactive.png";
import ExchangeActive from "./../../img/ExhangeActive.png";
import ExchangeInactive from "./../../img/ExchangeInactive.png";
import AccountActive from "./../../img/AccountActive.png";
import AccountInactive from "./../../img/AccountInactive.png";
import SettingsActive from "./../../img/SettingsActive.png";
import SettingsInactive from "./../../img/SettingsInactive.png";
import styles from "./css/index.module.css";

const CustomerNavigation = ({setShowNav}) => {

    return(
        <div className={styles.CustomerNavigation}>
            <ul onClick={() => setShowNav(false)}>
                <li>
                    <NavLink 
                        className={({isActive, isPending}) => isActive ? styles.ActiveNav : ""}
                        end
                        to={'/Dashboard'}>
                        {({isActive, isPending}) => (
                            <div>
                            <img src={isActive ? DashboardActive : DashboardInactive } alt="Dashboard Icon" />
                            <span>Dashboard</span>
                            </div>
                        )}       
                    </NavLink>
                </li>
                <li>
                    <NavLink 
                        className={({isActive, isPending}) => isActive ? styles.ActiveNav : ""}
                        to={'Exchange'}>
                        {({isActive, isPending}) => (
                            <div>
                            <img src={isActive ? ExchangeActive : ExchangeInactive } alt="Exchange Icon" />
                            <span>Exchange</span>
                            </div>
                        )}       
                    </NavLink>
                </li>
                <li>
                    <NavLink 
                        className={({isActive, isPending}) => isActive ? styles.ActiveNav : ""}
                        to={'Account'}>
                        {({isActive, isPending}) => (
                            <div>
                            <img src={isActive ? AccountActive : AccountInactive } alt="Account Icon" />
                            <span>Account</span>
                            </div>
                        )}       
                    </NavLink>
                </li>
                <li>
                    <NavLink 
                        className={({isActive, isPending}) => isActive ? styles.ActiveNav : ""}
                        to={'Settings'}>
                        {({isActive, isPending}) => (
                            <div>
                            <img src={isActive ? SettingsActive : SettingsInactive } alt="Settings Icon" />
                            <span>Settings</span>
                            </div>
                        )}       
                    </NavLink>
                </li>
            </ul>
        </div>
    );

}

export default CustomerNavigation;