import { Link, useParams } from "react-router-dom";
import styles from "./css/index.module.css"
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../store/AuthContext";
import { BASE_URL, DELETE_ACCOUNT, FREEZE_ACCOUNT, GET_USER } from "../../../../../Utils/Endpoint";

const CustomerProfile = () => {
    const [user, setUser] = useState(null);
    const [userState, setUserState] = useState('');
    const AuthCtx = useContext(AuthContext);
    const { id } = useParams();


    useEffect(() => {
        let getTransactionsAction = async () => {
        
            var response = await fetch(BASE_URL + GET_USER + "?userId=" + id,
                {
                    method : "GET",
                    headers : {
                        'content-type': 'application/json',
                        "Authorization" : "Bearer " + AuthCtx.token,
                        'Accept': 'application/json'
                    }
                })
    
                if(response.ok){
                    let json = await response.json();
                    setUser(json.data);
                }
        }

        getTransactionsAction()
        
    },[AuthCtx, id])

    let rtnDate = (date) => {

        let newDate = new Date(date);
        var strDate = newDate.toLocaleDateString('en-US', {day:"numeric", year:"numeric", month:"short"});
        return strDate;

    }

    let freezeAccountToggle = async () => {
        
        var response = await fetch(BASE_URL + FREEZE_ACCOUNT + "?userId=" + id,
            {
                method : "PUT",
                headers : {
                    'content-type': 'application/json',
                    "Authorization" : "Bearer " + AuthCtx.token,
                    'Accept': 'application/json'
                }
            })

            if(response.ok){
                let json = await response.json();
                setUserState(prev => json.data ? "User account frozen" : "User account unfrozen");
            }
    }

    let deleteAccountToggle = async () => {
        
        var response = await fetch(BASE_URL + DELETE_ACCOUNT + "?userId=" + id,
            {
                method : "PUT",
                headers : {
                    'content-type': 'application/json',
                    "Authorization" : "Bearer " + AuthCtx.token,
                    'Accept': 'application/json'
                }
            })

            if(response.ok){
                let json = await response.json();
                setUserState(prev => json.data ? "User Account deleted" : "User Account Reactivated.");
            }
    }

    return(
        <div className={styles.CustomerProfile}>
            <div className={styles.Person}>
                    <div>
                        <h5>Personal Information</h5>
                        <Link to={'Edit'}>
                            Edit
                        </Link>
                    </div>
                    {user !== null &&
                    <div>
                        <div>
                            <label>First Name</label>
                            <span>{user.firstName}</span>
                        </div>
                        <div>
                            <label>Last Name</label>
                            <span>{user.lastName}</span>
                        </div>
                        <div>
                            <label>Date of Birth</label>
                            <span>{rtnDate(user.dateOfBirth) ?? "---"}</span>
                        </div>
                        <div>
                            <label>Phone Number</label>
                            <span>{user.phoneNumber ?? "---"}</span>
                        </div>
                        <div>
                            <label>Country</label>
                            <span>{user.country ?? "---"}</span>
                        </div>
                        <div>
                            <label>State</label>
                            <span>{user.state ?? "---"}</span>
                        </div>
                        <div>
                            <label>Street Address</label>
                            <span>{user.streetAddress ?? "---"}</span>
                        </div>
                    </div>}
                    <p>{userState}</p>
                    <button onClick={() => deleteAccountToggle()}>Delete Account Toggle</button>
                    <button onClick={() => freezeAccountToggle()}>Freeze Account Toggle</button>
                </div>
        </div>
    );

}

export default CustomerProfile;