import ModalContainer from "../ModalContainer/Index";
import styles from "./css/index.module.css";
import Bank from "./../../../../img/BankSmall.png";
import DropDownArrow from "./../../../../img/DropdownArrow.png"
import { useState } from "react";


const Withdraw = ({setWithdrawModal, walletBalance, rate, setWithAmount, withAmount, bankAccts, setActiveBank, activebank, setShowPinModal, setModalCheck}) => {
    const [openDropdown, setOpenDropdown] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    let setConfirm = () => {
        if(withAmount > walletBalance){
            setErrorMessage("You do not have enough balance to do this transaction.");
            return null;
        }

        setErrorMessage('');
        setWithdrawModal(false); 
        setShowPinModal(true)

    }

    let check = async () => {

        await setModalCheck(setConfirm, withAmount);
    }
    
    return(
        <ModalContainer showModal={setWithdrawModal}>
            <div className={styles.Withdraw}>
                <h3>Withdraw</h3>
                <div>
                    <div>
                        <label>Send to</label>
                        <div onClick={() => setOpenDropdown(prevState => !prevState)} className={styles.DropDownWrapper}>
                            <div className={styles.DropDownCtnr}>
                                <div>
                                {activebank !== null ? 
                                <>
                                    <img src={Bank} alt="Bank Transfer Icon"/>
                                    <label>{`${activebank.bankName} - ${activebank.accountNumber}`}</label>
                                </>
                                :
                                    <label>Set Bank Account</label>
                                }
                                </div>
                                <img src={DropDownArrow} alt="Dropdown Arrow Icon"/>
                            </div>
                            {openDropdown &&
                            <div className={styles.DropDown}>
                                {bankAccts === null ? 
                                    <div>
                                        <label>Go to Settings to add a Bank Account</label>
                                    </div>
                                    :
                                    <>
                                    {bankAccts.map((bankAcct, index) => (
                                        <div key={index} onClick={() => setActiveBank(bankAcct)}>
                                            <img src={Bank} alt="Bank Transfer Icon"/>
                                            <label>{`${bankAcct.bankName} - ${bankAcct.accountNumber}`}</label>
                                        </div>
                                    ))}
                                    </>
                                }
                            </div>}
                        </div>
                    </div>
                    <div>
                        <label>Amount</label>
                        <div>
                            <label>NGN</label>
                            <input  type={'number'} value={withAmount} onChange={(e) => setWithAmount(e.target.value)}/>
                        </div>
                        <div>
                            <span>Wallet Balance</span>
                            <span>NGN {walletBalance.toFixed(2)}</span>
                        </div>
                    </div>
                    {/* <div>
                        <div>
                            <span>You will receive</span>
                            <span>{parseFloat(withAmount).toFixed(2)} NGN</span>
                        </div>
                        <div>
                            <span>Exchange rate</span>
                            <span>NGN {rate.toFixed(2)}</span>
                        </div>
                    </div> */}
                    {<label className={styles.ErrorMessage}>{errorMessage}</label>}
                </div>
                <div>
                    <button onClick={() => setWithdrawModal(false)}>
                        Cancel
                    </button>
                    <button onClick={() => {check()}}>
                        Confirm
                    </button>
                </div>
            </div>
        </ModalContainer>
    )
}

export default Withdraw;