import { Outlet } from "react-router-dom";
import AuthHeader from "../AuthHeader/Index";
import CustomerNavigation from "../CustomerNavigation/Index";
import AuthFooter from "../AuthFooter/Index";
import styles from "./css/index.module.css";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../store/AuthContext";
import useGaTracker from "./../../Utils/useGATracker";


const AuthCustContainer = () => {

    useGaTracker();

    const isMob = window.innerWidth > 800;
    const [showNav, setShowNav] = useState(isMob);
    const AuthCtx = useContext(AuthContext);

    useEffect(() => {

        function handleResize() {
            setShowNav(window.innerWidth > 800)
          
        }
      
          window.addEventListener('resize', handleResize)

          const logoutHandler = setTimeout(() => {
            AuthCtx.logout();
          }, 3600000)

        return () => {
            window.removeEventListener('resize', handleResize);
            clearTimeout(logoutHandler);
        }
    },[AuthCtx])

    return(
        <div className={styles.AuthCustContainer}>
            <AuthHeader setShowNav={setShowNav}/>
            <div>
                {(showNav || isMob) && <CustomerNavigation setShowNav={setShowNav}/>}
                <div className={styles.Outlet}>
                    <Outlet/>
                </div>
            </div>
            <AuthFooter/>
        </div>
    )

}

export default AuthCustContainer;