import SettingsHeader from "../../../../../Components/SettingsHeader/Index";
import styles from "./css/index.module.css";
import InputCtnr from "../../../../../Components/InputCtnr/Index";
import Input from "../../../../../Components/Input/Index";
import { useContext, useEffect, useState } from "react";
import ArrowDown from "./../../../../../img/CustomerArrowDown.png";
import Search from "./../../../../../img/PhoneSearch.png";
import { ADD_BANK_ACCOUNT, BASE_URL, GET_BANKS, GET_BANK_ACCOUNT_DETAILS } from "../../../../../Utils/Endpoint";
import AuthContext from "../../../../../store/AuthContext";
import { useNavigate } from "react-router-dom";

const AddBankAccount = () => {

    const [banks, setBanks] = useState(null)
    const [activebank, setActiveBank] = useState(null);
    const [showBanks, setShowBanks] = useState(false);
    const [filteredBanks, setFilteredBanks] = useState(null);
    const [accountDetails, setAccountDetails] = useState({name : "", number: ""});
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const AuthCtx = useContext(AuthContext);


    let setNameHandler = (value) => {
       /*  setAccountDetails(prevState => {
            return {
                ...prevState,
                name : value
            }
        }); */
        console.log(value);
    }

    useEffect(()=> {

        let GetBankAccounts = async () => {
        
            var response = await fetch(BASE_URL + GET_BANKS,
                {
                    method : "GET",
                    headers : {
                        'content-type': 'application/json',
                    }
                })
    
                if(response.ok){
                    let json = await response.json();
                    console.log(json);
                    //navigate('/Dashboard/Settings/BankAccounts',{replace : true})
                    setBanks(json?.data);
                }
        }

        GetBankAccounts()

    },[])

    let setNumberHandler = (value) => {

        if(value.length < 11)
            setAccountDetails(prevState => {
                return {
                    ...prevState,
                    number : value
                }
            });
        
        if(value.length === 10)
            GetBankAccountDetails(value);
    }

    let GetBankAccountDetails = async (value) => {
        
        var response = await fetch(BASE_URL + GET_BANK_ACCOUNT_DETAILS,
            {
                method : "POST",
                body : JSON.stringify({
                    "accountNumber": value,
                    "bankCode": activebank.code,
                  }),
                headers : {
                    'content-type': 'application/json',
                    "Authorization" : "Bearer " + AuthCtx.token
                }
            })

            let json = await response.json();
            console.log(json);

            if(response.ok){
                
                setAccountDetails(prevState => {
                    return {
                        ...prevState,
                        name : json?.data?.account_name
                    }
                });
                setErrorMessage('');
                return null;
            }

            setErrorMessage(json.statusText);
            return null;

    }

    let AddBankAccountAction = async () => {

        var obj = {
            "bankName" : activebank.name,
            "accountNumber" : accountDetails.number,
            "accountName" : accountDetails.name
          };
        
        console.log(obj);

        if(activebank.name !== '' && accountDetails.number !== ''
        && accountDetails.name !== ''){
        var response = await fetch(BASE_URL + ADD_BANK_ACCOUNT,
            {
                method : "POST",
                body : JSON.stringify(obj),
                headers : {
                    'content-type': 'application/json',
                    "Authorization" : "Bearer " + AuthCtx.token,
                    'Accept': 'application/json'
                }
            })

            if(response.ok){
                let data = await response.json();
                console.log(data);
                navigate('/Dashboard/Settings/BankAccounts',{replace : true})
            }
        } 
    }

    return(
        <div className={styles.AddBankAccount}>
            <SettingsHeader
                    heading={"Add Bank Account"}
                    backText={"Bank Accounts"}
                    backLink={"/Dashboard/Settings/BankAccounts"}
            /> 
            <div className={styles.AddBankAccountBody}>
                <div>
                    <InputCtnr label={"Bank Name"}>
                        <div className={styles.BankNameDropdown}>
                            <div onClick={() => {setShowBanks(prevState => !prevState);setFilteredBanks(banks)}}>
                                <span>{activebank === null ? "Select a bank..." : activebank.name}</span>
                                <img src={ArrowDown} alt="Arrow down" />
                            </div>
                            {showBanks &&
                            <div>
                                <div>
                                    <img src={Search} alt="Phone Search"/>
                                    <input 
                                        type={"search"} 
                                        placeholder={"Search"} 
                                        onChange={(e) => setFilteredBanks(prevState => banks.filter(str => str.name.toLowerCase().includes(e.target.value.toLowerCase())))} />
                                </div>
                                {filteredBanks !== null && filteredBanks.map((bankInfo, index) => (
                                    <div key={index} onClick={() => {setActiveBank(bankInfo);setShowBanks(false);setFilteredBanks(banks)}}>
                                        <span>{bankInfo.name}</span>
                                    </div>
                                ))}
                            </div>}
                        </div>
                    </InputCtnr>
                    <InputCtnr label={"Account Number"}>
                        <Input 
                            value={accountDetails.number}
                            setChange={setNumberHandler}
                            type={"tel"}
                        />
                    </InputCtnr>
                    <InputCtnr label={"Account Name"}>
                        <Input
                            value={accountDetails.name}
                            setChange={setNameHandler}
                            type={"text"} 
                        />
                    </InputCtnr>
                </div>
                <span className={styles.ErrorMessage}>{errorMessage}</span>
                <div className={styles.Footer}>
                    <button onClick={() => AddBankAccountAction()}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    )

}

export default AddBankAccount;