import ModalContainer from "../ModalContainer/Index";
import styles from "./css/index.module.css";
import CompletedIcon from "./../../../../img/Completed.gif";

const Completed = ({setIsCompleted}) => {

    return(
        <ModalContainer showModal={setIsCompleted}>
            <div className={styles.Completed}>
                <img src={CompletedIcon} alt="Completed Icon" />
                <h3>Transaction Pending</h3>
                <p>Your request is being processed.</p>
                <button onClick={() => setIsCompleted(false)}>Go Back</button>
            </div>
        </ModalContainer>
    );

}

export default Completed;