import styles from "./../css/index.module.css";

const Person = ({source, name, role, noPhoto = false}) => {
    
    return(
    <div className={styles.Person}>
        <div className={`${noPhoto ? styles.NoPhoto : ''}`}>
            <img src={source} alt={name + " Photo"} />
        </div>
        <div>
            <h3>{name}</h3>
            <label>{role}</label>
        </div>        
    </div>
    );
}

export default Person;