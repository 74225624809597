// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_ChangePassword__xTbL9{padding: 32px 96px 0px 32px;text-align: left;}
.css_ChangePasswordBody__gDD64{margin-top: 32px;width: 360px;margin-bottom: 96px;}
.css_ErrorMessage__Ea-HR{color: red; font-size: 14px;margin-top: 12px;display: block;margin-bottom: 8px;}
.css_SuccessMessage__D4S8D{color: rgb(41, 172, 41); font-size: 14px;margin-top: 12px;display: block;margin-bottom: 8px;}

@media screen and (max-width : 600px) { 
    .css_ChangePassword__xTbL9{padding: 32px;}
    .css_ChangePasswordBody__gDD64{width: 100%;}
}`, "",{"version":3,"sources":["webpack://./src/Pages/Customer/Settings/Pages/ChangePassword/css/index.module.css"],"names":[],"mappings":"AAAA,2BAAgB,2BAA2B,CAAC,gBAAgB,CAAC;AAC7D,+BAAoB,gBAAgB,CAAC,YAAY,CAAC,mBAAmB,CAAC;AACtE,yBAAc,UAAU,EAAE,eAAe,CAAC,gBAAgB,CAAC,cAAc,CAAC,kBAAkB,CAAC;AAC7F,2BAAgB,uBAAuB,EAAE,eAAe,CAAC,gBAAgB,CAAC,cAAc,CAAC,kBAAkB,CAAC;;AAE5G;IACI,2BAAgB,aAAa,CAAC;IAC9B,+BAAoB,WAAW,CAAC;AACpC","sourcesContent":[".ChangePassword{padding: 32px 96px 0px 32px;text-align: left;}\n.ChangePasswordBody{margin-top: 32px;width: 360px;margin-bottom: 96px;}\n.ErrorMessage{color: red; font-size: 14px;margin-top: 12px;display: block;margin-bottom: 8px;}\n.SuccessMessage{color: rgb(41, 172, 41); font-size: 14px;margin-top: 12px;display: block;margin-bottom: 8px;}\n\n@media screen and (max-width : 600px) { \n    .ChangePassword{padding: 32px;}\n    .ChangePasswordBody{width: 100%;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChangePassword": `css_ChangePassword__xTbL9`,
	"ChangePasswordBody": `css_ChangePasswordBody__gDD64`,
	"ErrorMessage": `css_ErrorMessage__Ea-HR`,
	"SuccessMessage": `css_SuccessMessage__D4S8D`
};
export default ___CSS_LOADER_EXPORT___;
