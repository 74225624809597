import ModalContainer from "../ModalContainer/Index";
import styles from "./css/index.module.css";
//import BTC from "./../../../../img/BTC.png";
import DropDownArrow from "./../../../../img/DropdownArrow.png";
import Transfer from "./../../../../img/Transfer.png";
import Wallet from "./../../../../img/Wallet.png";
import { useState } from "react";
import { PYMNTMETHOD } from "../../../../Utils/StaticData";
import LoadingCircle from "../../../../Components/Loading/Index";


const Buy = ({setBuyModal, buyWalletAddress, setBuyWalletAddress, setBuyMethod, buyMethod, amount, confirmBuyAction, asset, balance, buyError, buyNairaRate, isLoading, buyFee}) => {
    const [showMethod, setShowMethod] = useState(false);
    const [error, setError] = useState('');

    const PaymentMethod = [
        {
            name : "Bank Transfer",
            img : Transfer,
            code : PYMNTMETHOD[0].Code
        },
        {
            name : "Wallet Balance - NGN " + parseFloat(balance).toFixed(2),
            img : Wallet,
            code : PYMNTMETHOD[1].Code
        },
    ]

    const submitHandler = () => {
        if(buyWalletAddress === ''){
            setError("Wallet address not set.");
            return;
        }

        if(buyMethod === null){
            setError("Set a payment method.");
            return;
        }

        confirmBuyAction();
    }

    return(
        <ModalContainer showModal={setBuyModal}>
            <div className={styles.Buy}>
                <h3>Buy Order</h3>
                <div>
                    <div className={styles.InputGroup}>
                        <label>Send to</label>
                        <div className={styles.InputDropDown}>
                            <div className={styles.SelectedInput}>
                            <img src={asset.img} alt="Bank Transfer Icon" className={styles.coinSize}/>
                            <label>{asset.name} Address</label>
                            </div>
                            <img src={DropDownArrow} alt="Dropdown Arrow Icon"/>
                        </div>
                    </div>
                    <div className={styles.InputGroup}>
                        <label>Wallet Address</label>
                        <div>
                            <input  type={'text'} value={buyWalletAddress} onChange={(e) => setBuyWalletAddress(e.target.value)}/>
                        </div>
                    </div>
                    <div className={styles.InputGroup}>
                        <label>Payment Method</label>
                        <div className={styles.InputDropDownCtr} onClick={() => setShowMethod(prevState => !prevState)}>
                            <div className={styles.InputDropDown}>
                                <div className={styles.SelectedInput}>
                                    {buyMethod === null ?
                                    <label>Set Buy Method</label>
                                    :
                                    <>
                                        <img src={buyMethod.img} alt="Method Icon"/>
                                        <label>{buyMethod.name}</label>
                                    </>}
                                </div>
                                <img src={DropDownArrow} alt="Dropdown Arrow Icon"/>
                            </div>
                            {showMethod &&
                            <div>
                                {PaymentMethod.map((method, index) => (
                                    <div key={index} onClick={() => setBuyMethod(method)}>
                                        <img src={method.img} alt="Method Icon"/>
                                        <label>{method.name}</label>
                                    </div>
                                ))}
                            </div>}
                        </div>
                    </div>
                    <label className={styles.buyError}>{buyError}</label>
                    <div className={styles.Summary}>
                        <div>
                            <span>Fee</span>
                            <span>{parseFloat(buyFee.feeCharge).toFixed(2)} NGN</span>
                        </div>
                        <div>
                            <span>Total</span>
                            <span>{parseFloat(amount + buyFee.feeCharge).toFixed(2)} NGN</span>
                        </div>
                        <div>
                            <span>Exchange rate</span>
                            <span>NGN {parseFloat(buyNairaRate.rate).toFixed(2)} / USD</span>
                        </div>
                    </div>
                </div>
                <span className={styles.ErrorMessage}>{error}</span>
                <div>{isLoading && buyMethod.code === PaymentMethod[1].code  ?
                    <LoadingCircle/>
                    :
                    <>
                        <button onClick={() => setBuyModal(false)}>
                            Cancel
                        </button>
                        <button onClick={() => submitHandler()}>
                            Confirm
                        </button>
                    </>
                    }
                </div>
            </div>
        </ModalContainer>
    )
}

export default Buy;