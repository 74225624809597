// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_FirstSection__u0AhU{padding:96px 0px;}
.css_SecondSection__FnnEb{color: var(--gray-500, #667085);font-size: 18px;font-style: normal;font-weight: 450;line-height: 28px;text-align: left;display: flex;flex-direction: column;align-items: center;padding-bottom: 96px;}
.css_SecondSection__FnnEb > div{width:75%}
.css_SecondSection__FnnEb h3{margin: 12px 0px 24px 0px;color: var(--gray-900, #101828);font-size: 30px;font-style: normal;font-weight: 500;line-height: 38px; /* 126.667% */}

@media screen and (max-width : 800px) { 
    .css_FirstSection__u0AhU{padding: 48px 32px;}
}`, "",{"version":3,"sources":["webpack://./src/Pages/AntiMoney/css/index.module.css"],"names":[],"mappings":"AAAA,yBAAc,gBAAgB,CAAC;AAC/B,0BAAe,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,gBAAgB,CAAC,aAAa,CAAC,sBAAsB,CAAC,mBAAmB,CAAC,oBAAoB,CAAC;AACpN,gCAAqB,SAAS;AAC9B,6BAAkB,yBAAyB,CAAC,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,EAAE,aAAa,CAAC;;AAEjK;IACI,yBAAc,kBAAkB,CAAC;AACrC","sourcesContent":[".FirstSection{padding:96px 0px;}\n.SecondSection{color: var(--gray-500, #667085);font-size: 18px;font-style: normal;font-weight: 450;line-height: 28px;text-align: left;display: flex;flex-direction: column;align-items: center;padding-bottom: 96px;}\n.SecondSection > div{width:75%}\n.SecondSection h3{margin: 12px 0px 24px 0px;color: var(--gray-900, #101828);font-size: 30px;font-style: normal;font-weight: 500;line-height: 38px; /* 126.667% */}\n\n@media screen and (max-width : 800px) { \n    .FirstSection{padding: 48px 32px;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FirstSection": `css_FirstSection__u0AhU`,
	"SecondSection": `css_SecondSection__FnnEb`
};
export default ___CSS_LOADER_EXPORT___;
