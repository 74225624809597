// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_AuthCustContainer__NEkCx{height: 100vh;display: flex;flex-direction: column;}
.css_AuthCustContainer__NEkCx > div:nth-of-type(2){display: flex;flex: 1 1;}
.css_Outlet__40XxV{width: 100%;}`, "",{"version":3,"sources":["webpack://./src/Components/AuthCustContainer/css/index.module.css"],"names":[],"mappings":"AAAA,8BAAmB,aAAa,CAAC,aAAa,CAAC,sBAAsB,CAAC;AACtE,mDAAwC,aAAa,CAAC,SAAO,CAAC;AAC9D,mBAAQ,WAAW,CAAC","sourcesContent":[".AuthCustContainer{height: 100vh;display: flex;flex-direction: column;}\n.AuthCustContainer > div:nth-of-type(2){display: flex;flex: 1;}\n.Outlet{width: 100%;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AuthCustContainer": `css_AuthCustContainer__NEkCx`,
	"Outlet": `css_Outlet__40XxV`
};
export default ___CSS_LOADER_EXPORT___;
